import { Contract_Status_Enum } from 'kheops-graphql';
import FiltersBackgroundSm from '../../assets/images/filters_bg_sm.png';
import FiltersBackgroundMd from '../../assets/images/filters_bg_md.png';
import FiltersBackgroundLg from '../../assets/images/filters_bg_lg.png';
import FiltersBackgroundXl from '../../assets/images/filters_bg_xl.png';

export const BreakpointFilterBackgrounds = {
  xs: 'none',
  sm: FiltersBackgroundSm,
  md: FiltersBackgroundMd,
  lg: FiltersBackgroundLg,
  xl: FiltersBackgroundXl,
};

export default class FilterUtils {
  static ExtractAdministrativeAreaInfo(area: string): string[] {
    return area.split('|');
  }

  static GetSignedContractFilter(attribute: string, companyId: string): string {
    return `NOT ${attribute}:${companyId}_${Contract_Status_Enum.Signed}`;
  }

  static GetSupplierCanDeliverFilter(area: string): string {
    return `delivery_zones:"${area}" OR delivery_zones:ALL_AREAS`;
  }

  static visiblePackagingsForBuyerFilter(buyerCompanyId: string): string {
    return `NOT invisibleForBuyers:${buyerCompanyId}`;
  }
}
