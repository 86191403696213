import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Chip, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import { useTranslation } from 'react-i18next';
import { CompanyPackaging } from '../../state';
import { DraftSubCatalog } from './state';
import { ellipsisStyle } from '../../../common/utils/style.utils';
import FileUtils from '../../../common/utils/file.utils';
import SizedImage from '../../../common/components/SizedImage';
import i18n from '../../../i18n';

interface SubCatalogFormPackagingCardProps {
  packaging: CompanyPackaging
}

export default function SubCatalogFormPackagingCard({ packaging }: SubCatalogFormPackagingCardProps): React.JSX.Element {
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const { t } = useTranslation(['products']);
  const { control } = useFormContext<DraftSubCatalog>();

  return (
    <Box
      sx={{
        p: 1.5,
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Controller
          name={`packagingsAvailability.${packaging.sku}.isAvailable`}
          control={control}
          render={({ field }) => (
            <Checkbox
              checked={!!field.value}
              {...field}
              sx={{
                height: 40,
                my: 'auto',
              }}
            />
          )}
        />
        <SizedImage
          src={FileUtils.BuildPackagingImageUrl({
            photo: packaging.base_unit.main_photo,
            type: packaging.product.sub_family,
            size: {
              height: 72,
              width: 72,
              fit: 'contain',
            },
          })}
          alt={t('products:csu')}
          width={72}
          height={72}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', overflow: 'hidden', ml: 1, justifyContent: 'center' }}>
          <Typography
            variant="bodyLarge"
            sx={{
              ...ellipsisStyle,
              width: '100%',
            }}
          >
            {packaging.product.name}
          </Typography>
          <Typography variant="bodyMedium" color="secondary">
            {FormatBaseUnitQuantity(packaging.base_unit, 'fr', numberFormat)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
          pt: 0.5,
          alignSelf: 'end',
        }}
      >
        {packaging.base_unit.gtin && (
        <Chip
          label={<Typography variant="bodySmall">{packaging.base_unit.gtin}</Typography>}
          icon={<DocumentScannerOutlinedIcon sx={{ transform: 'rotate(90deg)', verticalAlign: 'top', mr: 0.5, ml: 1, height: 16, width: 16 }} />}
          sx={{
            backgroundColor: 'surfaceContainerHigh.main',
            borderRadius: 100,
            py: 0.5,
            pl: 1,
            pr: 1.5,
            width: 'fit-content',
          }}
        />
        )}
        <Chip
          label={<Typography variant="bodySmall">{FormatLogisticalPackaging(packaging, 'fr', packaging.base_unit)}</Typography>}
          sx={{
            backgroundColor: 'surfaceContainerHigh.main',
            borderRadius: 100,
            py: 0.5,
            px: 1.5,
            width: 'fit-content',
          }}
        />
      </Box>
    </Box>
  );
}
