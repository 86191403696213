import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, styled, SvgIcon, Typography } from '@mui/material';
import MinimumOrderValue from '../assets/icons/minimum_order_value.svg?react';
import { BasketOrder, BasketOrdersDict } from '../hooks/useBasketOrders';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';

interface BasketMinimumOrderValuesProps {
  orders: BasketOrdersDict;
}

const MinimumOrderValueBox = styled(Box)(({ theme }) => ({
  borderRadius: (theme.shape.borderRadius as number) * 4,
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  textAlign: 'center',
  fontSize: '0.875rem',
}));

const MinimumOrderValueContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.between('sm', 'md')]: {
    flexDirection: 'column',
  },
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function BasketMinimumOrderValues({ orders }: BasketMinimumOrderValuesProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);
  const currencyFormat = useCurrencyFormat();
  const [seeDetails, setSeeDetails] = useState(false);
  const unreachedMinimumOrderValueOrders = useMemo(
    () => Object.keys(orders).reduce((acc, tradeName) => {
      const order = orders[tradeName];

      if (order.totalPrice < order.minimumOrderValue) {
        acc.push(order);
      }

      return acc;
    }, [] as BasketOrder[]),
    [orders],
  );

  const toggleSeeDetails = (): void => {
    setSeeDetails(!seeDetails);
  };

  const seeDetailsButton = useMemo((): React.JSX.Element => (
    <Button
      variant="text"
      disableRipple
      onClick={toggleSeeDetails}
    >
      {t(`common:${seeDetails ? 'less_details' : 'see_details'}`)}
    </Button>
  ), [seeDetails]);

  const minimumOrderValueDetails = useMemo((): React.JSX.Element[] => {
    const minimumOrderValueBoxes = unreachedMinimumOrderValueOrders.map((order) => (
      <MinimumOrderValueBox key={order.name}>
        <MinimumOrderValueContentBox>
          <Box
            component="img"
            src={order.photoUrl}
            alt={t('common:company')}
            sx={{
              width: 24,
              height: 24,
              borderRadius: 2,
              mr: 1,
            }}
          />
          <Typography variant="bodyMedium">
            {order.name}
          </Typography>
        </MinimumOrderValueContentBox>
        <MinimumOrderValueContentBox>
          <Typography variant="bodySmall" sx={{ mr: 0.5 }}>
            {`${t('basket:price_missing', { value: currencyFormat.format(order.minimumOrderValue - order.totalPrice) })}`} - {t('common:minimum_order_value')} :
          </Typography>
          <MinimumOrderValueChip
            minimumOrderValue={order.minimumOrderValue}
            totalPrice={order.totalPrice}
            enableProgress
          />
        </MinimumOrderValueContentBox>
      </MinimumOrderValueBox>
    ));

    return minimumOrderValueBoxes;
  }, [unreachedMinimumOrderValueOrders]);

  const basketMinimumOrderValues = useMemo((): React.JSX.Element => {
    if (!unreachedMinimumOrderValueOrders.length) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <Box sx={{ maxWidth: 600, mx: 'auto' }}>
        {seeDetails
          ? (
            <Box sx={{ textAlign: 'center' }}>
              {seeDetailsButton}
              {minimumOrderValueDetails}
            </Box>
          )
          : (
            <MinimumOrderValueBox>
              <MinimumOrderValueContentBox>
                <SvgIcon component={MinimumOrderValue} inheritViewBox sx={{ fill: (theme) => (theme.palette.marketing3.main as unknown as string), mr: 0.5 }} />
                <Typography variant="bodySmall">
                  {t('basket:unreached_minimum_order_value_for_N_suppliers', { count: unreachedMinimumOrderValueOrders.length })}
                </Typography>
              </MinimumOrderValueContentBox>
              {seeDetailsButton}
            </MinimumOrderValueBox>
          )}
      </Box>
    );
  }, [unreachedMinimumOrderValueOrders, seeDetails, minimumOrderValueDetails]);

  return basketMinimumOrderValues;
}
