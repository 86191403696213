import { LoadingButton } from '@mui/lab';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import OrderPaymentSchedulingDialog from './dialogs/OrderPaymentSchedulingDialog';
import useOpenable from '../../hooks/useOpenable';
import { useUpdatePaymentDateMutation } from '../../mutations/__generated__/updatePaymentDate.generated';
import { orderAtom, orderSddPaymentDateAtom } from '../state/state';
import { OrderByReferenceIdDocument } from '../../queries/__generated__/orderByReferenceId.generated';
import { commonSnackbarPropsAtom } from '../../common/state/state';

export default function UpdatePaymentDateButton(): React.JSX.Element {
  const { t } = useTranslation(['payment']);
  const order = useAtomValue(orderAtom);
  const sddPaymentDate = useAtomValue(orderSddPaymentDateAtom);
  const { open, close, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [updatePaymentDate, { loading, client, data }] = useUpdatePaymentDateMutation(
    {
      refetchQueries: [OrderByReferenceIdDocument],
      awaitRefetchQueries: true,
      onError: () => client.refetchQueries({ include: [OrderByReferenceIdDocument] }),
    },
  );

  const handleClose = useCallback((confirm: boolean) => {
    if (confirm) {
      updatePaymentDate({
        variables: {
          orderId: order.id,
          orderReferenceId: order.reference_id,
          newDate: sddPaymentDate!.toISOString(),
        },
      });
    }

    close();
  }, [order, sddPaymentDate]);

  useEffect(() => {
    if (data) {
      setCommonSnackbarProps({
        label: t('payment:payment_scheduled'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [data]);

  return (
    <>
      <OrderPaymentSchedulingDialog
        open={isOpen}
        onClose={handleClose}
      />
      <LoadingButton
        variant="text"
        onClick={open}
        loading={loading}
        sx={{ flex: 'auto' }}
      >
        {t('payment:update_payment_date')}
      </LoadingButton>
    </>
  );
}
