import { FormHelperText, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { FormatMeasurementUnit } from 'kheops-utils';
import { Measurement_Unit_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../../ProductFormPackagings';

interface MeasurementUnitSelectProps {
  name: `productPackagings.${number}.csu.content_measurement_unit` | `productPackagings.${number}.packagings.${number}.content_measurement_unit`;
}

export default function MeasurementUnitSelect({ name }: MeasurementUnitSelectProps): React.JSX.Element {
  const { t } = useTranslation(['packaging', 'products', 'validation']);
  const { control } = useFormContext<ProductPackagingsFormData>();

  const measurementUnitValues = useMemo(() => {
    const measurementUnits = [
      Measurement_Unit_Enum.Ml,
      Measurement_Unit_Enum.Cl,
      Measurement_Unit_Enum.L,
      Measurement_Unit_Enum.G,
      Measurement_Unit_Enum.Kg,
      Measurement_Unit_Enum.T,
      Measurement_Unit_Enum.Unit,
    ];

    return measurementUnits.map((unit) => (
      <MenuItem value={unit} key={unit}>
        <ListItemText primary={FormatMeasurementUnit(unit, 'fr', 1)} />
      </MenuItem>
    ));
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: t('validation:this_field_is_required') as string }}
      render={({ field, fieldState }) => (
        <>
          <Select
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            displayEmpty
            fullWidth
            renderValue={(selected) => (
              !selected
                ? <Typography color="secondary">{t('products:measurement_unit_placeholder')}</Typography>
                : FormatMeasurementUnit(selected as Measurement_Unit_Enum, 'fr', 1)
            )}
            error={!!fieldState.error}
            sx={{ height: 48 }}
          >
            {measurementUnitValues}
          </Select>
          {fieldState.error
            && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
        </>
      )}
    />
  );
}
