import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Box, Button, Drawer, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useOpenable from '../../../hooks/useOpenable';
import { DraftSubCatalog } from './state';
import SubCatalogInfoForm from './SubCatalogInfoForm';

export default function SubCatalogInfoFormDrawerButton(): React.JSX.Element {
  const { t } = useTranslation(['settings']);
  const { watch, setValue, formState, clearErrors } = useFormContext<DraftSubCatalog>();
  const subCatalogFormContracts = watch('contracts');
  const subCatalogFormName = watch('name');
  const { isOpen, open, close } = useOpenable();
  const [subCatalogContracts, setSubCatalogContracts] = useState<string[]>(subCatalogFormContracts);
  const [subCatalogName, setSubCatalogName] = useState<string>(subCatalogFormName);

  const handleConfirmUpdates = (): void => {
    setValue('contracts', subCatalogContracts, { shouldValidate: true });
    setValue('name', subCatalogName, { shouldValidate: true });
  };

  useEffect(() => {
    clearErrors();
  }, [subCatalogContracts, subCatalogName]);

  useEffect(() => {
    if (formState.isValid) {
      close();
    }
  }, [formState.isValid]);

  useEffect(() => {
    if (!isOpen) {
      setSubCatalogName(subCatalogFormName);
      setSubCatalogContracts(subCatalogFormContracts);
    }
  }, [isOpen]);

  return (
    <>
      <Button variant="text" onClick={open}>
        <SettingsIcon />
      </Button>
      <Drawer
        open={isOpen}
        onClose={close}
        anchor="bottom"
        PaperProps={{
          sx: {
            height: 480,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <SettingsIcon />
              <Typography variant="titleLarge">{t('settings:settings')}</Typography>
            </Box>
            <Button variant="text" onClick={close}>
              <CloseIcon />
            </Button>
          </Box>
          <SubCatalogInfoForm
            contractsValue={subCatalogContracts}
            onChangeContracts={setSubCatalogContracts}
            nameValue={subCatalogName}
            onChangeName={setSubCatalogName}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={handleConfirmUpdates}
          >
            {t('common:update')}
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
