import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { Box, Typography } from '@mui/material';

export interface BaseUnitGtinProps {
  gtin: string;
}

export default function BaseUnitGtin({ gtin }: BaseUnitGtinProps): React.JSX.Element {
  return (
    <Box sx={{ display: 'flex' }}>
      <DocumentScannerIcon sx={{ mr: 1, rotate: '90deg', color: 'text.secondary' }} />
      <Typography variant="bodySmall" color="secondary">
        {gtin}
      </Typography>
    </Box>
  );
}
