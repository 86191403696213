import { Grid2, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function BankTransferInformation(): React.JSX.Element {
  const { t } = useTranslation(['payment', 'price']);

  return (
    <Grid2
      container
      sx={{
        textAlign: 'start',
        p: 4,
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Grid2 size={{ xs: 12 }}>
        <Typography variant="titleMedium" sx={{ mb: 2 }}>{t('payment:bank_transfer_informations')}</Typography>
      </Grid2>
      <Grid2
        sx={{
          mt: 1,
          '& .MuiGrid-item': {
            py: 1,
          },
        }}
        container
        size={{ xs: 12 }}
      >
        <Grid2 size={{ xs: 12, sm: 7 }}>
          <Typography variant="bodySmall" color="secondary">IBAN</Typography>
          <Typography variant="bodyMedium">{import.meta.env.REACT_APP_LEMONWAY_IBAN}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 3 }}>
          <Typography variant="bodySmall" color="secondary">BIC</Typography>
          <Typography variant="bodyMedium">{import.meta.env.REACT_APP_LEMONWAY_BIC_CODE}</Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 2 }}>
          <Typography variant="bodySmall" color="secondary">{t('payment:currency')}</Typography>
          <Typography variant="bodyMedium">EUR</Typography>
        </Grid2>
      </Grid2>
      <Grid2 container size={{ xs: 12 }}>
        <Grid2 size={{ xs: 12 }} sx={{ pt: 1 }}>
          <Typography variant="bodySmall" color="secondary">{t('payment:account_holder')}</Typography>
          <Typography variant="bodyMedium">{import.meta.env.REACT_APP_LEMONWAY_HOLDER_NAME}</Typography>
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
