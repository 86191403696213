import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'kheops-graphql';

type NutritionFacts = 'calories' | 'calories_kj' | 'carbohydrate' | 'fat' | 'protein' | 'saturated_fat' | 'sodium' | 'sugars';

interface ProductNutritionFactsProps {
  product: Pick<Product, NutritionFacts>;
}

export default function ProductNutritionFacts({ product }: ProductNutritionFactsProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  const displayNutritionFacts = useMemo((): boolean => {
    const nutritionFactsKeys: NutritionFacts[] = ['calories', 'calories_kj', 'fat', 'saturated_fat', 'carbohydrate', 'sugars', 'sodium', 'protein'];

    return nutritionFactsKeys.some((fact) => product[fact]);
  }, [product]);

  const nutritionFactsRows = useMemo((): React.JSX.Element[] => {
    return [
      { label: t('products:calories'), value: [product.calories, product.calories_kj], unit: ['kcal', 'kj'] },
      { label: t('products:fat'), value: product.fat, unit: 'g' },
      { label: t('products:including_saturated_fat'), value: product.saturated_fat, unit: 'g' },
      { label: t('products:carbohydrate'), value: product.carbohydrate, unit: 'g' },
      { label: t('products:including_sugars'), value: product.sugars, unit: 'g' },
      { label: t('products:sodium'), value: product.sodium, unit: 'g' },
      { label: t('products:protein'), value: product.protein, unit: 'g' },
    ].map((item) => {
      let displayValue;

      if (Array.isArray(item.value)) {
        displayValue = item.value.map((value, valueIndex) => (
          value || value === 0 ? <>{value} {item.unit[valueIndex]}<br /></> : undefined
        ));
      } else {
        displayValue = typeof item.value === 'number' ? `${item.value} ${item.unit}` : '';
      }

      return (
        <TableRow key={item.label}>
          <TableCell>
            <Typography variant="bodySmall" sx={{ pl: 1 }}>
              {item.label}
            </Typography>
          </TableCell>
          <TableCell align="left">
            <Typography variant="bodySmall">
              {displayValue}
            </Typography>
          </TableCell>
        </TableRow>
      );
    });
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {displayNutritionFacts && (
        <Box>
          <Typography variant="titleSmall" sx={{ mb: 1 }}>
            {t('products:nutritional_values_per_100g_100ml')}
          </Typography>
          <Table>
            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  py: 0.5,
                  px: 0,
                },
                '& .MuiTableRow-root:nth-child(odd)': {
                  backgroundColor: 'layout.main',
                },
              }}
            >
              {nutritionFactsRows}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
}
