import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import useOpenable from '../../hooks/useOpenable';
import UploadInvoiceDrawer from './documents/UploadInvoiceDrawer';
import { orderAtom } from '../state/state';

export default function UpdateInvoiceButton(): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const { open: openUploadInvoice, isOpen: isUploadInvoiceOpen, close: closeUploadInvoice } = useOpenable();
  const order = useAtomValue(orderAtom);

  return (
    <>
      <UploadInvoiceDrawer open={isUploadInvoiceOpen} onClose={closeUploadInvoice} orderReferenceId={order.reference_id} />
      <Button
        variant="outlined"
        onClick={openUploadInvoice}
        startIcon={<EditOutlinedIcon />}
        sx={{ flex: 'auto' }}
      >
        {t('order:update_invoice')}
      </Button>
    </>
  );
}
