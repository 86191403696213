import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import PhoneNumberDisplay from '../../common/components/PhoneNumberDisplay';
import { PRIVACY_SETTING_WORDING } from './PrivacySettingSelect';
import { currentContextAtom, userAtom } from '../../state';

export enum PhoneType {
  MOBILE = 'MOBILE',
  LANDLINE = 'LANDLINE'
}
interface PhoneContactInformationDisplayProps {
  phoneType: PhoneType;
}

export default function PhoneContactInformationDisplay({ phoneType }: PhoneContactInformationDisplayProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);
  const user = useAtomValue(userAtom);
  const { realm } = useAtomValue(currentContextAtom);

  const { phoneNumber, privacySetting } = useMemo(() => {
    if (phoneType === PhoneType.LANDLINE) {
      return {
        phoneNumber: user.contactInformation?.landlinePhone,
        privacySetting: user.contactInformation?.landlinePrivacySetting,
      };
    }

    return {
      phoneNumber: user.contactInformation?.mobilePhone,
      privacySetting: user.contactInformation?.mobilePrivacySetting,
    };
  }, [phoneType, user]);

  return (
    <Box
      sx={{
        display: 'flex',
        mt: 2,
        gap: 1,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <Box
        sx={{
          width: '50%',
          mb: {
            xs: 2,
            sm: 0,
          },
        }}
      >
        <Typography sx={{ mb: 0.5 }} variant="bodySmall" color="secondary">{t('common:number')}</Typography>
        <PhoneNumberDisplay phoneNumber={phoneNumber} />
      </Box>
      <Box sx={{ width: '50%' }}>
        <Typography sx={{ mb: 0.5 }} variant="bodySmall" color="secondary">
          {t('settings:information_visible_to')}
        </Typography>
        <Typography variant="bodyMedium">
          {
            privacySetting
              ? t(PRIVACY_SETTING_WORDING[privacySetting][realm]!)
              : '-'
          }
        </Typography>
      </Box>
    </Box>
  );
}
