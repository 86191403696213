import { Box, Card, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum, Order_Status_Enum } from 'kheops-graphql';
import OrderChipsInfo from './OrderChipsInfo';
import AddPackagingsButton from './AddPackagingsButton';
import { orderAtom, viewOrderItemsAtom } from '../state/state';
import PackagingDisplayBox from '../../common/components/PackagingDisplayBox';
import { currentContextAtom } from '../../state';

const CONTEXT_TO_STATUS_CAN_EDIT_ORDER_ITEMS = new Map<Business_Profile_Enum, Order_Status_Enum[]>([
  [Business_Profile_Enum.Buyer, [
    Order_Status_Enum.OrderToBeValidatedByBuyer,
    Order_Status_Enum.OrderToBeValidatedBySupplier,
    Order_Status_Enum.OrderToBeReceivedByBuyer,
  ]],
  [Business_Profile_Enum.Supplier, [
    Order_Status_Enum.OrderToBeValidatedByBuyer,
    Order_Status_Enum.OrderToBeValidatedBySupplier,
    Order_Status_Enum.OrderToBePreparedBySupplier,
    Order_Status_Enum.OrderToBeDeliveredBySupplier,
    Order_Status_Enum.OrderToBeReceivedByBuyer,
  ]],
]);

export default function OrderPackagings(): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const order = useAtomValue(orderAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const items = useAtomValue(viewOrderItemsAtom);
  const canEditItems = CONTEXT_TO_STATUS_CAN_EDIT_ORDER_ITEMS.get(realm!)?.includes(order.status);

  const sortedItems = useMemo(() => {
    return items.sort(
      (a, b) => {
        return a.packaging.product!.name.localeCompare(b.packaging.product!.name)
          || a.packaging.base_unit!.unit_type!.localeCompare(b.packaging.base_unit!.unit_type!)
          || a.packaging.trade_item_unit_descriptor.localeCompare(b.packaging.trade_item_unit_descriptor);
      },
    );
  }, [items]);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          gap: 2,
          flexWrap: 'wrap',
        }}
      >
        <OrderChipsInfo />
        {canEditItems && (
          <AddPackagingsButton
            supplierCompanyId={order.contract.supplyingCompanyId}
            buyerCompanyId={order.contract.buyingCompanyId}
            excludedObjectIds={items.map((pq) => `${pq.packaging.sku}-${pq.packaging.company_id}`)}
          />
        )}
      </Box>
      <Box>
        <Typography variant="titleMedium">
          {t('order:base_unit_list')}
        </Typography>
        <Box>
          {sortedItems.map(({ packaging, hasJustBeenAdded, notPresentInPreviousVersion }, index) => (
            <PackagingDisplayBox
              key={packaging.id}
              index={index}
              orderStatus={order.status}
              packagingDisplay={packaging}
              canEditQuantity={canEditItems}
              shouldEditQuantityBeEnabledByDefault={hasJustBeenAdded}
              shouldDisplayAddedPackagingBadge={hasJustBeenAdded || notPresentInPreviousVersion}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
}
