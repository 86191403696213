import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryIcon from '../../assets/icons/delivery.svg?react';
import { RoutePaths } from '../../routes/AppRoutes';
import BackButton from '../../common/components/BackButton';
import TopBar from '../../common/components/TopBar';
import CompanyInfoSection from '../CompanyInfoSection';
import DeliveryHoursInfo from './deliveryHours/DeliveryHoursInfo';
import ContactsInfo from './contacts/ContactsInfo';
import DeliveryAddressInfo from './address/DeliveryAddressInfo';

export default function BuyerDeliveryInfoPage(): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.NAVIGATION_MAIN}
          shouldCheckHistory={false}
          compact
        />
        <DeliveryIcon height={32} width={32} />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t('common:delivery')}
        </Typography>
      </TopBar>
      <Card
        sx={{
          height: {
            lg: 'calc(100% - 48px)',
          },
          mt: {
            xs: 8,
            lg: 0,
          },
          px: {
            xs: 0,
            md: 3,
          },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: 3,
          overflow: 'auto',
          backgroundColor: {
            xs: 'transparent',
            lg: 'background.paper',
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
            alignItems: 'center',
            gap: 1,
          }}
        >
          <DeliveryIcon height={32} width={32} />
          <Typography variant="titleLarge">
            {t('common:delivery')}
          </Typography>
        </Box>
        <CompanyInfoSection icon="📍" title={t('common:address')}>
          <DeliveryAddressInfo />
        </CompanyInfoSection>
        <CompanyInfoSection icon="⏰" title={t('common:hours')}>
          <DeliveryHoursInfo />
        </CompanyInfoSection>
        <CompanyInfoSection icon="👤" title={t('common:contacts')}>
          <ContactsInfo type="DELIVERY" />
        </CompanyInfoSection>
      </Card>
    </>
  );
}
