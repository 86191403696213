export type Palette = {
  [index: number]: string;
  0: string;
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  95: string;
  98: string;
  100: string;
};

export const PrimaryPalette: Palette = {
  0: '#000000',
  10: '#291800',
  20: '#452B00',
  30: '#624000',
  40: '#7F5610',
  50: '#9B6E28',
  60: '#B8883F',
  70: '#D6A257',
  80: '#F4BD6F',
  90: '#FFDDB3',
  95: '#FFEEDC',
  98: '#FFF8F4',
  100: '#FFFFFF',
};

export const SecondaryPalette: Palette = {
  0: '#000000',
  10: '#271904',
  20: '#3B2E1D',
  30: '#534432',
  40: '#6F5B40',
  50: '#85745F',
  60: '#A08E78',
  70: '#BCA891',
  80: '#D8C3AB',
  90: '#FADEBC',
  95: '#FFEEDC',
  98: '#FFF8F4',
  100: '#FFFFFF',
};

export const TertiaryPalette: Palette = {
  0: '#000000',
  10: '#102004',
  20: '#28341D',
  30: '#3E4B32',
  40: '#516440',
  50: '#6E7C5F',
  60: '#879578',
  70: '#A1B091',
  80: '#BDCCAB',
  90: '#D4EABC',
  95: '#E7F6D3',
  98: '#EFFFDC',
  100: '#FFFFFF',
};

export const NeutralPalette: Palette = {
  0: '#000000',
  10: '#201B13',
  20: '#362F27',
  30: '#4A4642',
  40: '#625D59',
  50: '#7C7671',
  60: '#96908A',
  70: '#B1AAA4',
  80: '#CCC5BF',
  87: '#E4D8CC',
  90: '#EDE0D4',
  92: '#F3E6DA',
  94: '#F9ECDF',
  95: '#F7EFE9',
  96: '#FEF1E5',
  98: '#FFF8F4',
  100: '#FFFFFF',
};

export const NeutralVariantPalette: Palette = {
  0: '#000000',
  10: '#201B13',
  20: '#362F27',
  30: '#4F4539',
  40: '#665D53',
  50: '#817567',
  60: '#998F84',
  70: '#B4A99E',
  80: '#D3C4B4',
  90: '#EDE0D4',
  95: '#FCEFE2',
  98: '#FFF8F4',
  100: '#FFFFFF',
};

export const ErrorPalette: Palette = {
  0: '#000000',
  10: '#410002',
  20: '#690005',
  30: '#93000A',
  40: '#BA1A1A',
  50: '#DE3730',
  60: '#FF5449',
  70: '#FF897D',
  80: '#FFB4AB',
  90: '#FFDAD6',
  95: '#FFEDEA',
  98: '#FFF8F7',
  100: '#FFFFFF',
};

export const SuccessPalette: Palette = {
  0: '#000000',
  10: '#072100',
  20: '#103800',
  30: '#1F5105',
  40: '#376A1F',
  50: '#4F8435',
  60: '#689F4C',
  70: '#81BA64',
  80: '#9CD67D',
  90: '#C0F0A5',
  95: '#CEFFB2',
  98: '#EEFFDE',
  100: '#FFFFFF',
};

export const WarningPalette: Palette = {
  0: '#000000',
  10: '#2E1500',
  20: '#4C2700',
  30: '#6C3A00',
  40: '#8E4E00',
  50: '#B26300',
  60: '#D77900',
  70: '#F89326',
  80: '#FFB779',
  90: '#FFDCC1',
  95: '#FFEEE2',
  98: '#FFF8F5',
  100: '#FFFFFF',
};
