import { Box, Button, Checkbox, FormControlLabel, FormHelperText, Grid2, TextField, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { format } from 'date-fns';
import { Delivery_Hours_Insert_Input } from 'kheops-graphql';
import DeliveryTimePicker from './DeliveryTimePicker';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import { currentContextAtom } from '../../../state';
import { UserRoleDocument } from '../../../queries/__generated__/userRole.generated';
import { useUpsertDeliveryHoursMutation } from '../../../mutations/__generated__/upsertDeliveryHours.generated';
import { DeliveryHoursDraft, deliveryHoursDrawerOpenAtom, WeekDays } from '../state';
import useDeliveryHoursFormDefaultValues from './hooks/useDeliveryHoursFormDefaultValues';
import { BaseFormProps } from '../FormDrawer';

export default function DeliveryHoursForm({ onCancel, onChange }: BaseFormProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'date', 'settings']);
  const { companyId } = useAtomValue(currentContextAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const setDeliveryHoursDrawerOpen = useSetAtom(deliveryHoursDrawerOpenAtom);
  const [upsertDeliveryHours, { data: upsertDeliveryHoursData }] = useUpsertDeliveryHoursMutation({
    refetchQueries: [UserRoleDocument],
  });
  const defaultValues = useDeliveryHoursFormDefaultValues();
  const { control, handleSubmit, setValue, watch, formState: { isDirty, isSubmitted } } = useForm<DeliveryHoursDraft>({
    defaultValues,
  });
  const weekDays = watch('weekDays');
  const values = watch();

  const handleWeekDayChange = (weekDay: WeekDays): void => {
    const weekDayIndex = weekDays.indexOf(weekDay);
    const newWeekDays = [...weekDays];

    if (weekDayIndex > -1) {
      newWeekDays.splice(weekDayIndex, 1);
    } else {
      newWeekDays.push(weekDay);
    }

    setValue('weekDays', newWeekDays, { shouldDirty: true });
  };

  const onSubmit = useCallback(async (deliveryHoursDraft: DeliveryHoursDraft): Promise<void> => {
    const deliveryHoursInsertInput: Delivery_Hours_Insert_Input[] = [...deliveryHoursDraft.weekDays.values()].map((weekDay) => (
      {
        company_id: companyId,
        week_day: weekDay,
        opening_hour: format(deliveryHoursDraft.openingHour!, 'HH:mm:ss.SSSS'),
        closing_hour: format(deliveryHoursDraft.closingHour!, 'HH:mm:ss.SSSS'),
      }
    ));

    upsertDeliveryHours({
      variables: {
        companyId,
        deliveryHours: deliveryHoursInsertInput,
        deliveryDescription: deliveryHoursDraft.deliveryDescription || null,
      },
    });
  }, []);

  const validateTimes = (): boolean => {
    const openingHour = watch('openingHour');
    const closingHour = watch('closingHour');

    // try/catch cause if a user enters a value manually in time picker, it returns an invalid date until the value is complete
    try {
      return !weekDays.length || !openingHour || !closingHour || format(openingHour, 'HH:mm:ss') < format(closingHour, 'HH:mm:ss');
    } catch {
      return true;
    }
  };

  useEffect((): void => {
    if (upsertDeliveryHoursData?.insert_delivery_hours) {
      setCommonSnackbarProps({
        label: t(defaultValues.weekDays!.length ? 'settings:delivery_hours_successfully_updated' : 'settings:delivery_hours_successfully_added'),
        snackbarProps: {
          open: true,
        },
      });
      setDeliveryHoursDrawerOpen(false);
    }
  }, [upsertDeliveryHoursData]);

  useEffect((): void => {
    onChange(isDirty);
  }, [values, isDirty]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="titleMedium">
          {t('settings:select_delivery_days')}
        </Typography>
        <Grid2
          container
          spacing={1}
          sx={{
            '& .MuiBox-root': {
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 4,
              p: 1,
              py: 0.75,
            },
          }}
        >
          {Object.keys(WeekDays).map((weekDay) => (
            <Grid2 size={{ xs: 12, lg: 6 }} key={weekDay}>
              <Box>
                <FormControlLabel
                  control={<Checkbox checked={weekDays.includes(weekDay as WeekDays)} />}
                  label={t(`date:${weekDay.toLowerCase()}`)}
                  onChange={() => handleWeekDayChange(weekDay as WeekDays)}
                />
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="titleMedium">
          {t('settings:select_delivery_hours')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
            <Typography variant="bodySmall" color="secondary">
              {t('settings:opening')}
            </Typography>
            <Controller
              name="openingHour"
              control={control}
              rules={{
                required: !!weekDays.length,
                validate: { time: validateTimes },
              }}
              render={({ field, fieldState }) => (
                <DeliveryTimePicker
                  timePickerProps={{ ...field, maxTime: watch('closingHour') }}
                  error={fieldState.invalid}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
            <Typography variant="bodySmall" color="secondary">
              {t('settings:closing')}
            </Typography>
            <Controller
              name="closingHour"
              control={control}
              rules={{
                required: !!weekDays.length,
                validate: { time: validateTimes },
              }}
              render={({ field, fieldState }) => (
                <DeliveryTimePicker
                  timePickerProps={{ ...field, minTime: watch('openingHour') }}
                  error={fieldState.invalid}
                />
              )}
            />
          </Box>
        </Box>
        {!validateTimes() && isSubmitted && <FormHelperText error>{t('settings:opening_hour_should_be_earlier_than_closing_hour')}</FormHelperText>}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="titleMedium">
          {t('settings:additional_info')}
        </Typography>
        <Controller
          name="deliveryDescription"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              multiline
              minRows={2}
              placeholder={t('settings:additional_info_placeholder')}
              {...field}
            />
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, position: 'sticky', bottom: -24, backgroundColor: 'white', py: 1 }}>
        <Button variant="text" onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Button variant="contained" type="submit" disabled={!isDirty}>
          {t('common:validate')}
        </Button>
      </Box>
    </Box>
  );
}
