import { Card, Grid2, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import { PackagingDetail } from './PackagingPricesDetails';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import DiscountChip from '../../common/components/DiscountChip';
import i18n from '../../i18n';

export interface PackagingPricesCardProps {
  packaging: PackagingDetail;
}

const imageSizes = {
  xs: 94,
  sm: 138,
};

export default function PackagingPricesCard({ packaging }: PackagingPricesCardProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  return (
    <Grid2 size={{ xs: 12, sm: 6, lg: 4 }} key={packaging.id}>
      <Card
        sx={{
          px: {
            xs: 1,
            sm: 2,
          },
          py: {
            xs: 2,
            sm: 2,
          },
          my: 0.5,
          boxSizing: 'border-box',
          boxShadow: 'unset',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: {
              xs: 'row',
              sm: 'column',
            },
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              margin: {
                sm: 'auto',
              },
              height: imageSizes,
              width: imageSizes,
            }}
          >
            <SizedImage
              src={FileUtils.BuildPackagingImageUrl({
                photo: packaging.base_unit.main_photo,
                type: packaging.product.sub_family,
                size: { height: 264, fit: 'contain' },
              })}
              height={imageSizes}
              width={imageSizes}
              borderRadius={0}
              sx={{
                maxHeight: imageSizes,
                maxWidth: imageSizes,
              }}
              alt={t('common:product_one')}
            />
            {!!packaging.discount && (
              <DiscountChip
                discount={packaging.discount}
                color="discount"
                sx={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  position: {
                    xs: 'initial',
                    sm: 'absolute',
                  },
                  top: 0,
                  right: 0,
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              ml: {
                xs: 1,
                sm: 0,
              },
              pt: {
                sm: 3,
              },
              minWidth: 0,
            }}
          >
            <Box
              sx={{
                minHeight: 0,
                overflow: 'hidden',
                height: {
                  sm: '42px',
                },
              }}
            >
              <Typography variant="bodyMedium">
                {packaging.product.name}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
              <Typography
                variant="bodySmall"
                color="secondary"
                sx={{
                  backgroundColor: 'secondary.light',
                  borderRadius: 4,
                  py: 0.125,
                  px: 0.5,
                  mr: 0.5,
                }}
              >
                {FormatBaseUnitQuantity(packaging.base_unit, 'fr', numberFormat)}
              </Typography>
              <Typography variant="bodySmall" color="secondary">
                {FormatLogisticalPackaging(packaging, 'fr', packaging.base_unit)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, gap: 0.5 }}>
              {packaging.discount
                ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      variant="titleLarge"
                      sx={{
                        backgroundColor: 'discount.main',
                        p: 0.5,
                        borderRadius: 2,
                      }}
                    >
                      {packaging.discountedPrimaryPriceDisplay}
                    </Typography>
                    <Typography sx={{ textDecorationLine: 'line-through' }}>
                      {packaging.primaryPriceDisplay}
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="titleLarge" color="primary.main">
                    {packaging.primaryPriceDisplay}
                  </Typography>
                )}
              <Box sx={{ display: 'flex', gap: 1 }}>
                {packaging.discountedSecondaryPriceDisplay && (
                  <Typography sx={{ fontWeight: 700 }}>
                    {packaging.discountedSecondaryPriceDisplay}
                  </Typography>
                )}
                {packaging.discount
                  ? (
                    <Typography sx={{ textDecorationLine: 'line-through' }}>
                      {packaging.secondaryPriceDisplay}
                    </Typography>
                  )
                  : <Typography>{packaging.secondaryPriceDisplay}</Typography>}
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid2>
  );
}
