import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router-dom';
import TopBar from '../common/components/TopBar';
import BackButton from '../common/components/BackButton';
import { RoutePaths } from '../routes/AppRoutes';
import { PageWrapper } from '../common/components/PageWrapper';
import { currentContextAtom } from '../state';
import CompanyProductSearch from './CompanyProductSearch';
import MyCompanyInfoCard from './MyCompanyInfoCard';

export default function MyCompanyPage(): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['common']);
  const { companyId } = useAtomValue(currentContextAtom);

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.NAVIGATION_MAIN}
          shouldCheckHistory={false}
          compact
        />
        <Typography variant="displaySmall" sx={{ ml: 1 }}>
          {t('settings:my_company')}
        </Typography>
      </TopBar>
      <PageWrapper>
        <MyCompanyInfoCard />
        <Card
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              alignItems: 'center',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <Typography variant="displaySmall">
              {t('common:product_other')}
            </Typography>
            <Button
              variant="outlined"
              component={Link}
              to={RoutePaths.COMPANY_PRODUCTS}
              startIcon={<EditIcon />}
            >
              {t('settings:update_my_products')}
            </Button>
          </Box>

          <Box
            sx={{
              '& .MuiGrid-root.MuiGrid-item': {
                [theme.breakpoints.down('sm')]: {
                  paddingLeft: 0,
                },
              },
              '& .MuiPaper-root.MuiCard-root': {
                [theme.breakpoints.down('sm')]: {
                  border: 'none',
                  paddingLeft: 0,
                },
              },
            }}
          >
            <CompanyProductSearch companyId={companyId} />
          </Box>
        </Card>
      </PageWrapper>
    </>
  );
}
