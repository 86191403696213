import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Address, Custom_Price_List_Packaging, Company, Contract, Custom_Price_List, Maybe, Product, Product_Max_Fields, Sub_Catalog, Sub_Catalog_Packaging } from 'kheops-graphql';
import { ContractsAsBuyerQueryVariables, ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';
import {
  ContractsAsSupplierDocument,
  ContractsAsSupplierQuery,
  ContractsAsSupplierQueryVariables,
} from '../queries/__generated__/contractsAsSupplier.generated';
import { DisplayPhoto } from '../common/common';
import { contractsAtom, currentContextAtom, userAtom } from '../state';

export type ContractPreview = Pick<Contract, 'id' | 'reference_id' | 'buyingCompanyId' | 'supplyingCompanyId' | 'minimum_order_value' | 'order_count' | 'last_order_date' | 'status' | 'discount' | 'custom_price_list_id' | 'channel_url' | 'supplier_commission_rate' | 'sub_catalog_id'>
  & {
    company: Pick<Company, 'id' | 'tradeName' | 'business_profile' | 'brand'>
      & {
        products?: Pick<Product, 'sub_family'>[];
        addresses: Pick<Address, 'latitude' | 'longitude'>[];
        photos: DisplayPhoto[];
        products_aggregate?: {
          aggregate: {
            max: Pick<Product_Max_Fields, 'sub_family'>,
          },
        }
      };
    custom_price_list?: Maybe<Pick<Custom_Price_List, 'name'> & {
      custom_price_list_packagings: Pick<Custom_Price_List_Packaging, 'company_id' | 'discount' | 'packaging_sku'>[]
    }>;
    sub_catalog?: Maybe<Pick<Sub_Catalog, 'name'> & {
      sub_catalog_packagings: Pick<Sub_Catalog_Packaging, 'supplier_company_id' | 'packaging_sku'>[];
    }>;
    total_revenue: number;
  };

export default function useContracts(): boolean {
  const context = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const setContracts = useSetAtom(contractsAtom);
  const [contractsAvailable, setContractsAvailable] = useState(false);

  const [getContracts, { data, loading, called }] = useLazyQuery<
    ContractsAsSupplierQuery,
    ContractsAsBuyerQueryVariables | ContractsAsSupplierQueryVariables
  >(
    context.realm === 'BUYER' ? ContractsAsBuyerDocument : ContractsAsSupplierDocument,
  );

  useEffect((): void => {
    if (userId) {
      getContracts({ variables: { companyId: context.companyId, userId } });
    }
  }, [userId]);

  useEffect((): void => {
    const contractTotatPrices = new Map(data?.all_contracts_total_price.map(({ reference_id, total_price }) => ([reference_id, total_price])));
    const contracts = data?.contract.map((contract) => ({ ...contract, total_revenue: contractTotatPrices.get(contract.reference_id!) || 0 })) || [];

    setContracts(contracts);

    if (data) {
      setContractsAvailable(true);
    }
  }, [data?.contract]);

  return !loading && called && contractsAvailable;
}
