import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtomValue } from 'jotai';
import { Order_Status_Enum, TaskAction } from 'kheops-graphql';
import useOrderActions from '../../hooks/useOrderActions';
import OrderDeliveryDateDialog, { DeliveryDateOrderStatus } from './OrderDeliveryDateDialog';
import { isPerformingActionAtom } from '../state/state';

interface OrderUpdateDeliveryDateButtonProps {
  orderReferenceId: string;
  orderId: string;
  orderStatus: DeliveryDateOrderStatus;
  useEditIcon?: boolean;
}

export default function OrderUpdateDeliveryDateButton({ orderReferenceId, orderId, orderStatus, useEditIcon }: OrderUpdateDeliveryDateButtonProps): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const [isDeliveryDateDialogOpen, setIsDeliveryDateDialogOpen] = useState(false);

  const { updateOrder, isLoading } = useOrderActions({ orderReferenceId, orderId });
  const isPerformingAction = useAtomValue(isPerformingActionAtom);

  const handleDeliveryDialog = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      updateOrder(orderStatus === Order_Status_Enum.OrderToBeValidatedBySupplier ? TaskAction.Approve : undefined);
    }
    setIsDeliveryDateDialogOpen(false);
  };

  const buttonComponent = useMemo(() => {
    if (useEditIcon) {
      return (
        <Button variant="text" onClick={() => setIsDeliveryDateDialogOpen(true)}>
          <EditOutlinedIcon />
        </Button>
      );
    }
    return (
      <LoadingButton
        variant="outlined"
        onClick={() => setIsDeliveryDateDialogOpen(true)}
        startIcon={<EditOutlinedIcon />}
        loading={isLoading || isPerformingAction}
        sx={{ flex: 'auto' }}
      >
        {t('order:update_delivery_date')}
      </LoadingButton>
    );
  }, [useEditIcon, orderStatus, isLoading, isPerformingAction]);

  return (
    <>
      {buttonComponent}
      <OrderDeliveryDateDialog
        open={isDeliveryDateDialogOpen}
        orderStatus={orderStatus as DeliveryDateOrderStatus}
        onClose={(date) => handleDeliveryDialog(!!date)}
        actionType={TaskAction.Modify}
      />
    </>
  );
}
