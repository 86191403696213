import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

interface ProductIngredientsProps {
  ingredients: string;
}

export default function ProductIngredients({ ingredients }: ProductIngredientsProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Box>
      <Typography variant="titleSmall" sx={{ mb: 1 }}>
        {t('products:ingredient_list')}
      </Typography>
      <Typography variant="bodySmall">
        {ingredients}
      </Typography>
    </Box>
  );
}
