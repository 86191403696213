/* eslint-disable import/no-duplicates */
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export interface MessageTimeProps {
  time: number;
}

export default function MessageTime({ time }: MessageTimeProps): React.JSX.Element {
  return (
    <Typography
      variant="labelSmall"
      color="secondary"
      sx={{
        mt: 0.5,
        textAlign: 'right',
      }}
    >
      {format(time, 'p', { locale: fr })}
    </Typography>
  );
}
