import { Box, Button, Chip, SxProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { round } from 'kheops-utils';
import PercentIcon from '../../assets/icons/percent.svg?react';

export interface CommissionInfoProps {
  commission: number;
  size?: 'small' | 'medium';
  short?: boolean;
  sx?: SxProps;
}

export default function CommissionInfo({ commission, size, short, sx }: CommissionInfoProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        ...sx,
      }}
    >
      <Chip
        size={size || 'medium'}
        icon={<PercentIcon />}
        label={` ${round(commission * 100)}%`}
        sx={{
          backgroundColor: 'greys.primary',
          fontWeight: 400,
          height: 24,
        }}
      />
      {short
        ? (
          <Button variant="text" onClick={() => Intercom('showArticle', 9425028)}>
            <InfoOutlinedIcon />
          </Button>
        )
        : (
          <Button variant="text" disableRipple onClick={() => Intercom('showArticle', 9425028)}>
            {t('common:kheops_commission')}
          </Button>
        )}
    </Box>
  );
}
