import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandableText from '../../../common/components/ExpandableText';

interface ProductDescriptionProps {
  description: string;
}

export default function ProductDescription({ description }: ProductDescriptionProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);

  return (
    <Box>
      <Typography variant="titleSmall" sx={{ mb: 2 }}>
        {t('contracts:description')}
      </Typography>
      <ExpandableText
        text={description}
        lineClampThreshold={3}
        variant="bodySmall"
      />
    </Box>
  );
}
