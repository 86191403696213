import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../common/components/LoadingDots';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { BasketItem } from './state/basket';
import { isBasketLoadingAtom } from './state/state';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';

export interface BasketOrderPriceProps {
  items: BasketItem[];
}

export function BasketOrderPrice({ items }: BasketOrderPriceProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const currencyFormat = useCurrencyFormat();
  const isBasketLoading = useAtomValue(isBasketLoadingAtom);

  const totalPrice = items.reduce(
    (total, { quantity, packaging }) => total + quantity * packaging.price,
    0,
  );
  const minimumOrderValue = items[0].contract.minimum_order_value!;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="bodySmall">
        {t('common:total')} :
      </Typography>
      <LoadingDots loading={isBasketLoading}>
        <Typography variant="bodySmall">
          &nbsp;{currencyFormat.format(totalPrice)}
        </Typography>
      </LoadingDots>
      <MinimumOrderValueChip
        minimumOrderValue={minimumOrderValue}
        totalPrice={totalPrice}
        enableProgress
        sx={{ ml: 1 }}
      />
    </Box>
  );
}
