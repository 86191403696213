import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import BlackBackgroundTooltip from '../../common/components/BlackBackgroundTooltip';
import { orderAtom, orderInvoiceAtom } from '../state/state';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';

export default function OrderTotalPriceDisplay(): React.JSX.Element {
  const currencyFormat = useCurrencyFormat();
  const { t } = useTranslation(['order', 'price']);
  const invoice = useAtomValue(orderInvoiceAtom);
  const order = useAtomValue(orderAtom);
  const invoicePriceExclTax = (invoice?.revised_price_excl_tax || invoice?.price_excl_tax) as number;
  const invoicePriceInclTax = (invoice?.revised_price_incl_tax || invoice?.price_incl_tax) as number;
  const invoicePriceExclTaxDiffersFromOrder = !!invoicePriceExclTax && (order.total_price_excl_tax !== invoicePriceExclTax);
  const displayedExclTaxPrice = invoicePriceExclTax || (order.total_price_excl_tax as number);
  const displayedInclTaxPrice = invoicePriceInclTax || (order.total_price_incl_tax as number);

  const priceExclTaxDisplay = (
    invoicePriceExclTaxDiffersFromOrder && invoice
      ? (
        <BlackBackgroundTooltip
          title={t('order:invoice_total_different_from_kheops_order')}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              color: 'orange',
              alignItems: 'center',
            }}
          >
            <ErrorIcon />
            <Typography variant="titleMedium" sx={{ color: 'inherit' }}>{currencyFormat.format(displayedExclTaxPrice)}</Typography>
          </Box>
        </BlackBackgroundTooltip>
      )
      : (<Typography variant="titleMedium" color="primary">{currencyFormat.format(displayedExclTaxPrice)}</Typography>)
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
      }}
    >
      {priceExclTaxDisplay}
      <Typography variant="bodySmall" color="secondary">{t('price:price_tax_included')}: {currencyFormat.format(displayedInclTaxPrice)}</Typography>
    </Box>
  );
}
