import React from 'react';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { Box, Chip, Typography } from '@mui/material';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import { useTranslation } from 'react-i18next';
import { CompanyPackaging } from './state';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import i18n from '../i18n';
import { ellipsisStyle } from '../common/utils/style.utils';

interface CompanyPackagnigCardProps {
  packaging: CompanyPackaging;
}

export default function CompanyPackagingCard({ packaging }: CompanyPackagnigCardProps): React.JSX.Element {
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const { t } = useTranslation(['products']);

  return (
    <>
      <SizedImage
        src={FileUtils.BuildPackagingImageUrl({
          photo: packaging.base_unit.main_photo,
          type: packaging.product.sub_family,
          size: {
            height: 80,
            width: 80,
            fit: 'contain',
          },
        })}
        alt={t('products:csu')}
        width={80}
        height={80}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1, overflow: 'hidden' }}>
        <Typography
          variant="bodyMedium"
          sx={{
            ...ellipsisStyle,
            width: '100%',
          }}
        >
          {packaging.product.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Chip size="small" label={FormatBaseUnitQuantity(packaging.base_unit, 'fr', numberFormat)} />
          <Typography variant="bodySmall" color="secondary">
            {FormatLogisticalPackaging(packaging, 'fr', packaging.base_unit)}
          </Typography>
        </Box>
        {packaging.base_unit.gtin && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DocumentScannerOutlinedIcon sx={{ transform: 'rotate(90deg)', verticalAlign: 'top', mr: 0.5, height: 20, width: 20 }} />
            <Typography variant="bodySmall" color="secondary">
              {packaging.base_unit.gtin}
            </Typography>
          </Box>

        )}
      </Box>
    </>
  );
}
