import { Box, Button, Card, Drawer, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOpenable from '../../hooks/useOpenable';
import ConfirmDialog from '../../common/components/ConfirmDialog';

export interface BaseFormProps {
  onCancel: () => void;
  onChange: (isDirty: boolean) => void;
}

export interface FormDrawerProps<P extends BaseFormProps> {
  open: boolean;
  onClose: () => void;
  title: string;
  FormComponent: FunctionComponent<P>;
  formProps?: React.JSX.IntrinsicAttributes & Omit<P, keyof BaseFormProps>;
}

export default function FormDrawer<P extends BaseFormProps>({ open, onClose, title, FormComponent, formProps }: FormDrawerProps<P>): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const { open: openConfirm, close: closeConfirm, isOpen: isConfirmOpen } = useOpenable();
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = useCallback((): void => {
    if (isDirty) {
      openConfirm();
    } else {
      onClose();
    }
  }, [isDirty]);

  // https://stackoverflow.com/questions/57755071/re-adding-a-property-removed-from-a-generic-type-with-omit-results-in-incompat
  const correctedFormProps = { ...formProps, onCancel: handleClose, onChange: (dirty) => setIsDirty(dirty) } as P;

  const handleConfirm = (confirm: boolean): void => {
    if (confirm) {
      onClose();
    }

    closeConfirm();
  };

  useEffect((): void => {
    setIsDirty(false);
  }, [open]);

  return (
    <>
      <Drawer
        open={open}
        onClose={handleClose}
        anchor="bottom"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            p: 3,
            mb: 3,
            backgroundColor: 'background.paper',
          }}
        >
          <Typography variant="displaySmall">
            {title}
          </Typography>
          <Button
            variant="text"
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Card
          sx={{
            alignSelf: 'center',
            overflow: 'auto',
            mb: 3,
            width: {
              xs: 'calc(100% - 80px)',
              lg: 600,
            },
          }}
        >
          <FormComponent {...correctedFormProps} />
        </Card>
      </Drawer>
      <ConfirmDialog
        title={t('common:abort_form')}
        message={t('common:abort_form_message')}
        confirmLabel={t('common:confirm')}
        cancelLabel={t('common:cancel')}
        open={isConfirmOpen}
        onClose={handleConfirm}
      />
    </>
  );
}
