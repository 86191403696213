import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useAtomValue, useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import { Billing_Type_Enum, Business_Profile_Enum, Order_Status_Enum } from 'kheops-graphql';
import { currentContextAtom } from '../../state';
import PackagingDisplayBox from '../../common/components/PackagingDisplayBox';
import { orderAtom, orderDeliveryDateAtom, thereAreOrderItemsWithUpdatedQuantitiesAtom, viewOrderItemsAtom } from '../state/state';
import DeliveryDatePicker from '../../common/components/DeliveryDatePicker';

export interface OrderValidationDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

export default function OrderValidationDialog({ open, onClose }: OrderValidationDialogProps): React.JSX.Element {
  const theme = useTheme();
  const { realm } = useAtomValue(currentContextAtom);
  const items = useAtomValue(viewOrderItemsAtom);
  const order = useAtomValue(orderAtom);
  const [deliveryDate, setDeliveryDate] = useAtom(orderDeliveryDateAtom);
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false);
  const { t } = useTranslation(['common', 'order']);
  const thereAreItemsWithUpdatedQuantities = useAtomValue(thereAreOrderItemsWithUpdatedQuantitiesAtom);

  const hasNotUpdatedAQuantityInToBePrepared = useMemo(() => (
    order.status === Order_Status_Enum.OrderToBePreparedBySupplier && !thereAreItemsWithUpdatedQuantities
  ), [order, thereAreItemsWithUpdatedQuantities]);

  const handleCancel = (): void => {
    onClose(false);
  };

  const handleConfirm = (): void => {
    if (order.status === Order_Status_Enum.OrderToBeValidatedBySupplier && !deliveryDate && realm === Business_Profile_Enum.Supplier) {
      setShouldShowErrorMessage(true);
      return;
    }
    onClose(true);
  };

  const handleDateChange = (value: Date | null): void => {
    setDeliveryDate(value);
  };

  const packagingsToDisplay = useMemo(() => (

    items
      .filter(
        (item) => (
          thereAreItemsWithUpdatedQuantities
            ? item.defaultQuantity !== item.quantity || item.defaultWeight !== item.weight
            : item.packaging.base_unit?.billing_type === Billing_Type_Enum.WeightDependent
        ),
      )
      .map((item, index) => {
        const canEditWeight = item.packaging.base_unit?.billing_type === Billing_Type_Enum.WeightDependent && (order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier || order.status === Order_Status_Enum.OrderToBePreparedBySupplier);

        return (
          <PackagingDisplayBox
            packagingDisplay={item.packaging}
            orderStatus={order.status}
            canEditQuantity={!hasNotUpdatedAQuantityInToBePrepared}
            canEditWeight={canEditWeight}
            shouldDisplayAddedPackagingBadge={item.hasJustBeenAdded || item.notPresentInPreviousVersion}
            index={index}
          />
        );
      })
  ), [items, order, thereAreItemsWithUpdatedQuantities, hasNotUpdatedAQuantityInToBePrepared]);

  const dateComponent = useMemo(() => {
    if (order.status === Order_Status_Enum.OrderToBeValidatedBySupplier && realm === Business_Profile_Enum.Supplier) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', m: 'auto' }}>
          <Typography variant="titleMedium" sx={{ mb: 1 }}>
            {t('order:verify_delivery_date')}
          </Typography>
          <DeliveryDatePicker
            companyId={order.contract.buyingCompanyId}
            value={deliveryDate}
            onChange={handleDateChange}
          />
          {shouldShowErrorMessage && (<Typography variant="bodySmall" color="error" align="center">{t('order:delivery_date_must_be_set')}</Typography>)}
        </Box>
      );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }, [order, deliveryDate, shouldShowErrorMessage]);

  return (
    <Dialog
      onBackdropClick={handleCancel}
      open={open}
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 960,
          },
        },
      }}
    >
      <DialogTitle>
        <Typography variant="displayMedium">
          {t(!hasNotUpdatedAQuantityInToBePrepared ? 'order:update_the_order' : 'order:weight_validation')}
        </Typography>
        {!hasNotUpdatedAQuantityInToBePrepared && (
          <Typography sx={{ mt: 0.5 }} variant="titleMedium" color="secondary">
            {t('order:verification_quantities_and_weights')}
          </Typography>
        )}
        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent
        sx={{
          boxSizing: 'border-box',
          [theme.breakpoints.down('sm')]: {
            px: 3,
          },
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            mb: 3,
          }}
          variant="bodyMedium"
        >
          {t(!hasNotUpdatedAQuantityInToBePrepared ? 'order:verification_quantities_and_weights_detailed' : 'order:weight_dialog_wording')}
        </Typography>
        <Box>
          <Typography variant="titleMedium" sx={{ mb: 2 }}>{t(!hasNotUpdatedAQuantityInToBePrepared ? 'order:updated_quantities' : 'order:weight_verification')}</Typography>
          <Box>
            {packagingsToDisplay}
          </Box>
        </Box>
        {dateComponent}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="text">
          {t('common:cancel')}
        </Button>
        <Button onClick={handleConfirm} variant="contained">
          {t('common:submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
