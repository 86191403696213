import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Button, Badge, useTheme, useMediaQuery } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingDots from '../common/components/LoadingDots';
import { RoutePaths } from '../routes/AppRoutes';
import BasketSummaryDrawer from './BasketSummaryDrawer';
import { basketDataAtom, isBasketDrawerOpenAtom, isBasketLoadingAtom, isRemoteBasketEmptyAtom } from './state/state';

export default function BasketButton(): React.JSX.Element {
  const location = useLocation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isRemoteBasketEmpty = useAtomValue(isRemoteBasketEmptyAtom);
  const basketData = useAtomValue(basketDataAtom);
  const isBasketLoading = useAtomValue(isBasketLoadingAtom);
  const setIsBasketDrawerOpen = useSetAtom(isBasketDrawerOpenAtom);
  const disabled = useMemo(
    () => [RoutePaths.SEARCH_PRODUCT, RoutePaths.BASKET].includes(location.pathname) && !isSmall,
    [location, isSmall],
  );

  const handleButtonClick = (): void => {
    setIsBasketDrawerOpen(true);
  };

  return (
    <>
      <LoadingDots loading={isBasketLoading}>
        <Badge
          color="primary"
          variant="dot"
          invisible={isRemoteBasketEmpty}
          sx={{
            '& .MuiBadge-badge': {
              top: 6,
              right: 6,
            },
          }}
        >
          <Button variant="text" onClick={handleButtonClick} disabled={disabled}>
            <ShoppingCartOutlinedIcon />
          </Button>
        </Badge>
      </LoadingDots>
      {basketData && <BasketSummaryDrawer />}
    </>
  );
}
