import { Box, SxProps, Typography } from '@mui/material';
import GroupAddOutlined from '@mui/icons-material/GroupAddOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { UserMessage } from '@sendbird/chat/message';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import MessageStatus from '@sendbird/uikit-react/ui/MessageStatus';
import { Chat_Message_Custom_Type } from 'kheops-utils';
import { userAtom } from '../../state';
import NewChannelIcon from '../../assets/icons/new_channel.svg?react';
import MessageTime from './MessageTime';

type ConversationAction = Chat_Message_Custom_Type.CREATED | Chat_Message_Custom_Type.JOINED | Chat_Message_Custom_Type.LEFT | Chat_Message_Custom_Type.INVITED;

export interface ConversationActionMessageProps {
  message: UserMessage;
  action: ConversationAction;
}

interface CustomMessageProps {
  children: React.ReactNode;
  action: ConversationAction;
  sx?: SxProps;
}

const CONVERSATION_ACTION_WORDINGS = {
  [Chat_Message_Custom_Type.CREATED]: {
    actionByYou: 'chat:you_have_created_the_conversation',
    actionByThem: 'chat:someone_has_created_the_conversation',
  },
  [Chat_Message_Custom_Type.JOINED]: {
    actionByYou: 'chat:you_have_joined_the_conversation',
    actionByThem: 'chat:someone_has_joined_the_conversation',
  },
  [Chat_Message_Custom_Type.LEFT]: {
    actionByYou: 'chat:you_have_left_the_conversation',
    actionByThem: 'chat:someone_has_left_the_conversation',
  },
  [Chat_Message_Custom_Type.INVITED]: {
    actionByYou: 'chat:you_have_invited_someone_to_the_conversation',
    actionByThem: 'chat:someone_has_invited_someone_to_the_conversation',
  },
};

function CustomMessage({ children, sx, action }: CustomMessageProps): React.JSX.Element {
  const icon = useMemo(() => {
    switch (action) {
      case Chat_Message_Custom_Type.CREATED:
        return <NewChannelIcon height="20" width="20" style={{ flexShrink: 0 }} />;
      case Chat_Message_Custom_Type.JOINED:
      case Chat_Message_Custom_Type.INVITED:
        return <GroupAddOutlined sx={{ height: 20, transform: 'scaleX(-1)' }} />;
      case Chat_Message_Custom_Type.LEFT:
        return <LogoutOutlinedIcon sx={{ height: 20 }} />;
      default:
        return undefined;
    }
  }, [action]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderRadius: 4,
        borderColor: 'divider',
        p: 1,
        display: 'inline-block',
        ...sx,
      }}
    >
      <Typography
        variant="bodySmall"
        sx={{
          display: 'flex',
          gap: 0.5,
          alignItems: 'center',
        }}
      >
        { icon }
        { children }
      </Typography>
    </Box>
  );
}

const Message = React.memo(CustomMessage);

export default function ConversationActionMessage({ message, action }: ConversationActionMessageProps): React.JSX.Element {
  const { t } = useTranslation();
  const { currentChannel } = useGroupChannelContext();
  const { id: userId } = useAtomValue(userAtom);

  const isByMe = message.sender?.userId === userId;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isByMe
        ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              '& .sendbird-message-status': {
                justifyContent: 'flex-end',
              },
              px: 3,
              overflow: 'hidden',
            }}
          >
            <Message sx={{ mb: 0.5, maxWidth: 400 }} action={action}>
              { t(CONVERSATION_ACTION_WORDINGS[action].actionByYou, { targetName: message.data }) }
            </Message>
            <MessageStatus message={message} channel={currentChannel!} />
          </Box>
        )
        : (
          <Box sx={{ display: 'inline-block', maxWidth: 400, px: 3, overflow: 'hidden' }}>
            <Message action={action}>
              { t(CONVERSATION_ACTION_WORDINGS[action].actionByThem, { name: message.sender.nickname, targetName: message.data }) }
            </Message>
            <MessageTime time={message.createdAt} />
          </Box>
        )}
    </>
  );
}
