import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { addBusinessDays, addDays, format, isWeekend } from 'date-fns';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Holidays from 'date-holidays';
import { orderSddPaymentDateAtom } from '../../state/state';
import useOpenable from '../../../hooks/useOpenable';
import { longDateFormat } from '../../../common/state/state';

interface OrderPaymentSchedulingDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
}

export default function OrderPaymentSchedulingDialog({ open, onClose }: OrderPaymentSchedulingDialogProps): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'payment']);
  const { isOpen: isOpenDateModal, open: openDateModal, close: closeDateModal } = useOpenable();
  const today = new Date();
  const defaultDate = addDays(today, Number(import.meta.env.REACT_APP_SDD_DEFAULT_PAYMENT_DELAY));
  const firstSelectableDate = addBusinessDays(today, 2);
  const [orderSddPaymentDate, setOrderSddPaymentDate] = useAtom(orderSddPaymentDateAtom);
  const [initialOrderPaymentDate] = useState<Date | undefined | null>(orderSddPaymentDate);
  const frenchHolidays = new Holidays('FR');

  useEffect(() => {
    if (open && !orderSddPaymentDate) {
      setOrderSddPaymentDate(defaultDate);
    }
  }, [open]);

  const disableWeekendsAndFrenchHolidays = useCallback((date: Date) => {
    return isWeekend(date) || !!frenchHolidays.isHoliday(date);
  }, []);

  const handleCancel = (): void => {
    setOrderSddPaymentDate(initialOrderPaymentDate);
    onClose(false);
  };

  const handleConfirm = (): void => {
    onClose(true);
  };

  const isConfirmDisabled = useMemo(() => {
    return !orderSddPaymentDate
      || (orderSddPaymentDate.getTime() < today.getTime())
      || (!!initialOrderPaymentDate && (format(orderSddPaymentDate, longDateFormat) === format(initialOrderPaymentDate, longDateFormat)));
  }, [orderSddPaymentDate]);

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>
        <Typography variant="displayMedium">
          {t('payment:schedule_payment')}
        </Typography>
        <Divider sx={{ mt: 3, mb: 1 }} />
      </DialogTitle>
      <DialogContent
        sx={{
          width: '590',
          align: 'center',
          justifyContent: 'center',
          m: 'auto',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            px: 0,
          },
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            mb: 3,
            [theme.breakpoints.down('sm')]: {
              px: 2,
            },
          }}
          variant="bodyMedium"
        >
          {t('payment:schedule_payment_description')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: 'auto' }}>
          <Typography variant="titleMedium" sx={{ mb: 1, textAlign: 'center' }}>
            {t('payment:choose_payment_date')}
          </Typography>
          <DatePicker
            value={orderSddPaymentDate}
            open={isOpenDateModal}
            onClose={closeDateModal}
            onChange={setOrderSddPaymentDate}
            shouldDisableDate={disableWeekendsAndFrenchHolidays}
            slotProps={{
              inputAdornment: {
                position: 'start',
                onClick: openDateModal,
              },
              textField: {
                onClick: openDateModal,
              },
            }}
            minDate={firstSelectableDate}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="text">
          {t('common:cancel')}
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={isConfirmDisabled}
          variant="contained"
        >
          {t('common:validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
