import { Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InstantSearch } from 'react-instantsearch';
import AddIcon from '@mui/icons-material/Add';
import { useSearchClient } from '../../hooks/useSearchClient';
import AddPackagingsDialog from './AddPackagingsDialog';
import FilterUtils from '../../search/filters/filter.utils';

interface AddPackagingsButtonProps {
  supplierCompanyId: string;
  buyerCompanyId: string;
  excludedObjectIds: string[];
}
export default function AddPackagingsButton({ supplierCompanyId, buyerCompanyId, excludedObjectIds }: AddPackagingsButtonProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'discovery', 'common']);
  const filters = `${excludedObjectIds.map((id) => `NOT objectID:${id}`).join(' AND ')} AND ${FilterUtils.visiblePackagingsForBuyerFilter(buyerCompanyId)}`;
  const [isAddPackagingModalOpen, setIsAddPackagingModalOpen] = useState(false);
  const searchClient = useSearchClient();

  const handleButtonClick = (): void => {
    setIsAddPackagingModalOpen(true);
  };

  return (
    <InstantSearch
      indexName={import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX}
      searchClient={searchClient}
    >
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleButtonClick}
      >
        {t('products:add_a_base_unit')}
      </Button>
      <AddPackagingsDialog
        companyId={supplierCompanyId}
        filters={filters}
        isOpen={isAddPackagingModalOpen}
        setIsOpen={setIsAddPackagingModalOpen}
      />
    </InstantSearch>
  );
}
