import { Autocomplete, Box, Button, InputAdornment, TextField, Typography, debounce } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { HTMLAttributes, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import parse from 'autosuggest-highlight/parse';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { placesLibraryReadyAtom } from './state';

interface GoogleMapsAddressAutocompleteProps {
  placeholder?: string;
  onSelectAddress: (address: google.maps.places.PlaceResult) => void;
}

const libraries: Libraries = ['places'];

export default function GoogleMapsAddressAutocomplete({ placeholder, onSelectAddress }: GoogleMapsAddressAutocompleteProps): React.JSX.Element {
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const setPlacesLibraryReadyAtom = useSetAtom(placesLibraryReadyAtom);
  const { t } = useTranslation(['login', 'settings']);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'fr',
    libraries,
  });

  const services = useMemo(() => {
    if (isLoaded) {
      setPlacesLibraryReadyAtom(true);

      return {
        autocompleteService: new window.google.maps.places.AutocompleteService(),
        placesService: new window.google.maps.places.PlacesService(document.createElement('div')),
      };
    }
  }, [isLoaded]);

  const handleInputChange = useCallback(debounce(async (_: SyntheticEvent, value?: string): Promise<void> => {
    if (!value) {
      setOptions([]);

      return;
    }

    const { predictions } = await services!.autocompleteService.getPlacePredictions({ input: value, region: 'fr' });

    setOptions(predictions);
  }), [services]);

  const handlePlaceChanged = useCallback((_: SyntheticEvent, selectedOption: google.maps.places.AutocompletePrediction | null): void => {
    if (!selectedOption) {
      return;
    }

    services!.placesService.getDetails(
      {
        placeId: selectedOption.place_id,
        fields: ['geometry', 'formatted_address', 'address_components', 'place_id'],
      },
      (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && result) {
          onSelectAddress(result);
        }
      },
    );
  }, [services, onSelectAddress]);

  const formatAutocompleteOptions = useCallback((props: HTMLAttributes<HTMLLIElement>, option: google.maps.places.AutocompletePrediction) => {
    const matches = option.structured_formatting.main_text_matched_substrings;
    const parts = parse(
      option.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length]),
    );
    const highlightedOptionChunks = parts.map((part) => (part.highlight ? `<b>${part.text}</b>` : part.text));
    const highlightedOption = `${highlightedOptionChunks.join('')} ${option.structured_formatting.secondary_text}`;

    return (
      <Box component="li" {...props}>
        <Typography dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(highlightedOption) }} />
      </Box>
    );
  }, []);

  return (
    isLoaded
      ? (
        <Autocomplete
          onInputChange={handleInputChange}
          isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
          onChange={handlePlaceChanged}
          getOptionLabel={(option) => option.description}
          options={options}
          filterOptions={(v) => v}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlinedIcon color="secondary" />
                  </InputAdornment>
                ),
                placeholder,
                sx: {
                  '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                  },
                },
              }}
            />
          )}
          renderOption={formatAutocompleteOptions}
          componentsProps={{
            paper: {
              sx: {
                borderRadius: 6,
              },
            },
          }}
          noOptionsText={(
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', p: 2 }}>
              <Typography variant="bodyMedium">{t('settings:your_address_not_in_list')}</Typography>
              <Button
                variant="contained"
                startIcon={<HelpOutlineOutlinedIcon />}
                onClick={() => window.Intercom('show')}
              >
                {t('login:contact_us')}
              </Button>
            </Box>
          )}
        />
      )
      : <></>
  );
}
