import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, AutocompleteChangeReason, AutocompleteChangeDetails, Checkbox, TextField, Typography, SxProps, InputProps as StandardInputProps, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

export interface CheckboxAutocompleteProps<T> {
  options: T[];
  label: string;
  onChange: (values: T[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T>) => void;
  values?: T[];
  // Should be provided when T is not string
  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  InputProps?: Partial<StandardInputProps>;
  sx?: SxProps;
  disabled?: boolean;
}

export default function CheckboxAutocomplete<T = string>(
  { options, label, onChange, isOptionEqualToValue, values, getOptionLabel, InputProps, sx, disabled }: CheckboxAutocompleteProps<T>,
): React.JSX.Element {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [localValues, setLocalValues] = useState<T[]>([]);
  const globalTheme = useTheme();

  const handleOnChange = useCallback((newValues: T[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T>) => {
    setLocalValues(newValues);
    onChange(newValues, reason, details);
  }, [onChange]);

  useEffect((): void => {
    setLocalValues(values || []);
  }, [values]);

  return (
    <Autocomplete
      multiple
      options={options}
      disabled={disabled}
      disableCloseOnSelect
      limitTags={3}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(event, newValues, reason, details) => handleOnChange(newValues, reason, details)}
      renderOption={(renderProps, option, { selected }) => (
        <li {...renderProps}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          <Typography variant="bodyMedium" color="secondary">{typeof option === 'string' ? option : getOptionLabel!(option)}</Typography>
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            startAdornment: (
              <>
                {InputProps?.startAdornment}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          onKeyDown={(event) => {
            if (event.key !== 'Escape') {
              event.stopPropagation();
            }
          }}
          placeholder={!localValues.length ? label : undefined}
          sx={{
            '& .MuiInputLabel-root, & .MuiInputBase-input': {
              fontSize: '0.875rem',
              top: 3,
            },
          }}
        />
      )}
      size="small"
      componentsProps={{
        popper: {
          sx: {
            zIndex: globalTheme.zIndex.snackbar - 1,
          },
        },
      }}
      sx={{
        width: {
          xs: '100%',
          sm: 268,
        },
        '& .MuiAutocomplete-tag': {
          maxWidth: 'calc(100% - 32px)',
        },
        ...sx,
      }}
      value={values}
    />
  );
}
