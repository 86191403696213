import { Badge, Button, Theme } from '@mui/material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { PushTriggerOption } from '@sendbird/chat';
import { useGroupChannelListContext } from '@sendbird/uikit-react/GroupChannelList/context';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { RoutePaths } from '../routes/AppRoutes';
import { getStateLayerColor, StateLayer } from '../theme/color.utils';

export default function ChatButton(): React.JSX.Element {
  const navigate = useNavigate();
  const { groupChannels } = useGroupChannelListContext();
  const matchChatPage = useMatch(RoutePaths.CHAT_LIST);

  const hasUnreadMessages = useMemo((): boolean => {
    return groupChannels.some((channel) => channel.unreadMessageCount && channel.myPushTriggerOption !== PushTriggerOption.OFF);
  }, [groupChannels]);

  return (
    <Badge
      color="primary"
      variant="dot"
      invisible={!hasUnreadMessages}
      sx={{
        '& .MuiBadge-badge': {
          top: 6,
          right: 6,
        },
      }}
    >
      <Button
        variant="text"
        onClick={() => navigate(RoutePaths.CHAT_LIST)}
        sx={(theme: Theme) => ({
          backgroundColor: matchChatPage ? getStateLayerColor(StateLayer.Layer16, theme.palette.surface.main, theme.palette.onSurfaceVariant.main) : undefined,
        })}
      >
        <MessageOutlinedIcon />
      </Button>
    </Badge>
  );
}
