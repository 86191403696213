import { FormHelperText, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import { Trade_Item_Unit_Descriptor_Enum } from 'kheops-graphql';
import { TradeItemUnitDescriptorIntlMap } from 'kheops-utils';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../../ProductFormPackagings';

interface TradeItemUnitSelectProps {
  name: `productPackagings.${number}.packagings.${number}.trade_item_unit_descriptor`;
}

export default function TradeItemUnitSelect({ name }: TradeItemUnitSelectProps): React.JSX.Element {
  const { t } = useTranslation(['packaging', 'products', 'validation']);
  const { control } = useFormContext<ProductPackagingsFormData>();

  const tradeItemUnitValues = useMemo(() => {
    return (Object.keys(TradeItemUnitDescriptorIntlMap) as Trade_Item_Unit_Descriptor_Enum[])
      .reduce((acc, tradeItemUnit) => {
        acc.push({
          tradeItemUnit,
          label: t(`packaging:${TradeItemUnitDescriptorIntlMap[tradeItemUnit]}`),
        });

        return acc;
      }, [] as { tradeItemUnit: Trade_Item_Unit_Descriptor_Enum, label: string }[])
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(({ tradeItemUnit, label }) => (
        <MenuItem value={tradeItemUnit} key={tradeItemUnit}>
          <ListItemText primary={label} />
        </MenuItem>
      ));
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: t('validation:this_field_is_required') as string }}
      render={({ field, fieldState }) => (
        <>
          <Select
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            displayEmpty
            fullWidth
            renderValue={(selected: Trade_Item_Unit_Descriptor_Enum | '') => (
              !selected
                ? <Typography color="secondary">{t('products:packaging_type_placeholder')}</Typography>
                : t(`packaging:${TradeItemUnitDescriptorIntlMap[selected]}`)
            )}
            error={!!fieldState.error}
            sx={{ height: 48 }}
          >
            {tradeItemUnitValues}
          </Select>
          {fieldState.error
            && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
        </>
      )}
    />
  );
}
