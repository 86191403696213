import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

export default function AddedPackagingBadge(): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        gap: 0.5,
        px: 1,
        py: 0.5,
        borderRadius: 2,
        textAlign: 'center',
        backgroundColor: 'marketing1.main',
      }}
    >
      <NewReleasesIcon fontSize="small" />
      <Typography variant="bodySmall">{t('base_unit_added')}</Typography>
    </Box>
  );
}
