import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import BillingTypeSwitch from './BillingTypeSwitch';
import PackagingPrice from './PackagingPrice';

interface PricingFormProps {
  baseUnitIndex: number;
}

export default function PricingForm({ baseUnitIndex }: PricingFormProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'price']);
  const { watch } = useFormContext<ProductPackagingsFormData>();
  const packagings = watch(`productPackagings.${baseUnitIndex}.packagings`);

  return (
    <Box>
      <Typography variant="titleLarge" sx={{ mb: 3 }}>{t('products:pricing')}</Typography>
      <Typography variant="titleMedium" sx={{ mb: 1 }}>{t('products:typology')}</Typography>
      <BillingTypeSwitch name={`productPackagings.${baseUnitIndex}.csu.billing_type`} />
      <Typography variant="titleMedium" sx={{ my: 2 }}>{t('price:catalog_price_without_tax')}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}>
        {
          packagings.map((packaging, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <PackagingPrice key={index} baseUnitIndex={baseUnitIndex} packagingIndex={index} />
          ))
        }
      </Box>
    </Box>
  );
}
