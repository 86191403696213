import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, OutlinedInput, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useLocation, useNavigate } from 'react-router-dom';
import { round } from 'kheops-utils';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import CatalogIcon from '../../assets/icons/catalog.svg?react';
import MinimumOrderValueIcon from '../../assets/icons/minimum_order_value.svg?react';
import { ContractConditions } from '../../chats/state';
import CustomPriceListsSelect from './CustomPriceListsSelect';
import { currentContextAtom } from '../../state';
import CommissionInfo from './CommissionInfo';
import CatalogsSelect from './CatalogsSelect';

enum PriceModeEnum {
  DEFAULT = 'DEFAULT',
  CONTRACT_DISCOUNT = 'CONTRACT_DISCOUNT',
  CUSTOM_PRICE_LIST = 'CUSTOM_PRICE_LIST',
}

const PriceModeWordings = {
  [PriceModeEnum.DEFAULT]: {
    button: 'contracts:default_price',
    description: 'contracts:default_price_description',
  },
  [PriceModeEnum.CONTRACT_DISCOUNT]: {
    button: 'contracts:global_discount',
    description: 'contracts:global_discount_description',
  },
  [PriceModeEnum.CUSTOM_PRICE_LIST]: {
    button: 'contracts:custom_price_list',
    description: 'contracts:custom_price_list_description',
  },
};

export interface ContractUpdateDialogProps {
  open: boolean;
  onClose: (response?: ContractConditions) => void;
  title: string;
  description: string;
  submitLabel: string;
  contractId: string;
  firstOffer?: boolean;
  conditions?: Partial<ContractConditions>;
  commissionRate?: number;
}

export default function ContractConditionsDialog({ open, onClose, title, description, submitLabel, contractId, firstOffer, conditions, commissionRate }: ContractUpdateDialogProps): React.JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'catalogs', 'contracts', 'price']);
  const location = useLocation();
  const { realm } = useAtomValue(currentContextAtom);
  const [newCustomPriceListId] = useState(location.state?.newCustomPriceListId);
  const [newCustomPriceListName] = useState(location.state?.newCustomPriceListName);
  const newSubCatalogId = location.state?.newSubCatalogId;
  const newSubCatalogName = location.state?.newSubCatalogName;
  const [contractData, setContractData] = useState<Partial<ContractConditions>>(conditions || {});
  const [priceMode, setPriceMode] = useState<PriceModeEnum>(PriceModeEnum.DEFAULT);
  const isBuyerEditingDiscount = realm === Business_Profile_Enum.Buyer && !!conditions?.discount;

  const isSubmitDisabled = useMemo((): boolean => {
    const hasMov = contractData.minimum_order_value || contractData.minimum_order_value === 0;
    const hasDiscount = contractData.discount || contractData.discount === 0;

    return !hasMov || (isBuyerEditingDiscount && !hasDiscount) || (!firstOffer && conditions === contractData);
  }, [contractData, conditions]);

  const handleContractConditionsUpdateConfirm = (): void => {
    onClose({
      discount: contractData.discount || 0,
      minimum_order_value: contractData.minimum_order_value!,
      supplyingCompanyId: contractData.supplyingCompanyId!,
      custom_price_list_id: contractData.custom_price_list_id,
      customPriceListName: contractData.customPriceListName,
      proposer_business_profile: realm,
      sub_catalog_id: contractData.sub_catalog_id,
      catalogName: contractData.catalogName,
    });
  };

  const handleMinimumOrderValueChange = (minimumOrderValue: number): void => {
    setContractData({
      ...contractData,
      minimum_order_value: minimumOrderValue,
    });
  };

  const handleDiscountChange = (discount: number): void => {
    setContractData({
      ...contractData,
      discount: round(Math.min(discount, 100) / 100, 4),
      custom_price_list_id: null,
      customPriceListName: undefined,
    });
  };

  const handleCustomPriceListChange = (customPriceListId: string, customPriceListName: string): void => {
    setContractData({
      ...contractData,
      discount: 0,
      custom_price_list_id: customPriceListId,
      customPriceListName,
    });
  };

  const handleCatalogChange = (catalogId: string | null, catalogName?: string): void => {
    setContractData({
      ...contractData,
      sub_catalog_id: catalogId,
      catalogName,
    });
  };

  useEffect((): void => {
    if (conditions?.discount) {
      setPriceMode(PriceModeEnum.CONTRACT_DISCOUNT);
    } else if (conditions?.custom_price_list_id || newCustomPriceListId) {
      setPriceMode(PriceModeEnum.CUSTOM_PRICE_LIST);
    }
  }, [conditions, newCustomPriceListId]);

  useEffect(() => {
    navigate('.', { replace: true });
  }, []);

  useEffect(() => {
    handleCatalogChange(newSubCatalogId, newSubCatalogName);
  }, [newSubCatalogId, newSubCatalogName]);

  useEffect((): void => {
    // Buyers have only access to discount if it's set and do not need to trigger any changes on the price mode
    if (realm === Business_Profile_Enum.Buyer) {
      return;
    }

    // eslint-disable-next-line default-case
    switch (priceMode) {
      case PriceModeEnum.DEFAULT:
        handleDiscountChange(0);
        break;
      case PriceModeEnum.CONTRACT_DISCOUNT:
        handleDiscountChange((conditions?.discount || 0) * 100);
        break;
      case PriceModeEnum.CUSTOM_PRICE_LIST:
        handleCustomPriceListChange(newCustomPriceListId || conditions?.custom_price_list_id || '', newCustomPriceListName || conditions?.customPriceListName || '');
        break;
    }
  }, [priceMode]);

  const discountInput = useMemo((): React.JSX.Element => (
    <OutlinedInput
      type="number"
      size="small"
      sx={{
        backgroundColor: 'white',
        width: 280,
        alignSelf: 'center',
      }}
      inputProps={{
        min: 0,
        max: 100,
        sx: {
          '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
            display: 'none',
          },
        },
      }}
      placeholder={t('contracts:enter_your_discount')}
      value={!Number.isNaN(contractData.discount) ? round(contractData.discount! * 100) : ''}
      endAdornment="%"
      onChange={(event) => handleDiscountChange(parseFloat(event.target.value))}
    />
  ), [contractData]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: {
            xs: 'calc(100vw - 64px)',
            sm: 640,
          },
        },
      }}
    >
      <DialogTitle variant="displayMedium" sx={{ mt: 0, p: 3, pb: 2 }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3, pb: 3 }}>
        <Typography
          variant="bodyMedium"
          sx={{
            borderTop: '1px solid',
            borderColor: 'divider',
            textAlign: 'center',
            pt: 2,
          }}
        >
          {description}
        </Typography>
        {realm === Business_Profile_Enum.Supplier && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="titleMedium" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CatalogIcon /> {t('catalogs:catalog')}
            </Typography>
            <CatalogsSelect value={contractData.sub_catalog_id || undefined} onChange={handleCatalogChange} contractId={contractId} />
          </Box>
        )}
        {(realm === Business_Profile_Enum.Supplier || isBuyerEditingDiscount) && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="titleMedium" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
              <DiscountIcon /> {t('price:prices')}
            </Typography>
            {realm === Business_Profile_Enum.Supplier && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                  }}
                >
                  {Object.values(PriceModeEnum).map((value) => {
                    const selected = value === priceMode;

                    return (
                      <Button
                        key={value}
                        variant={selected ? 'contained' : 'outlined'}
                        sx={{ flex: 1 }}
                        onClick={() => setPriceMode(value)}
                      >
                        {t(PriceModeWordings[value].button)}
                      </Button>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignItems: 'center',
                    backgroundColor: 'layout.main',
                    borderRadius: 3,
                    p: 2,
                  }}
                >
                  <Typography variant="bodySmall" sx={{ textAlign: 'center' }}>
                    {t(PriceModeWordings[priceMode].description)}
                  </Typography>
                  {priceMode === PriceModeEnum.CONTRACT_DISCOUNT && discountInput}
                  {priceMode === PriceModeEnum.CUSTOM_PRICE_LIST && (
                    <CustomPriceListsSelect value={contractData.custom_price_list_id || newCustomPriceListId || ''} onChange={handleCustomPriceListChange} contractId={contractId} />
                  )}
                </Box>
              </>
            )}
            {isBuyerEditingDiscount && discountInput}
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Typography variant="titleMedium" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
            <MinimumOrderValueIcon fill={theme.palette.marketing3.main} /> {t('common:minimum_order_value')}
          </Typography>
          <OutlinedInput
            type="number"
            size="small"
            sx={{ width: 280 }}
            inputProps={{
              min: 0,
              sx: {
                '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                  display: 'none',
                },
              },
            }}
            placeholder={t('contracts:enter_your_minimum_order_value')}
            value={contractData.minimum_order_value ?? ''}
            endAdornment="€"
            onChange={(event) => handleMinimumOrderValueChange(parseFloat(event.target.value))}
          />
        </Box>
        {realm === Business_Profile_Enum.Supplier && (
          <Box
            sx={{
              mt: 1,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <CommissionInfo commission={commissionRate as number} />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2 }}>
        <Button onClick={() => onClose()} variant="text">
          {t('common:cancel')}
        </Button>
        <Button
          onClick={handleContractConditionsUpdateConfirm}
          variant="contained"
          disabled={isSubmitDisabled}
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
