import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';
import { Delivery_Zone_Insert_Input } from 'kheops-graphql';
import { SettingsDialogViewProps } from './SettingsDialog';
import { DeliveryZoneSetting } from './DeliveryZoneSetting';
import { useInsertDeliveryZonesMutation } from '../../mutations/__generated__/insertDeliveryZones.generated';
import { UserRoleDocument } from '../../queries/__generated__/userRole.generated';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { useUpdateDeliveryZoneMutation } from '../../mutations/__generated__/updateDeliveryZone.generated';
import { currentContextAtom } from '../../state';

export interface DeliveryZoneSettingsProps extends SettingsDialogViewProps {
  indexZoneToModify?: number;
  addNewZone?: boolean;
}

export interface DeliveryZoneFormValue {
  mov: string;
  areas: string[];
}

export interface DeliveryZonesForm {
  deliveryZones: DeliveryZoneFormValue[];
}

export default function DeliveryZoneSettings({ next, standAlone, indexZoneToModify, addNewZone }: DeliveryZoneSettingsProps): React.JSX.Element {
  const { t } = useTranslation(['settings', 'common']);
  const context = useAtomValue(currentContextAtom);
  const [insertDeliveryZones, { data, loading: loadingInsert }] = useInsertDeliveryZonesMutation({
    refetchQueries: [UserRoleDocument],
    awaitRefetchQueries: true,
  });
  const [updateDeliveryZone, { data: dataUpdate, loading: loadingUpdate }] = useUpdateDeliveryZoneMutation({
    refetchQueries: [UserRoleDocument],
  });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  let deliveryZonesDefault = context.deliveryZones?.map((zone) => ({
    mov: `${zone.minimum_order_value}`,
    areas: zone.administrative_areas.map(({ area }) => area),
  })) || [{ mov: '', areas: [] }];

  if (addNewZone) {
    deliveryZonesDefault.push({ mov: '', areas: [] });
  }

  if (!deliveryZonesDefault.length) {
    deliveryZonesDefault = [{ mov: '', areas: [] }];
  }

  const form = useForm<DeliveryZonesForm>({
    defaultValues: {
      deliveryZones: deliveryZonesDefault,
    },
    mode: 'onChange',
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'deliveryZones',
  });

  const isValidButtonDisabled = useMemo((): boolean => {
    return !form.formState.isDirty || !form.formState.isValid;
  }, [form.formState.isValid, form.formState.isDirty]);

  const handleSubmit = useCallback(() => {
    const { deliveryZones } = form.getValues();

    if (indexZoneToModify !== undefined) {
      const zoneToSave = deliveryZones[indexZoneToModify];
      const previousZoneValue = context.deliveryZones![indexZoneToModify];
      const idZone = previousZoneValue.id;

      updateDeliveryZone({
        variables: {
          id: idZone,
          deliveryZone: {
            minimum_order_value: Number(zoneToSave.mov),
          },
          newAreas: zoneToSave.areas.map((area) => ({ area, delivery_zone_id: idZone, country_code: 'FR' })),
          previousAreas: context.deliveryZones![indexZoneToModify].administrative_areas.map(({ area }) => area),
        },
      });

      return;
    }

    let zoneToSave = deliveryZones;

    if (addNewZone) {
      zoneToSave = [deliveryZones[deliveryZones.length - 1]];
    }

    const formattedZoneToSave = zoneToSave.map<Delivery_Zone_Insert_Input>((zone) => ({
      minimum_order_value: Number(zone.mov),
      company_id: context.companyId,
      administrative_areas: {
        data: zone.areas.map((area) => ({ area, country_code: 'FR' })),
      },
    }));

    insertDeliveryZones({ variables: { delivery_zones: formattedZoneToSave } });
  }, [context, form]);

  useEffect((): void => {
    if (data?.insert_delivery_zone?.affected_rows || dataUpdate?.update_delivery_zone_by_pk) {
      setCommonSnackbarProps({
        label: t('settings:your_profile_has_been_updated'),
        snackbarProps: {
          open: true,
        },
      });

      next();
    }
  }, [next, data, dataUpdate]);

  return (
    <FormProvider {...form}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DialogTitle sx={{ pt: 0 }} variant="displaySmall">
          {t('settings:your_delivery_zones')}
          <Divider sx={{ pt: 2 }} />
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Typography variant="bodyMedium" color="secondary" sx={{ textAlign: 'center', mb: 1 }}>
            {t('settings:your_delivery_zones_description')}
          </Typography>
          <Box sx={{ overflow: 'auto' }}>
            {fields.map((field, index) => {
              const canEditField = (!addNewZone && (indexZoneToModify === undefined || indexZoneToModify === index))
                || (addNewZone && index === fields.length - 1);

              if (canEditField) {
                return (
                  <DeliveryZoneSetting
                    key={field.id}
                    index={index}
                    deletable={fields.length > 1}
                    deleteZone={() => remove(index)}
                    standAlone={standAlone}
                  />
                );
              }

              return undefined;
            })}
          </Box>
          {!standAlone && (
            <Button
              onClick={() => append({ areas: [], mov: '' })}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                mx: {
                  xs: 'unset',
                  sm: 'auto',
                },
              }}
            >
              {t('settings:add_new_delivery_zone')}
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          {
            standAlone
              ? (
                <Button onClick={() => next()} variant="text">
                  {t('common:cancel')}
                </Button>
              )
              : (
                <Button onClick={() => next(true)} variant="outlined">
                  {t('settings:complete_later')}
                </Button>
              )
          }
          <LoadingButton
            onClick={handleSubmit}
            loading={loadingInsert || loadingUpdate}
            variant="contained"
            disabled={isValidButtonDisabled}
          >
            {t('common:validate')}
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
