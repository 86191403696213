import { Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SettingsDialogViewProps } from './SettingsDialog';

export default function SettingsDialogWelcome({ next }: SettingsDialogViewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'settings']);

  return (
    <>
      <DialogTitle sx={{ pt: 0 }}>
        <Typography variant="displaySmall" sx={{ mb: 2 }}>
          {t('settings:complete_your_profile')}
        </Typography>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="bodyMedium" color="secondary" sx={{ mb: 1, textAlign: 'center' }}>
          {t('settings:complete_your_profile_description')}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Button
          onClick={() => next()}
          variant="contained"
          sx={{
            flex: 1,
            mb: 2,
          }}
        >
          {t('common:start')}
        </Button>
      </DialogActions>
    </>

  );
}
