import { useAtom, useAtomValue } from 'jotai';
import { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Business_Profile_Enum, Order_Status_Enum, Payment_Method_Enum, TaskAction } from 'kheops-graphql';
import { OrderButtonType } from '../../../common/models/order.models';
import CancelOrderButton from '../CancelOrderButton';
import OrderActionButtonCancel from '../OrderActionButtonCancel';
import { DeliveryDateOrderStatus } from '../OrderDeliveryDateDialog';
import OrderInvoiceButton from '../OrderInvoiceButton';
import OrderUpdateDeliveryDateButton from '../OrderUpdateDeliveryDateButton';
import { areOrderItemsEditedAtom, isPerformingActionAtom, orderAtom, orderTerminationStatuses, thereAreOrderItemsWithUpdatedQuantitiesAtom } from '../../state/state';
import OrderActionButton from '../OrderActionButton';
import { currentContextAtom } from '../../../state';
import ReorderButton from '../ReorderButton';
import UpdatePaymentDateButton from '../UpdatePaymentDateButton';
import OrderAdditionalInfoButton from '../actions/OrderAdditionalInfoButton';
import UpdateInvoiceButton from '../UpdateInvoiceButton';

export interface UseOrderFormButtonsProps {
  availableButtons: OrderButtonType[];
  userId: string;
}

export default function useOrderFormButtons({ availableButtons, userId }: UseOrderFormButtonsProps): React.JSX.Element[] {
  const { t } = useTranslation(['order']);
  const location = useLocation();
  const { realm } = useAtomValue(currentContextAtom);
  const areItemsEdited = useAtomValue(areOrderItemsEditedAtom);
  const thereAreSomeItemsWithUpdatedQuantities = useAtomValue(thereAreOrderItemsWithUpdatedQuantitiesAtom);
  const [isPerformingAction, setPerformingAction] = useAtom(isPerformingActionAtom);
  const order = useAtomValue(orderAtom);
  const contractHasCommission = !!order.contract.supplier_commission_rate;

  useEffect(() => {
    setPerformingAction(false);
  }, [location]);

  const orderActionButton = useMemo(() => {
    if (availableButtons.length || areItemsEdited) {
      let action: TaskAction | undefined;

      if (availableButtons.includes(OrderButtonType.MODIFY) && areItemsEdited) {
        action = TaskAction.Modify;
      } else if (availableButtons.includes(OrderButtonType.APPROVE)) {
        action = TaskAction.Approve;
      }

      return (
        <OrderActionButton action={action} />
      );
    }

    if (orderTerminationStatuses.includes(order.status)) {
      return (
        <ReorderButton
          variant="contained"
          orderReferenceId={order.reference_id}
          sx={{ flex: 'auto' }}
        />
      );
    }

    if (realm === Business_Profile_Enum.Buyer && order.status === Order_Status_Enum.OrderBeingPaidByBuyer && order.payment_method === Payment_Method_Enum.SddTriggered) {
      return (
        <UpdatePaymentDateButton />
      );
    }
  }, [availableButtons, areItemsEdited, order]);

  const orderAlternativeButton = useMemo(() => {
    const orderInPreparationAndOnlyWeightsEdited = order.status === Order_Status_Enum.OrderToBePreparedBySupplier && !thereAreSomeItemsWithUpdatedQuantities && areItemsEdited;

    /*
      When in to_be_prepared status, if as a supplier update at least a packaging's quantity (not weight) we display cancel modification.
      If however they only edit weights, we display the deliverydatebutton.
    */

    if (areItemsEdited && !orderInPreparationAndOnlyWeightsEdited) {
      return (
        <OrderActionButtonCancel disabled={isPerformingAction} />
      );
    }

    switch (order.status) {
      case Order_Status_Enum.OrderToBeValidatedByBuyer:
      case Order_Status_Enum.OrderToBeValidatedBySupplier:

        return (
          <CancelOrderButton
            orderId={order.id}
            referenceId={order.reference_id}
            userId={userId}
          />
        );
      case Order_Status_Enum.OrderToBeDeliveredBySupplier:
      case Order_Status_Enum.OrderToBePreparedBySupplier:

        return realm === Business_Profile_Enum.Supplier
          ? [
            <OrderAdditionalInfoButton label={t('order:delivery_information')} view="DELIVERY" />,
            <OrderUpdateDeliveryDateButton
              orderId={order.id}
              orderReferenceId={order.reference_id}
              orderStatus={order.status as DeliveryDateOrderStatus}
            />,
          ]
          : undefined;

      case Order_Status_Enum.OrderToBeBilledBySupplier:
        if (realm === Business_Profile_Enum.Supplier) {
          return <OrderAdditionalInfoButton label={t('order:invoicing_information')} view="INVOICING" />;
        }

        break;
      case Order_Status_Enum.OrderBeingPaidByBuyer:
      case Order_Status_Enum.OrderToBePaidByBuyer:
        // eslint-disable-next-line no-case-declarations
        const buttons = [
          <OrderInvoiceButton
            orderFriendlyId={order.friendly_id!}
            hasCommission={contractHasCommission}
            hasInvoiceGeneration={order.has_invoice_generation}
            paymentMethod={order.payment_method!}
          />,
        ];

        if (realm === Business_Profile_Enum.Supplier) {
          if (!order.has_invoice_generation && order.status === Order_Status_Enum.OrderToBePaidByBuyer) {
            buttons.unshift(<UpdateInvoiceButton />);
          }
          buttons.unshift(<OrderAdditionalInfoButton label={t('order:invoicing_information')} view="INVOICING" />);
        }

        return buttons;
      case Order_Status_Enum.OrderClosed:

        return (
          <OrderInvoiceButton
            orderFriendlyId={order.friendly_id!}
            hasCommission={contractHasCommission}
            hasInvoiceGeneration={order.has_invoice_generation}
            paymentMethod={order.payment_method!}
          />
        );
      default:
    }
  }, [areItemsEdited, order, thereAreSomeItemsWithUpdatedQuantities]);

  return [orderAlternativeButton, orderActionButton].flat().filter((element) => !!element) as ReactElement[];
}
