import { Box, Button, ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BlackBackgroundTooltip from '../../common/components/BlackBackgroundTooltip';
import { LocalStorageKeys } from '../../state';

export interface CustomPriceListDiscountTooltipProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
}

export default function CustomPriceListDiscountTooltip({ children, open, onClose }: CustomPriceListDiscountTooltipProps): React.JSX.Element {
  const { t } = useTranslation(['price']);

  const handleUnderstoodClick = (): void => {
    localStorage.setItem(LocalStorageKeys.DISABLE_CATALOG_PRICE_FIELD_TOOLTIP, 'true');
    onClose();
  };

  return (
    <BlackBackgroundTooltip
      open={open}
      disableHoverListener
      disableFocusListener
      placement="top-start"
      title={(
        <ClickAwayListener onClickAway={() => onClose()} mouseEvent="onMouseDown">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 1, textAlign: 'left' }}>
            {t('price:price_field_tooltip')}
            <Button onClick={handleUnderstoodClick} variant="tonal">
              {t('price:i_understood')}
            </Button>
          </Box>
        </ClickAwayListener>
      )}
    >
      {children}
    </BlackBackgroundTooltip>
  );
}
