import { Grid2, Skeleton } from '@mui/material';
import React from 'react';
import { DISCOVERY_AS_BUYER_HITS_PER_PAGE } from './as-buyer/DiscoveryAsBuyerPage';

export default function DiscoverySkeleton(): React.JSX.Element {
  return (
    <Grid2 container rowSpacing={2} columnSpacing={2} sx={{ pt: 1.5 }}>
      {[...Array(DISCOVERY_AS_BUYER_HITS_PER_PAGE)].map(() => (
        <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2.4 }}>
          <Skeleton
            variant="rectangular"
            sx={{
              borderRadius: 6,
              height: {
                xs: 232,
                sm: 484,
              },
              width: {
                xs: '100%',
                sm: 268,
              },
            }}
          />
        </Grid2>
      ))}
    </Grid2>
  );
}
