import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { LoadingButton } from '@mui/lab';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { BusinessProfile, Business_Profile_Enum, Company } from 'kheops-graphql';
import { useNavigate } from 'react-router';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import { currentContextAtom, userAtom } from '../../state';
import { DisplayPhoto } from '../../common/common';
import { useCreateContractRequestMutation } from '../../mutations/__generated__/createContractRequest.generated';
import { CompanyByIdDocument } from '../../queries/__generated__/companyById.generated';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import { ContractsAsBuyerDocument } from '../../queries/__generated__/contractsAsBuyer.generated';
import { RoutePaths } from '../../routes/AppRoutes';

export interface ContractRequestDialogProps {
  companyInfo: Pick<Company, 'id' | 'tradeName' | 'business_profile'> & { photos: DisplayPhoto[] };
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export default function ContractRequestDialog({ companyInfo, isOpen, onClose, onCancel }: ContractRequestDialogProps): React.JSX.Element {
  const navigate = useNavigate();
  const { id: userId } = useAtomValue(userAtom);
  const { realm, companyId: contextCompanyId } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['common', 'contracts']);
  const [message, setMessage] = useState('');
  const [createContractRequest, { loading }] = useCreateContractRequestMutation({
    refetchQueries: [CompanyByIdDocument, ContractsAsSupplierDocument, ContractsAsBuyerDocument],
    awaitRefetchQueries: true,
  });

  const [buyingCompanyId, supplyingCompanyId] = realm === Business_Profile_Enum.Buyer
    ? [contextCompanyId, companyInfo.id]
    : [companyInfo.id, contextCompanyId];

  const handleSubmit = async (): Promise<void> => {
    if (message) {
      const res = await createContractRequest({
        variables: {
          userId,
          buyingCompanyId,
          supplyingCompanyId,
          fromBusinessProfile: realm as unknown as BusinessProfile,
          message,
        },
      });

      if (res.data?.createContractRequest.channelUrl) {
        navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${res.data.createContractRequest.channelUrl}`);
      }
    }

    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={() => onClose()}>
      <DialogTitle sx={{ textAlign: 'center', mt: 0, p: 3, pb: 2 }} variant="displaySmall">
        {t('contracts:contact')}
      </DialogTitle>
      <DialogContent sx={{ px: 3 }}>
        <Typography
          variant="bodyMedium"
          color="secondary"
          sx={{
            textAlign: 'center',
            borderTop: '1px solid',
            borderColor: 'divider',
            py: 2,
          }}
        >
          {realm === Business_Profile_Enum.Buyer ? t('contracts:contact_request_description_from_buyer') : t('contracts:contact_request_description_from_supplier')}
        </Typography>
        <Typography variant="titleMedium" sx={{ pt: 2 }}>
          {t('contracts:your_message')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: 2 }}>
          <SizedImage
            src={
              FileUtils.BuildCompanyImageUrl({
                photo: companyInfo.photos[0]?.photo,
                type: companyInfo.business_profile,
                size: { height: 48, width: 48 },
              })
            }
            alt={t('common:company')}
            height={48}
            width={48}
            sx={{ borderRadius: 4 }}
          />
          <Typography variant="bodyLarge">
            {companyInfo.tradeName}
          </Typography>
        </Box>
        <TextField
          placeholder={t('contracts:ask_questions_to_supplier')}
          onChange={(event) => setMessage(event.target.value)}
          sx={{ width: '100%' }}
          multiline
          minRows={3}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Button onClick={onCancel} variant="text">
          {t('common:cancel')}
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          variant="contained"
          startIcon={<AddBusinessOutlinedIcon />}
          disabled={!message}
        >
          {t('contracts:contact')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
