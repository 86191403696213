import { Box, Grid2 } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useEffect, useState } from 'react';
import { productFamilyMap } from 'kheops-utils';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PageWrapper } from '../../common/components/PageWrapper';
import DiscoveryCarrouselNavigation from '../navigation/DiscoveryCarrouselNavigation';
import { configureRefineAtom, uiStateAtom } from '../../search/state/state';
import { DiscoveryView, currentFamilyAtom, currentSubFamilyAtom, discoveryViewAtom } from '../state';
import ProductsDiscovery from './ProductsDiscovery';
import SupplierDiscovery from './SupplierDiscovery';

export const DISCOVERY_AS_BUYER_HITS_PER_PAGE = 60;

export default function DiscoveryAsBuyerPage(): React.JSX.Element {
  const [discoveryView, setDiscoveryView] = useAtom(discoveryViewAtom);
  const setCurrentFamily = useSetAtom(currentFamilyAtom);
  const setCurrentSubFamily = useSetAtom(currentSubFamilyAtom);
  const [isInit, setIsInit] = useState(false);

  const getViewDisplay = (view: DiscoveryView): string => {
    return discoveryView === view ? 'flex' : 'none';
  };

  useEffect((): void => {
    const familyRefinementList = window.history.state.uiState?.[import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX]?.refinementList?.family as string[] | undefined;

    if (familyRefinementList) {
      Object.entries(productFamilyMap).forEach(([family, subFamilies]) => {
        const matchedSubFamilies = subFamilies.reduce((acc, subFamily) => {
          if (familyRefinementList.includes(subFamily)) {
            acc.push(subFamily);
          }

          return acc;
        }, [] as Product_Sub_Family_Name_Enum[]);

        if (matchedSubFamilies.length) {
          setCurrentFamily(family as Product_Family_Name_Enum);

          if (matchedSubFamilies.length === 1) {
            setCurrentSubFamily(matchedSubFamilies[0]);
          }
        }
      });
    }

    const previousDiscoveryView = window.history.state.discoveryView;

    if (previousDiscoveryView) {
      setDiscoveryView(previousDiscoveryView);
    }

    setIsInit(true);
  }, []);

  useEffect((): void => {
    if (isInit) {
      const historyUiState = {
        ...window.history.state,
        discoveryView,
      };

      window.history.replaceState(historyUiState, '');
    }
  }, [discoveryView, isInit]);

  return (
    <PageWrapper sx={{ pb: 2, mb: 0 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        {discoveryView === 'PRODUCTS'
          ? (
            <ScopeProvider atoms={[uiStateAtom, configureRefineAtom]}>
              <Grid2 container size={{ xs: 12 }} sx={{ display: getViewDisplay('PRODUCTS') }}>
                <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DiscoveryCarrouselNavigation />
                  <ProductsDiscovery />
                </Grid2>
              </Grid2>
            </ScopeProvider>
          ) : (
            <ScopeProvider atoms={[uiStateAtom, configureRefineAtom]}>
              <Grid2 size={{ xs: 12 }} container sx={{ display: getViewDisplay('SUPPLIERS') }}>
                <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DiscoveryCarrouselNavigation />
                  <SupplierDiscovery />
                </Grid2>
              </Grid2>
            </ScopeProvider>
          )}
      </Box>
    </PageWrapper>
  );
}
