import { Box, Chip, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

interface ProductVatRateProps {
  vatRate: number;
  sx?: SxProps;
}

export default function ProductVatRate({ vatRate, sx }: ProductVatRateProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'percent',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        ...sx,
      }}
    >
      <Typography variant="titleSmall">
        {t('products:vat_rate')}
      </Typography>
      <Chip
        size="small"
        sx={{
          fontSize: '1.125rem',
          fontWeight: 400,
          width: 'fit-content',
          height: 28,
          backgroundColor: 'secondary.light',
        }}
        label={numberFormat.format(vatRate!)}
      />
    </Box>
  );
}
