import { Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface InvalidFormBannerProps {
  close: () => void;
  message: string;
}

export default function InvalidFormBanner({ close, message }: InvalidFormBannerProps): React.JSX.Element {
  return (
    <Box
      sx={{
        borderRadius: 6,
        backgroundColor: 'error.main',
        color: 'common.white',
        textAlign: 'center',
        p: 2,
      }}
    >
      {message}
      <Button variant="text" onClick={close}>
        <CloseIcon />
      </Button>
    </Box>
  );
}
