import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, FormHelperText, InputAdornment, OutlinedInput, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { MenuButtonBold, MenuButtonBulletedList, MenuButtonItalic, MenuButtonOrderedList, MenuButtonUnderline } from 'mui-tiptap';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect } from 'react';
import { currentContextAtom } from '../../state';
import CompanyPhotoForm from './CompanyPhotoForm';
import { CompanyProfileForm } from './form.type';
import CustomPhoneNumberInput from '../../common/components/CustomPhoneNumberInput';
import CompanyAddressForm from './CompanyAddressForm';
import { RoutePaths } from '../../routes/AppRoutes';
import { useUpdateCompanyMutation } from '../../mutations/__generated__/updateCompany.generated';
import { UserRoleDocument } from '../../queries/__generated__/userRole.generated';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';
import { useUpsertCompanyAddressMutation } from '../../mutations/__generated__/upsertCompanyAddress.generated';
import { useUpsertCompanyPhotoMutation } from '../../mutations/__generated__/upsertCompanyPhoto.generated';
import { useNavBlocker } from '../../hooks/useNavBlocker';
import CustomRichTextEditor from './CustomRichTextEditor';

export default function CompanyProfile(): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts', 'error', 'validation']);
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { companyName, companyId, companyPhotos, companyAddress, contactEmail, contactPhoneNumber, description } = useAtomValue(currentContextAtom);
  const [updateCompany, { loading: updateCompanyLoading, called: companyUpdateCalled, error: updateCompanyError }] = useUpdateCompanyMutation({ refetchQueries: [UserRoleDocument] });
  const [upsertCompanyAddress, { loading: upsertCompanyAddressLoading, called: upsertAddressCalled, error: upsertCompanyAddressError }] = useUpsertCompanyAddressMutation();
  const [upsertCompanyPhoto, { loading: upsertCompanyPhotoLoading, called: upsertCompanyPhotoCalled, error: upsertCompanyPhotoError }] = useUpsertCompanyPhotoMutation();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const error = updateCompanyError || upsertCompanyAddressError || upsertCompanyPhotoError;
  const loading = updateCompanyLoading || upsertCompanyAddressLoading || upsertCompanyPhotoLoading;
  const calledAMutation = companyUpdateCalled || upsertAddressCalled || upsertCompanyPhotoCalled;
  const form = useForm<CompanyProfileForm>({
    defaultValues: {
      description,
      photo: companyPhotos ? companyPhotos[0]?.photo : undefined,
      contactPhoneNumber,
      address: {
        companyId,
        ...companyAddress,
      },
    },
  });
  const blockerMessage = {
    title: t('settings:abort_profile_update_title'),
    message: <Trans i18nKey="settings:abort_profile_update_message" />,
    confirmLabel: t('common:confirm'),
    cancelLabel: t('common:cancel'),
  };
  const blockerModal = useNavBlocker(form.formState.isDirty, blockerMessage);

  const {
    control,
    handleSubmit,
    getFieldState,
    formState: { isDirty, isValid },
    reset,
  } = form;

  useEffect(() => {
    if (error && !loading) {
      setCommonSnackbarProps({
        label: t('error:an_error_occured'),
        snackbarProps: {
          open: true,
        },
        alertProps: {
          severity: 'error',
        },
      });

      return;
    }

    if (calledAMutation && !loading) {
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [calledAMutation, loading, error]);

  useEffect(() => {
    reset({
      description,
      photo: companyPhotos ? companyPhotos[0]?.photo : undefined,
      contactPhoneNumber,
      address: {
        companyId,
        ...companyAddress,
      },
    });
  }, [description, companyAddress, companyPhotos]);

  const onSubmit = (values: CompanyProfileForm): void => {
    if (values.address && getFieldState('address').isDirty) {
      upsertCompanyAddress({
        variables: {
          companyId,
          addressData: values.address,
        },
      });
    }

    if (values.photo && getFieldState('photo').isDirty) {
      upsertCompanyPhoto({
        variables: {
          companyId,
          companyPhotoData: {
            company_id: companyId,
            photo: {
              data: {
                ...values.photo,
              },
            },
          },
        },
      });
    }

    updateCompany({
      variables: {
        companyId,
        companyUpdateData: {
          description: values.description,
          contactPhoneNumber: values.contactPhoneNumber,
          updated_at: new Date().toISOString(),
        },
      },
    });
  };

  return (
    <FormProvider {...form}>
      {blockerModal}
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.NAVIGATION_MAIN}
          shouldCheckHistory={false}
          compact
        />
        <Typography variant="displaySmall" sx={{ ml: 1 }}>
          {t('settings:update_my_profile')}
        </Typography>
      </TopBar>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mt: {
            xs: 8,
            lg: 0,
          },
          height: {
            lg: 'calc(100% - 48px)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            gap: 3,
          }}
        >
          <Typography variant="titleLarge">{companyName}</Typography>
          <CompanyPhotoForm companyId={companyId} />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="bodySmall" color="secondary">{t('contracts:description')}</Typography>
                <CustomRichTextEditor
                  extensions={[StarterKit, Underline]}
                  renderControls={() => (
                    <Box>
                      <MenuButtonBold />
                      <MenuButtonItalic />
                      <MenuButtonUnderline />
                      <MenuButtonBulletedList />
                      <MenuButtonOrderedList />
                    </Box>
                  )}
                  RichTextFieldProps={{
                    MenuBarProps: {
                      disableSticky: true,
                    },
                  }}
                  content={field.value}
                  onUpdate={({ editor }) => field.onChange(editor.getHTML())}
                  sx={{
                    '&.MuiTiptap-FieldContainer-focused .MuiTiptap-FieldContainer-notchedOutline': {
                      borderColor: 'black',
                    },
                  }}
                />
              </Box>
            )}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="titleMedium">{t('contracts:contact_coordinates')}</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
                <Typography variant="bodySmall" color="secondary">{t('common:email_address')}</Typography>
                <OutlinedInput
                  value={contactEmail}
                  disabled
                  sx={{
                    backgroundColor: 'secondary.light',
                  }}
                  startAdornment={(
                    <InputAdornment position="start" sx={{ mr: 0.5 }}>
                      <EmailOutlinedIcon />
                    </InputAdornment>
              )}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}>
                <Typography variant="bodySmall" color="secondary">{t('common:phone_number')} *</Typography>
                <Controller
                  name="contactPhoneNumber"
                  rules={{
                    validate: (phoneNumber) => (phoneNumber && isValidPhoneNumber(phoneNumber)),
                    required: t('validation:this_field_is_required'),
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <CustomPhoneNumberInput formProps={field} inputSx={{ width: '100%' }} />
                      {fieldState.error?.type === 'validate' && (
                        <FormHelperText error>{t('settings:invalid_number')}</FormHelperText>
                      )}
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="titleMedium">{t('common:address')}</Typography>
            <CompanyAddressForm companyId={companyId} companyTradeName={companyName} />
          </Box>

        </Box>

        {!isDownLg && (
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', backgroundColor: 'common.white', height: 48, gap: 1 }}>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!form.formState.isDirty || !form.formState.isValid}
              startIcon={<EditIcon />}
            >
              {t('common:update')}
            </LoadingButton>
            <Button variant="outlined" component={Link} to={RoutePaths.MY_COMPANY}>
              {t('settings:see_my_company')}
            </Button>
          </Box>
        )}
        {isDownLg && (
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid}
            startIcon={<EditIcon />}
            sx={{
              position: 'fixed',
              width: {
                xs: '100%',
                sm: 653,
              },
              mb: 3,
              right: 0,
              left: 0,
              mx: 'auto',
              bottom: 0,
            }}
          >
            {t('common:update')}
          </LoadingButton>
        )}
      </Card>
    </FormProvider>
  );
}
