import { Box, Card, Grid2, Typography } from '@mui/material';
import { Outlet } from 'react-router';
import { useAtomValue } from 'jotai';
import { PageWrapper } from '../common/components/PageWrapper';
import UserCompanyNavigation from './UserCompanyNavigation';
import ContextImage from '../topMenu/ContextImage';
import { getCompanyShortName } from '../common/utils/common.utils';
import { ellipsisStyle } from '../common/utils/style.utils';
import { currentContextAtom } from '../state';

export default function UserCompanyPage(): React.JSX.Element {
  const context = useAtomValue(currentContextAtom);

  return (
    <PageWrapper>
      <Grid2
        container
        columnSpacing={2}
        sx={{
          height: {
            lg: 'calc(100vh - 120px)',
          },
        }}
      >
        <Grid2
          size={{ xs: 3 }}
          sx={{
            height: '100%',
            display: {
              xs: 'none',
              lg: 'initial',
            },
          }}
        >
          <Card sx={{ height: 'calc(100% - 48px)', overflow: 'auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1.5 }}>
              <ContextImage context={context} size={40} borderRadius={3} />
              <Typography variant="bodyLarge" sx={{ ...ellipsisStyle }}>
                {getCompanyShortName(context.companyName, context.realm)}
              </Typography>
            </Box>
            <UserCompanyNavigation
              tabsProps={{
                orientation: 'vertical',
                sx: {
                  '& .MuiTabs-flexContainer': {
                    gap: 1,
                  },
                  '.MuiTabs-indicator': {
                    display: 'none',
                  },
                  '& .MuiTab-root': {
                    justifyContent: 'left',
                  },
                },
              }}
            />
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 9 }} sx={{ height: '100%' }}>
          <Outlet />
        </Grid2>
      </Grid2>
    </PageWrapper>
  );
}
