import { Box, Grid2, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHits, useInstantSearch } from 'react-instantsearch';
import { AlgoliaHit } from 'instantsearch.js';
import { useMemo } from 'react';
import { ProductHit } from '../../search/search';
import DiscoveryFilters from './DiscoveryFilters';
import DiscoveryProductHit from './DiscoveryProductHit';
import i18n from '../../i18n';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import TrumpeterButterflyImage from '../../assets/images/trumpeter_butterfly.png';
import DiscoveryViewSwitch from '../DiscoveryViewSwitch';
import QueryTag from '../../search/filters/QueryTag';
import GeoLocationTags from '../../search/filters/GeoLocationTags';
import ProductLabelsTags from '../filters/ProductLabelsTags';
import ContractStatusFilterTag from '../../search/filters/ContractStatusFilterTag';
import ClearFiltersButton from '../../search/filters/ClearFiltersButton';
import DiscoverySkeleton from '../DiscoverySkeleton';

export default function DiscoveryProductHits(): React.JSX.Element {
  const { t } = useTranslation(['discovery', 'products']);
  // eslint-disable-next-line deprecation/deprecation
  const { items, results } = useHits<AlgoliaHit<ProductHit>>();
  const { status } = useInstantSearch();
  const format = new Intl.NumberFormat(i18n.resolvedLanguage);

  const mainComponent = useMemo(() => {
    if (status === 'loading' || !results?.processingTimeMS) {
      return <DiscoverySkeleton />;
    }

    return items.length
      ? (
        <Grid2 container rowSpacing={2} columnSpacing={2} sx={{ pt: 1.5 }}>
          {items.map((hit) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2.4 }} key={hit.objectID}>
              <DiscoveryProductHit shouldDisplayCTAButon shouldDisplayCompany hit={hit} />
            </Grid2>
          ))}
        </Grid2>
      )
      : (
        <EmptyPagePlaceholder
          imageSrc={TrumpeterButterflyImage}
          title={t('products:no_product')}
        />
      );
  }, [items, status, results]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <>
        <Box
          sx={{
            position: 'sticky',
            top: 72,
            backgroundColor: 'white',
            zIndex: 7,
            pt: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column-reverse',
                lg: 'row',
              },
              alignItems: 'center',
              gap: {
                xs: 1.5,
                lg: 2,
              },
              mb: 1,
            }}
          >
            <DiscoveryFilters
              fullTextSearchPlaceholder={t('discovery:search_a_product')}
              contractFilterAttribute="buyers"
              searchMode="PRODUCT"
              index={import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <DiscoveryViewSwitch />
              <Typography variant="titleLarge">
                {t('discovery:N_product', { count: results!.nbHits, formattedCount: format.format(results!.nbHits) })}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
              '&:not(:empty)': {
                py: 1,
              },
            }}
          >
            <QueryTag variant="filled" size="small" />
            <GeoLocationTags variant="filled" size="small" />
            <ProductLabelsTags />
            <ContractStatusFilterTag attribute="buyers" variant="filled" size="small" searchMode="PRODUCT" />
            <ClearFiltersButton
              sx={{ height: 36 }}
              attributesToCheck={['administrative_areas', 'labels']}
              excludedAttributes={['family']}
              searchMode="PRODUCT"
            />
          </Box>
        </Box>
        {mainComponent}
      </>
    </Box>
  );
}
