import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import useOpenable from '../../hooks/useOpenable';
import DiscoverSupplierDialog from './DiscoverSupplierDialog';
import { useCompanyByIdLazyQuery } from '../../queries/__generated__/companyById.generated';
import ContractRequestDialog from '../../company/contractRequest/ContractRequestDialog';

interface DiscoverSupplierButtonProps {
  supplierCompanyId: string;
}

export default function DiscoverSupplierButton({ supplierCompanyId }: DiscoverSupplierButtonProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);
  const { open: openDiscoverSupplierDialog, close: closeDiscoverSupplierDialog, isOpen: isDiscoverSupplierDialogOpen } = useOpenable();
  const { open: openContractRequestDialog, close: closeContractRequestDialog, isOpen: isContractRequestDialogOpen } = useOpenable();
  const [getCompanyData, { data: companyData, loading: isCompanyDataLoading }] = useCompanyByIdLazyQuery({
    variables: {
      companyId: supplierCompanyId,
    },
  });

  const handleButtonClick = (): void => {
    getCompanyData();
    openDiscoverSupplierDialog();
  };
  const contactClick = (): void => {
    openContractRequestDialog();
    closeDiscoverSupplierDialog();
  };

  const contactCancelClick = (): void => {
    openDiscoverSupplierDialog();
    closeContractRequestDialog();
  };

  return (
    <>
      <LoadingButton
        loading={isCompanyDataLoading}
        variant="contained"
        fullWidth
        onClick={handleButtonClick}
      >
        {t('contracts:i_want_this_product')}
      </LoadingButton>
      {companyData && <DiscoverSupplierDialog companyData={companyData} open={isDiscoverSupplierDialogOpen} onClose={closeDiscoverSupplierDialog} onContactClick={contactClick} />}
      {companyData && <ContractRequestDialog companyInfo={companyData.company_by_pk!} isOpen={isContractRequestDialogOpen} onClose={closeContractRequestDialog} onCancel={contactCancelClick} />}
    </>
  );
}
