import AddIcon from '@mui/icons-material/Add';
import { Button, MenuItem, Select } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSubCatalogsByCompanyIdQuery } from '../../queries/__generated__/subCatalogsByCompanyId.generated';
import { RoutePaths } from '../../routes/AppRoutes';
import { currentContextAtom } from '../../state';

export interface CatalogsSelectProps {
  contractId: string;
  value?: string;
  onChange: (id: string | null, name?: string) => void;
}

const DEFAULT_CATALOG_ID = 'DEFAULT';

export default function CatalogsSelect({ value, contractId, onChange }: CatalogsSelectProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs']);
  const { companyId } = useAtomValue(currentContextAtom);
  const { data } = useSubCatalogsByCompanyIdQuery({ variables: { companyId } });
  const location = useLocation();

  const selectedCatalogId = useMemo(() => {
    return value ?? DEFAULT_CATALOG_ID;
  }, [value]);

  const catalogs = useMemo(() => {
    const defaultCatalog = {
      id: DEFAULT_CATALOG_ID,
      name: t('catalogs:default_catalog'),
    };

    return [defaultCatalog].concat(data?.sub_catalog || []);
  }, [data?.sub_catalog]);

  const options = useMemo((): React.JSX.Element[] => {
    return catalogs.map(({ id, name }) => {
      return (
        <MenuItem
          value={id}
          key={id}
          sx={{
            p: 2,
            mx: 2.5,
            borderRadius: 4,
          }}
        >
          {name}
        </MenuItem>
      );
    });
  }, [catalogs, value]);

  const getName = useCallback((subCatalogId: string): string => {
    return catalogs.find(({ id }) => id === subCatalogId)?.name || '';
  }, [catalogs]);

  const handleChange = (catalogId: string): void => {
    onChange(
      catalogId === DEFAULT_CATALOG_ID ? null : catalogId,
      getName(catalogId),
    );
  };

  return (
    <Select
      size="small"
      value={selectedCatalogId}
      onChange={(event) => handleChange(event.target.value)}
      sx={{
        width: 280,
        py: 0.5,
      }}
      renderValue={getName}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            mt: 1,
            py: 1,
            borderRadius: 4,
          },
        },
      }}
    >
      {options}
      <MenuItem
        sx={{
          display: 'flex',
          '&:hover': {
            backgroundColor: 'unset',
            cursor: 'default',
          },
        }}
        disableRipple
      >
        <Button
          component={Link}
          to={`${RoutePaths.SUB_CATALOG}?contractId=${contractId}`}
          state={{ origin: location.pathname }}
          variant="outlined"
          startIcon={<AddIcon />}
          fullWidth
        >
          {t('catalogs:create_a_new_catalog')}
        </Button>
      </MenuItem>
    </Select>
  );
}
