import { Box, Button, Card, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import CatalogIcon from '../../assets/icons/catalog.svg?react';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import GuitaristButterflyImage from '../../assets/images/guitarist_butterfly.png';
import EmptyPagePlaceholder from '../../common/components/EmptyPagePlaceholder';
import { RoutePaths } from '../../routes/AppRoutes';
import TopBar from '../../common/components/TopBar';
import BackButton from '../../common/components/BackButton';
import { useSubCatalogsByCompanyIdQuery } from '../../queries/__generated__/subCatalogsByCompanyId.generated';
import { currentContextAtom } from '../../state';
import SubCatalogCard from './subCatalogs/SubCatalogCard';

export default function CatalogsList(): React.JSX.Element {
  const { t } = useTranslation(['catalogs', 'discovery']);
  const navigate = useNavigate();
  const { companyId } = useAtomValue(currentContextAtom);
  const { data: subCatalogsData } = useSubCatalogsByCompanyIdQuery({ variables: { companyId } });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionsButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          flexDirection: 'column',
          alignItems: 'start',
          px: {
            xs: 2,
            sm: 4,
          },
          py: 1.5,
          gap: 2,
          height: 'fit-content',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <BackButton
            to={RoutePaths.NAVIGATION_MAIN}
            shouldCheckHistory={false}
            compact
            sx={{
              p: 1,
              width: 40,
              height: 40,
              '& .MuiSvgIcon-root': {
                width: 24,
                height: 24,
              },
            }}
          />
          <CatalogIcon height={32} width={32} />
          <Typography variant="titleLarge" sx={{ ml: 1 }}>
            {t('catalogs:my_catalogs')}
          </Typography>
        </Box>
      </TopBar>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'transparent',
          gap: 3,
          overflow: 'auto',
          height: {
            lg: 'calc(100% - 48px)',
          },
          mt: {
            xs: 8,
            lg: 0,
          },
          mb: {
            xs: 10,
            lg: 0,
          },
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
              <CatalogIcon height={28} width={28} />
              <Typography variant="titleLarge">
                {t('catalogs:my_catalogs')}
              </Typography>
            </Box>
            <Typography variant="bodyMedium" color="secondary">
              {t('catalogs:catalogs_description')}
            </Typography>
          </Box>
          {!!subCatalogsData?.sub_catalog.length && (
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => navigate(RoutePaths.SUB_CATALOG)}
            >
              {t('catalogs:create_a_catalog')}
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Box>
            <Typography variant="titleMedium">
              {t('catalogs:default_catalog')}
            </Typography>
            <Typography variant="bodyMedium" color="secondary">
              {t('catalogs:default_catalog_description')}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              backgroundColor: 'surfaceContainerLow.main',
              borderRadius: 4,
              display: 'flex',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="bodyLarge">
                {t('catalogs:default_catalog')}
              </Typography>
              <Typography variant="bodyMedium" sx={{ color: 'onSurfaceVariant.main' }}>
                {(t('discovery:N_product', { formattedCount: subCatalogsData?.packaging_aggregate.aggregate?.count, count: subCatalogsData?.packaging_aggregate.aggregate?.count || 0 }))}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="text"
                onClick={handleOptionsButtonClick}
              >
                <MoreVertIcon />
              </Button>
              <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClose={() => setAnchorEl(null)}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: 4,
                    gap: 2,
                  },
                  '& .MuiMenuItem-root': {
                    py: 1,
                  },
                }}
              >
                <MenuItem onClick={() => navigate(RoutePaths.COMPANY_PRODUCTS)}>
                  <ListItemIcon>
                    <CatalogIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {t('catalogs:edit_products')}
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => navigate(RoutePaths.COMPANY_PRODUCTS_PRICES)}>
                  <ListItemIcon>
                    <DiscountIcon height={24} width={24} />
                  </ListItemIcon>
                  <ListItemText>
                    {t('catalogs:edit_default_prices')}
                  </ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography variant="titleMedium">
            {t('catalogs:sub_catalogs')}
          </Typography>
          <Typography variant="bodyMedium" color="secondary">
            {t('catalogs:sub_catalogs_description')}
          </Typography>
        </Box>
        {subCatalogsData?.sub_catalog.length
          ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {subCatalogsData.sub_catalog.map((subCatalogData) => (
                <SubCatalogCard
                  subCatalog={subCatalogData}
                  supplierPackagingsCount={subCatalogsData.packaging_aggregate.aggregate?.count || 0}
                  shouldDisplayOptionsMenu
                  key={subCatalogData.id}
                />
              ))}
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                onClick={() => navigate(RoutePaths.SUB_CATALOG)}
                sx={{
                  height: 40,
                  position: 'fixed',
                  bottom: 0,
                  display: {
                    xs: 'flex',
                    lg: 'none',
                  },
                  right: 0,
                  left: 0,
                  mx: 'auto',
                  mb: 3,
                  maxWidth: 320,
                }}
              >
                {t('catalogs:create_a_catalog')}
              </Button>
            </Box>
          )
          : (
            <EmptyPagePlaceholder
              title={t('catalogs:no_custom_catalog')}
              imageSrc={GuitaristButterflyImage}
              sx={{ pt: 0 }}
              linkDestination={RoutePaths.SUB_CATALOG}
              buttonLabel={t('catalogs:create_a_catalog')}
              buttonProps={{
                startIcon: <AddIcon />,
              }}
            />
          )}
      </Card>
    </>

  );
}
