import { Divider, Grid2, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EuroIcon from '@mui/icons-material/Euro';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { FormatMeasurementUnit, FormatPackagingUnitPrice, FormatPackagingVolumePrice, GetConvertedUnit, round } from 'kheops-utils';
import { Base_Unit_Type_Enum, Billing_Type_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { DraftCustomPriceList } from './state';
import CustomPriceListDiscountTooltip from './CustomPriceListDiscountTooltip';
import { LocalStorageKeys } from '../../state';
import { CompanyPackaging } from '../state';
import CompanyPackagingCard from '../companyPackagingCard';
import BlackBackgroundTooltip from '../../common/components/BlackBackgroundTooltip';

export interface CustomPriceListProductsFormRowProps {
  packaging: CompanyPackaging;
}

export default function CustomPriceListProductsFormRow({ packaging }: CustomPriceListProductsFormRowProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const currencyFormat = useCurrencyFormat();
  const { control, setValue } = useFormContext<DraftCustomPriceList>();
  const baseUnitPrice = packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent
    ? FormatPackagingVolumePrice(packaging, packaging.base_unit).value
    : FormatPackagingUnitPrice(packaging);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const priceUnit = useMemo((): string | undefined => {
    const isBulk = packaging.base_unit.unit_type === Base_Unit_Type_Enum.Bulk;
    const isBilledByWeight = packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent;
    let result: string | undefined;

    if ((isBilledByWeight || isBulk)) {
      const convertedUnit = GetConvertedUnit((isBulk ? packaging.content_measurement_unit : packaging.base_unit.content_measurement_unit)!);

      result = convertedUnit.volumeUnit === Measurement_Unit_Enum.L ? Measurement_Unit_Enum.L : convertedUnit.volumeUnit.toLowerCase();
    }

    return result;
  }, [packaging]);

  const defaultPrice = useMemo((): string => {
    let result: string;

    if (packaging.base_unit.unit_type === Base_Unit_Type_Enum.Bulk || packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent) {
      const volumePrice = FormatPackagingVolumePrice(packaging, packaging.base_unit);

      result = `${currencyFormat.format(volumePrice.value)}/${FormatMeasurementUnit(volumePrice.unit, 'fr', 1)}`;
    } else {
      result = currencyFormat.format(FormatPackagingUnitPrice(packaging));
    }

    return result;
  }, [packaging, priceUnit]);

  const handleDiscountChange = (discount: number): void => {
    const newPrice = discount ? ((100 - discount) / 100) * baseUnitPrice : baseUnitPrice;

    setValue(`prices.${packaging.sku}.price`, round(newPrice, 2));
  };

  const handlePriceChange = (price: number): void => {
    const newDiscount = 100 - ((price / baseUnitPrice) * 100);

    setValue(`prices.${packaging.sku}.discount`, round(newDiscount, 2));
  };

  const handlePriceFieldFocus = (): void => {
    const disablePriceFieldTooltip = localStorage.getItem(LocalStorageKeys.DISABLE_CATALOG_PRICE_FIELD_TOOLTIP);

    if (!disablePriceFieldTooltip) {
      setTooltipOpen(true);
    }
  };

  return (
    <Grid2 container size={{ xs: 12 }} columnSpacing={1}>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        sx={{
          display: 'flex',
          gap: 1,
          py: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <CompanyPackagingCard packaging={packaging} />
      </Grid2>
      <Grid2
        container
        size={{ xs: 12 }}
        columnSpacing={1}
        sx={{
          py: 1,
          display: {
            xs: 'flex',
            lg: 'none',
          },
          '& .MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Grid2 size={{ xs: 4 }}>
          <Typography variant="bodySmall" color="secondary">
            {t('price:catalog_price_without_tax')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <Typography variant="bodySmall" color="secondary">
            {t('price:discount_in_percent')}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 4 }} sx={{ display: 'flex', gap: 0.5 }}>
          <Typography variant="bodySmall" color="secondary">
            {t('price:final_price_without_tax')}
          </Typography>
          <BlackBackgroundTooltip title={t('price:price_field_tooltip')} placement="top-start">
            <HelpOutlineIcon fontSize="inherit" />
          </BlackBackgroundTooltip>
        </Grid2>
      </Grid2>
      <Grid2
        container
        size={{ xs: 12, lg: 6 }}
        columnSpacing={1}
        sx={{
          py: {
            xs: 1,
            lg: 0,
          },
        }}
      >
        <Grid2 size={{ xs: 4 }} sx={{ margin: { xs: 'auto', lg: 'unset' } }}>
          {defaultPrice}
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <Controller
            name={`prices.${packaging.sku}.discount`}
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field }) => (
              <OutlinedInput
                size="small"
                name={field.name}
                value={field.value}
                fullWidth
                onChange={(event) => {
                  handleDiscountChange(parseFloat(event.currentTarget.value));
                  field.onChange(event);
                }}
                inputProps={{
                  step: '.01',
                  min: '0',
                  max: '100',
                }}
                type="number"
                endAdornment="%"
                sx={{
                  '& input[type=number]::-webkit-outer-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                }}
              />
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <Controller
            name={`prices.${packaging.sku}.price`}
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field }) => (
              <CustomPriceListDiscountTooltip open={tooltipOpen} onClose={() => setTooltipOpen(false)}>
                <OutlinedInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  fullWidth
                  onFocus={handlePriceFieldFocus}
                  onChange={(event) => {
                    handlePriceChange(parseFloat(event.currentTarget.value));
                    field.onChange(event);
                  }}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  endAdornment={(
                    <InputAdornment position="start">
                      {priceUnit
                        ? `€/${priceUnit}`
                        : <EuroIcon sx={{ fontSize: '1rem' }} />}
                    </InputAdornment>
                  )}
                  sx={{
                    '& input[type=number]::-webkit-outer-spin-button': {
                      display: 'none',
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                  }}
                />
              </CustomPriceListDiscountTooltip>
            )}
          />
        </Grid2>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Divider sx={{ my: 2 }} />
      </Grid2>
    </Grid2>
  );
}
