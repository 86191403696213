import { Button } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAtomValue } from 'jotai';
import React from 'react';
import BuyerInfoDialog, { BuyerView } from '../../../company/buyer/dialog/BuyerInfoDialog';
import useOpenable from '../../../hooks/useOpenable';
import { orderAtom } from '../../state/state';

export interface OrderAdditionalInfoButtonProps {
  label: string;
  view: BuyerView;
}

export default function OrderAdditionalInfoButton({ label, view }: OrderAdditionalInfoButtonProps): React.JSX.Element {
  const { contract: { buyingCompanyId } } = useAtomValue(orderAtom);
  const { open, close, isOpen } = useOpenable();

  return (
    <>
      <Button
        variant="outlined"
        onClick={open}
        startIcon={<InfoOutlinedIcon />}
        sx={{ flex: 'auto' }}
      >
        {label}
      </Button>
      <BuyerInfoDialog
        close={close}
        isOpen={isOpen}
        companyId={buyingCompanyId}
        openView={view}
      />
    </>
  );
}
