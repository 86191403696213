import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { Contract_Status_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { contractsAtom, currentContextAtom } from '../../../state';
import ContractsSelect from '../../../common/components/ContractsSelect';
import { ContractPreview } from '../../../hooks/useContracts';
import { DraftSubCatalog } from './state';
import { useSubCatalogsByCompanyIdQuery } from '../../../queries/__generated__/subCatalogsByCompanyId.generated';
import { insensitiveStringCompare } from '../../../common/utils/common.utils';

interface SubCatalogInfoFormProps {
  contractsValue?: string[];
  onChangeContracts?: (contracts: string[]) => void;
  nameValue?: string;
  onChangeName?: (name: string) => void;
}

export default function SubCatalogInfoForm({ contractsValue, nameValue, onChangeContracts, onChangeName }: SubCatalogInfoFormProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'validation', 'catalogs']);
  const { control, watch } = useFormContext<DraftSubCatalog>();
  const contracts = useAtomValue(contractsAtom);
  const { companyId } = useAtomValue(currentContextAtom);
  const { data: subCatalogsData } = useSubCatalogsByCompanyIdQuery({ variables: { companyId } });
  const [searchParams] = useSearchParams();
  const subCatalogId = searchParams.get('subCatalogId');
  const [signedContracts, unsignedContracts] = contracts.reduce<[ContractPreview[], ContractPreview[]]>((acc, contract) => {
    if (contract.status === Contract_Status_Enum.Signed) {
      acc[0].push(contract);
    } else {
      acc[1].push(contract);
    }

    return acc;
  }, [[], []]);
  const formContracts = watch('contracts');
  const { field: contractsField } = useController({
    name: 'contracts',
    control,
  });

  const existingSubCatalogsNames = useMemo((): Set<string> => {
    const existingNamesSet = new Set<string>();
    (subCatalogsData?.sub_catalog || []).forEach((subCatalog) => {
      if (subCatalog.id !== subCatalogId) {
        existingNamesSet.add(subCatalog.name);
      }
    });

    return existingNamesSet;
  }, [subCatalogsData]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="bodyMedium">
          {t('common:name')}
        </Typography>
        <Box>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
              validate: {
                unique: (value) => !existingSubCatalogsNames.has(value) || t('catalogs:sub_catalog_name_already_exists'),
                invalidValue: (value) => !insensitiveStringCompare(value, 'Catalogue par défaut') || t('catalogs:unavailable_name'),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  fullWidth
                  name={field.name}
                  value={nameValue || field.value}
                  inputRef={field.ref}
                  onChange={(event) => {
                    if (onChangeName) {
                      onChangeName(event.currentTarget.value);
                    } else {
                      field.onChange(event);
                    }
                  }}
                  placeholder={t('common:select_a_name')}
                  error={!!fieldState.error}
                  sx={{
                    '& .MuiInputBase-root': { height: 48 },
                  }}
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="bodyMedium">
          {t('common:buyer_other')}
        </Typography>
        <ContractsSelect
          onChange={(values) => {
            if (onChangeContracts) {
              onChangeContracts(values);
            } else {
              contractsField.onChange(values);
            }
          }}
          contracts={signedContracts}
          hiddenContracts={unsignedContracts}
          values={contractsValue || formContracts}
        />
      </Box>
    </Box>
  );
}
