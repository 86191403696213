import { Button, Card, Grid2, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from '../routes/AppRoutes';
import { PageWrapper } from '../common/components/PageWrapper';
import BackBar from '../common/components/BackBar';
import { useProductVersionByIdQuery } from '../queries/__generated__/productVersionById.generated';
import BaseUnit from './productPage/Product/BaseUnit/BaseUnit';
import ProductInfo from './productPage/Product/ProductInfo';

export default function ProductPage(): React.JSX.Element {
  const { t } = useTranslation(['common', 'products']);
  const theme = useTheme();
  const { productSku, companyId } = useParams();
  const { data: productData } = useProductVersionByIdQuery({
    variables: {
      companyId,
      productSku: Number(productSku),
      shouldCheckHistory: false,
    },
  });

  const product = useMemo(() => {
    return productData?.product?.[0];
  }, [productData]);

  const baseUnitsGridContainerLgColumn = useMemo((): number => {
    return product?.base_units!.length === 1 ? 4 : 8;
  }, [product]);

  const baseUnitsGridItemLgColumn = useMemo((): number => {
    return product?.base_units!.length === 1 ? 12 : 6;
  }, [product]);

  return (
    <>
      <BackBar to={RoutePaths.COMPANY_PRODUCTS} />
      <PageWrapper>
        {product && (
          <Grid2 container columnSpacing={2} rowSpacing={2} sx={{ justifyContent: 'center' }}>
            <Grid2
              size={{ xs: 12, md: 7, lg: 4 }}
              sx={{
                [theme.breakpoints.up('md')]: {
                  position: 'sticky',
                  top: 80,
                  alignSelf: 'start',
                },
              }}
            >
              <Card sx={{ pt: 4, pb: 3 }}>
                <ProductInfo product={product} />
              </Card>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 5, lg: baseUnitsGridContainerLgColumn }}>
              <Card
                sx={{
                  p: {
                    xs: 2,
                    md: 3,
                  },
                }}
              >
                <Grid2 container spacing={{ xs: 2, md: 3 }}>
                  <Grid2 size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                    <Typography variant="titleMedium">{t('products:base_unit', { count: product.base_units.length })}</Typography>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      component={Link}
                      to={`${RoutePaths.PRODUCT_ADD_PACKAGING}?productId=${product.id}`}
                    >
                      {t('common:add')}
                    </Button>
                  </Grid2>
                  {product.base_units.map((baseUnit, index) => (
                    <Grid2 size={{ xs: 12, sm: 6, md: 12, lg: baseUnitsGridItemLgColumn }} key={baseUnit.id}>
                      <BaseUnit
                        baseUnit={baseUnit}
                        baseUnitIndex={index}
                        product={product}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              </Card>
            </Grid2>
          </Grid2>
        )}
      </PageWrapper>
    </>
  );
}
