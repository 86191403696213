import { Tabs, TabsProps, tabClasses } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { RoutePaths } from '../routes/AppRoutes';
import KheopsTab from '../common/components/KheopsTab';
import useCurrentTabs from '../topMenu/hooks/useCurrentTab';
import { currentContextAtom } from '../state';
import { BuyerCompanyNavMap, NavigationCategories, SupplierCompanyNavMap } from '../common/state/navigation';

export interface UserCompanyNavigationProps {
  tabsProps?: TabsProps;
}

export default function UserCompanyNavigation({ tabsProps }: UserCompanyNavigationProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'price', 'contracts']);
  const { realm } = useAtomValue(currentContextAtom);
  const [currentCategory, setCurrentCategory] = useState<NavigationCategories>();
  const currentTabValue = useCurrentTabs([
    RoutePaths.COMPANY_PROFILE,
    RoutePaths.COMPANY_PRODUCTS,
    RoutePaths.COMPANY_DELIVERY_ZONES,
    RoutePaths.COMPANY_CUSTOM_PRICE_LISTS,
    RoutePaths.COMPANY_PRODUCTS_PRICES,
    RoutePaths.COMPANY_GLOBAL_DISCOUNTS,
    RoutePaths.COMPANY_BUYER_BILLING_INFO,
    RoutePaths.COMPANY_BUYER_DELIVERY_INFO,
    RoutePaths.COMPANY_CATALOGS,
  ]);
  const userCompanyNavMap = realm === Business_Profile_Enum.Supplier ? SupplierCompanyNavMap : BuyerCompanyNavMap;

  useEffect(() => {
    const currentNavData = userCompanyNavMap.find((navData) => {
      if (navData.subTabs.length) {
        return !!navData.subTabs.find((subTab) => (subTab.to === currentTabValue));
      }
      return navData.to === currentTabValue;
    });

    if (currentNavData) {
      setCurrentCategory(currentNavData.category);
    }
  }, [currentTabValue]);

  const mainTabs = useMemo(() => {
    return userCompanyNavMap.flatMap((item) => {
      const shouldDisplayNestedSubTabs = item.subTabs.length && currentCategory === item.category;
      const mainTab = (
        <KheopsTab
          key={item.to}
          to={item.to}
          component={Link}
          value={item.category}
          icon={<item.icon height={24} width={24} />}
          iconPosition="start"
          label={t(item.label)}
          sx={
            shouldDisplayNestedSubTabs
              ? {
                [`&.${tabClasses.selected}`]: {
                  backgroundColor: 'common.white',
                },
                ...item.sx,
              }
              : item.sx
          }
        />
      );

      const nestedSubTabs = shouldDisplayNestedSubTabs
        ? (
          <Tabs
            key={`${item.to}_nested_tabs`}
            textColor="secondary"
            value={currentTabValue}
            {...tabsProps}
            sx={{
              borderLeft: '1px solid',
              borderColor: 'divider',
              px: 2,
              ml: 2,
              mt: -1,
              ...tabsProps?.sx,
            }}
          >
            {item.subTabs.map((subTab) => (
              <KheopsTab
                key={subTab.to}
                value={subTab.to}
                component={Link}
                to={subTab.to}
                iconPosition="start"
                label={t(subTab.label)}
                sx={{
                  color: 'text.secondary',
                  height: 42,
                  minHeight: 42,
                  [`&.${tabClasses.selected}`]: {
                    fontWeight: 600,
                  },
                }}
              />
            ))}
          </Tabs>
        )
        : undefined;

      return [mainTab, nestedSubTabs];
    });
  }, [currentTabValue, currentCategory]);

  return (
    <Tabs
      textColor="secondary"
      value={currentCategory || false}
      {...tabsProps}
    >
      {mainTabs}
    </Tabs>
  );
}
