import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Menu, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { LoadingButton } from '@mui/lab';
import { PushTriggerOption } from '@sendbird/chat';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification_Code_Enum, Notification_Scope_Enum } from 'kheops-graphql';
import { currentChannelAtom } from './state';
import { useInsertNotificationSubscriptionMutation } from '../mutations/__generated__/insertNotificationSubscription.generated';
import { useDeleteNotificationSubscriptionMutation } from '../mutations/__generated__/deleteNotificationSubscription.generated';
import { commonSnackbarPropsAtom } from '../common/state/state';
import { userAtom } from '../state';

export interface ToggleNotificationChannelProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export default function ToggleNotificationChannel({ anchorEl, onClose }: ToggleNotificationChannelProps): React.JSX.Element {
  const { t } = useTranslation(['chat', 'common']);
  const { id: userId } = useAtomValue(userAtom);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const currentChannel = useAtomValue(currentChannelAtom);
  const hasNotification = currentChannel?.myPushTriggerOption !== 'off';
  const [insertNotification, { loading: insertNotifLoading }] = useInsertNotificationSubscriptionMutation();
  const [deleteNotification, { loading: deleteNotifLoading }] = useDeleteNotificationSubscriptionMutation();
  const [loading, setLoading] = useState(false);
  const [newNotificationStatus, setNewNotificationStatus] = useState<PushTriggerOption | undefined>(undefined);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const toggleNotifications = (): void => {
    setLoading(true);
    setNewNotificationStatus(undefined);

    const notificationParams = {
      user_id: userId,
      code: Notification_Code_Enum.Ch100,
      scope: Notification_Scope_Enum.Chat,
      object_id: currentChannel!.url,
    };

    if (hasNotification) {
      currentChannel!.setMyPushTriggerOption(PushTriggerOption.OFF);

      insertNotification({
        variables: {
          object: {
            ...notificationParams,
            subscribed: false,
          },
        },
      });
    } else {
      currentChannel!.setMyPushTriggerOption(PushTriggerOption.ALL);

      deleteNotification({
        variables: notificationParams,
      });
    }

    if (!isSmall) {
      onClose();
    }
  };

  useEffect(() => {
    if (!loading || insertNotifLoading || deleteNotifLoading || !newNotificationStatus) {
      return;
    }

    if (isSmall) {
      onClose();
    }

    setCommonSnackbarProps({
      label: t(`chat:${newNotificationStatus === PushTriggerOption.ALL ? 'notification_activated' : 'notification_deactivated'}`),
      snackbarProps: {
        open: true,
      },
    });

    setLoading(false);
  }, [newNotificationStatus, insertNotifLoading, deleteNotifLoading, loading]);

  useEffect(() => {
    if (currentChannel && !newNotificationStatus) {
      setNewNotificationStatus(currentChannel.myPushTriggerOption);
    }
  }, [currentChannel?.myPushTriggerOption]);

  if (isSmall) {
    return (
      <Dialog
        open={!!anchorEl}
        onClose={onClose}
      >
        <DialogTitle variant="displaySmall" sx={{ mt: 0, pt: 3 }}>
          {t('chat:conversation_muted')}
        </DialogTitle>
        <Divider sx={{ mx: 3 }} />
        <DialogContent>
          <Typography variant="bodySmall" color="secondary" sx={{ textAlign: 'center' }}>
            {t('chat:conversation_muted_description')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          {hasNotification
            ? (
              <Button
                variant="contained"
                onClick={toggleNotifications}
                startIcon={<NotificationsOffOutlinedIcon />}
              >
                {t('chat:mute')}
              </Button>
            )
            : (
              <LoadingButton
                variant="contained"
                onClick={toggleNotifications}
                startIcon={<NotificationsOutlinedIcon />}
                loading={loading}
              >
                {t('chat:reactivate')}
              </LoadingButton>
            )}
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: 4,
            width: 271,
          },
        },
      }}
      keepMounted
    >
      <Box
        sx={{
          py: 1,
          px: 3,
        }}
      >
        <FormControlLabel
          control={(
            <Switch
              checked={currentChannel?.myPushTriggerOption === 'off'}
              onClick={toggleNotifications}
            />
          )}
          label={t('chat:conversation_muted')}
          sx={{
            mx: 0,
            mb: 1,
          }}
        />
        <Typography variant="bodySmall" color="secondary">
          {t('chat:conversation_muted_description')}
        </Typography>
      </Box>
    </Menu>
  );
}
