import { FormHelperText, Grid2, OutlinedInput, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Trade_Item_Unit_Descriptor_Enum } from 'kheops-graphql';
import ValidatorsUtils from '../../../common/utils/validators.utils';
import { ProductPackagingsFormData } from '../ProductFormPackagings';
import TradeItemUnitSelect from './inputs/TradeItemUnitSelect';

interface CSUPackagingFormProps {
  baseUnitIndex: number;
  packagingIndex: number;
}

export default function CSUPackagingForm({ baseUnitIndex, packagingIndex }: CSUPackagingFormProps): React.JSX.Element {
  const { t } = useTranslation(['packaging', 'products', 'validation']);
  const { control, watch, setValue } = useFormContext<ProductPackagingsFormData>();
  const tradeItemUnitDescriptor = watch(`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.trade_item_unit_descriptor`);

  const disableUnitQuantity = useMemo((): boolean => {
    return tradeItemUnitDescriptor === Trade_Item_Unit_Descriptor_Enum.Unit;
  }, [tradeItemUnitDescriptor]);

  useEffect((): void => {
    if (disableUnitQuantity) {
      setValue(`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.unit_quantity`, 1);
    }
  }, [disableUnitQuantity]);

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:packaging_type')}*
          </Typography>
          <TradeItemUnitSelect name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.trade_item_unit_descriptor`} />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5, whiteSpace: 'nowrap' }}>
            {t('products:csu_by_packaging')}*
          </Typography>
          <Controller
            name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.unit_quantity`}
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
              min: { value: 1, message: t('validation:this_value_should_be_higher_than_N', { value: 0 }) },
              validate: {
                decimal: (value) => !!value && (ValidatorsUtils.validateDecimal(value) || (t('validation:this_value_should_be_a_integer') as string)),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <OutlinedInput
                  name={field.name}
                  value={field.value}
                  disabled={disableUnitQuantity}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  fullWidth
                  inputProps={{
                    min: '1',
                  }}
                  type="number"
                  placeholder={t('products:csu_by_packaging_placeholder')}
                  sx={{ height: 48 }}
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
      </Grid2>
      <Typography variant="bodySmall" color="secondary">
        {t('products:packagings_description')}
      </Typography>
    </>
  );
}
