import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useFormContext } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { formatAddressComponents } from 'kheops-utils';
import GoogleMapsAddressAutocomplete from '../../common/components/GoogleMapsAddressAutocomplete';
import { CompanyProfileForm } from './form.type';
import { placesLibraryReadyAtom } from '../../common/components/state';

interface CompanyAddressFormProps {
  companyId: string;
  companyTradeName: string;
}

export default function CompanyAddressForm({ companyId, companyTradeName }: CompanyAddressFormProps): React.JSX.Element {
  const { setValue, watch } = useFormContext<CompanyProfileForm>();
  const isPlacesLibraryReady = useAtomValue(placesLibraryReadyAtom);
  const addressFromForm = watch('address');
  const { t } = useTranslation(['common', 'settings']);
  const handleSelectAddress = (address: google.maps.places.PlaceResult): void => {
    const formattedAddressComponents = formatAddressComponents(address.address_components!);

    setValue(
      'address',
      {
        companyId,
        description: companyTradeName,
        formattedAddress: address.formatted_address,
        placeId: address.place_id,
        latitude: address.geometry?.location?.lat(),
        longitude: address.geometry?.location?.lng(),
        ...formattedAddressComponents,
      },
      {
        shouldDirty: true,
      },
    );
  };
  const segmentedFormattedAddress = addressFromForm?.formattedAddress?.replace(/,/g, '\n');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="bodySmall" color="secondary">{t('settings:street_number_and_name')}</Typography>
      <GoogleMapsAddressAutocomplete onSelectAddress={handleSelectAddress} placeholder={t('settings:update_address_here')} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          gap: 3,
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box>
          <Typography whiteSpace="break-spaces">{segmentedFormattedAddress}</Typography>
        </Box>
        {addressFromForm && isPlacesLibraryReady && (
          <Box
            sx={{
              width: {
                xs: '100%',
                md: 464,
              },
              height: {
                xs: 360,
                md: 464,
              },
            }}
          >
            <GoogleMap
              zoom={12}
              options={{
                disableDefaultUI: true,
              }}
              mapContainerStyle={{
                borderRadius: 12,
                width: '100%',
                height: '100%',
              }}
              center={{
                lat: addressFromForm.latitude!,
                lng: addressFromForm.longitude!,
              }}
            >
              <MarkerF
                position={{
                  lat: addressFromForm.latitude!,
                  lng: addressFromForm.longitude!,
                }}
                clickable={false}
              />
            </GoogleMap>
          </Box>
        )}
      </Box>
    </Box>
  );
}
