import { GroupChannel, GroupChannelListQueryParams } from '@sendbird/chat/groupChannel';
// eslint-disable-next-line import/no-unresolved
import { atom } from 'jotai';
import { Address, Administrative_Area, Brand_Enum, Business_Profile_Enum, Company, Contract, Custom_Price_List, Delivery_Zone, Maybe, Sub_Catalog, User } from 'kheops-graphql';
import { DisplayPhoto, RawPhoto } from '../common/common';

export interface GroupChannelMetadata {
  buyingCompanyId: string;
  supplyingCompanyId: string;
  hasSentBuyerAckNotification: string;
  inviterBusinessProfile: Business_Profile_Enum;
}

export type ContractConditions = Pick<Contract, 'discount' | 'minimum_order_value' | 'custom_price_list_id' | 'proposer_business_profile' | 'supplyingCompanyId' | 'sub_catalog_id'> &
  {
    customPriceListName?: string;
    catalogName?: string;
  };

export interface OfferMessageData extends ContractConditions {
  contract_id: string;
}

type ContractDeliveryZone = Pick<Delivery_Zone, 'minimum_order_value'> & {
  administrative_areas: Pick<Administrative_Area, 'area'>[]
};

export type Member = Pick<User, 'id' | 'first_name' | 'last_name' | 'auth_provider_avatar_url'> & { default_avatar?: Maybe<RawPhoto> };

export type CompanyOfferInfo = Pick<Company, 'tradeName' | 'id'> &
  {
    photos: DisplayPhoto[];
    role_assignments: { user: Member }[];
  };
export type SupplyingCompanyInfo = CompanyOfferInfo & {
  delivery_zones: ContractDeliveryZone[];
};
export type BuyingCompanyInfo = CompanyOfferInfo & {
  brand?: Maybe<Brand_Enum>;
  addresses: Pick<Address, 'administrativeAreaLevel2'>[];
};
export type ContractOfferInfo = Pick<Contract, 'id' | 'status' | 'proposer_business_profile' | 'proposer_user_id' | 'minimum_order_value' | 'discount' | 'custom_price_list_id' | 'supplyingCompanyId' | 'buyingCompanyId' | 'supplier_commission_rate' | 'sub_catalog_id'>
  & {
    supplying_company: SupplyingCompanyInfo;
    buying_company: BuyingCompanyInfo;
    custom_price_list?: Maybe<Pick<Custom_Price_List, 'name'>>;
    sub_catalog?: Maybe<Pick<Sub_Catalog, 'name'>>;
  };

export const baseCurrentChannelAtom = atom<GroupChannel | undefined>(undefined);
export const currentChannelAtom = atom<GroupChannel | undefined, Array<GroupChannel | undefined>, void>(
  (get) => get(baseCurrentChannelAtom),
  async (get, set, args) => {
    const channel = args;

    if (channel && !(channel.cachedMetaData as GroupChannelMetadata).buyingCompanyId) {
      await channel.getAllMetaData();
    }

    set(baseCurrentChannelAtom, channel);
  },
);
export const currentContractAtom = atom<ContractOfferInfo | undefined>(undefined);
export const newChannelSlideOpenAtom = atom<boolean>(false);

export const channelListQueryParamsAtom = atom<GroupChannelListQueryParams | undefined>(undefined);

export const showChannelMembersAtom = atom<boolean>(false);
