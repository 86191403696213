import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Comment_Type_Enum, TaskAction } from 'kheops-graphql';
import OrderCancellationDialog from './OrderCancellationDialog';
import useOrderActions from '../../hooks/useOrderActions';
import { useInsertCommentMutation } from '../../mutations/__generated__/insertComment.generated';
import { CommentsByOrderDocument } from '../../queries/__generated__/commentsByOrder.generated';

interface CancelOrderButtonProps {
  referenceId: string,
  userId: string;
  orderId: string;
}

export default function CancelOrderButton({ referenceId, userId, orderId }: CancelOrderButtonProps): React.JSX.Element {
  const { t } = useTranslation('order');
  const { updateOrder, isLoading } = useOrderActions({ orderReferenceId: referenceId, orderId });
  const [insertComment] = useInsertCommentMutation({ refetchQueries: [CommentsByOrderDocument] });
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleDialog = async (isConfirmed: boolean, comment?: string): Promise<void> => {
    setIsModalOpened(false);
    if (isConfirmed) {
      await updateOrder(TaskAction.Cancel);
      if (comment) {
        insertComment({
          variables: {
            comment: {
              content: comment,
              user_id: userId,
              order_reference_id: referenceId,
              type: Comment_Type_Enum.CancelReason,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <LoadingButton
        variant="outlined"
        onClick={() => setIsModalOpened(true)}
        loading={isLoading}
        sx={{ flex: 'auto' }}
      >
        {t('order:cancel_order')}
      </LoadingButton>
      <OrderCancellationDialog
        open={isModalOpened}
        onClose={handleDialog}
      />
    </>

  );
}
