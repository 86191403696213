import { Grid2, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MeasurementUnitSelect from './inputs/MeasurementUnitSelect';
import TradeItemUnitSelect from './inputs/TradeItemUnitSelect';
import UnitNetContentInput from './inputs/UnitNetContentInput';

interface WeightPackagingFormProps {
  baseUnitIndex: number;
  packagingIndex: number;
}

export default function WeightPackagingForm({ baseUnitIndex, packagingIndex }: WeightPackagingFormProps): React.JSX.Element {
  const { t } = useTranslation('products');

  return (
    <>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <Typography variant="bodySmall" sx={{ whiteSpace: 'nowrap', mb: 0.5 }}>
            {t('products:packaging_type')}*
          </Typography>
          <TradeItemUnitSelect name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.trade_item_unit_descriptor`} />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:net_content')}*
          </Typography>
          <UnitNetContentInput name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.net_content`} />
        </Grid2>
        <Grid2 size={{ xs: 12, sm: 4 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:measurement_unit')}*
          </Typography>
          <MeasurementUnitSelect name={`productPackagings.${baseUnitIndex}.packagings.${packagingIndex}.content_measurement_unit`} />
        </Grid2>
      </Grid2>
      <Typography variant="bodySmall" color="secondary">
        {t('products:bulk_packaging_description')}
      </Typography>
    </>
  );
}
