import { Typography } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../hooks/useAuth/useAuth';

function GoogleLoginButton(): React.JSX.Element {
  const { loginWithGoogle } = useAuth();
  const { t } = useTranslation(['login']);
  const [error, setError] = useState('');

  const handleGoogleLoginError = (err: AxiosError): void => {
    if (err.response?.status === 400) {
      setError('login:error_google_email_not_found');
    }
  };

  const onLoginWithGoogleSuccess = (
    response: CredentialResponse,
  ): void => {
    if (response.credential) {
      loginWithGoogle(response.credential)
        .catch(
          (err: AxiosError) => handleGoogleLoginError(err),
        );
    }
  };

  return (
    <>
      <GoogleLogin
        onSuccess={onLoginWithGoogleSuccess}
        locale={i18n.resolvedLanguage}
      />
      {error && (
      <Typography sx={{ mt: 2 }} variant="bodySmall" color="error" align="center">
        {t(error)}
      </Typography>
      )}
    </>
  );
}

export default React.memo(GoogleLoginButton);
