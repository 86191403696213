import { Chip, Typography } from '@mui/material';
import { useMemo } from 'react';
import { OrdersCategories } from '../state/state';

type OrderCategoryChipProps = {
  label: string;
  selectedCategory: OrdersCategories;
  category: OrdersCategories;
  onClick: (category: OrdersCategories) => void;
}

export default function OrderCategoryChip({ label, category, selectedCategory, onClick }: OrderCategoryChipProps): React.JSX.Element {
  const isChipSelected = useMemo(() => (
    selectedCategory === category
  ), [selectedCategory, category]);

  return (
    <Chip
      label={(
        <Typography
          variant="bodySmall"
          fontWeight={isChipSelected ? 600 : 400}
          color={isChipSelected ? 'text' : 'secondary'}
        >
          {label}
        </Typography>
      )}
      variant={isChipSelected ? undefined : 'outlined'}
      onClick={() => onClick(category)}
      clickable
      sx={{
        borderRadius: 12,
        borderColor: 'divider',
        backgroundColor: isChipSelected ? 'secondary.light' : undefined,
      }}
    />
  );
}
