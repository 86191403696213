import { Button, SxProps, Theme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';

interface ClearAllButtonProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

export default function ClearAllButton({ onClick, sx }: ClearAllButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <Button
      variant="text"
      startIcon={<ClearIcon />}
      sx={{ ...sx }}
      onClick={onClick}
    >
      {t('common:clear_all')}
    </Button>
  );
}
