import { Box, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import FileUtils from '../../../../common/utils/file.utils';
import { DraftPhoto } from '../../state';
import SizedImage from '../../../../common/components/SizedImage';
import PhotoEditOverlay from './PhotoEditOverlay';

interface GridPackagingPictureProps {
  csuIndex: number;
  src: DraftPhoto;
  onEdit: () => void;
  onDelete: () => void;
}

const GridPackagingPicture = React.memo(({ src, csuIndex, onEdit, onDelete }: GridPackagingPictureProps): React.JSX.Element => {
  const { t } = useTranslation(['products']);

  return (
    <Grid2 size={{ xs: 'auto' }}>
      <Box sx={{ position: 'relative', border: '1px solid', borderColor: 'divider', borderRadius: 6, height: 224, width: 224 }}>
        <PhotoEditOverlay
          csuIndex={csuIndex}
          src={src}
          onDelete={onDelete}
          onEdit={onEdit}
        />
        <SizedImage
          src={
            FileUtils.BuildPackagingImageUrl({
              photo: src,
              type: '',
              size: {
                height: 182,
                fit: 'contain',
              },
            })
          }
          sx={{
            borderRadius: 0,
            display: 'inline',
          }}
          height={182}
          width={182}
          alt={t('products:pictures_one')}
        />
      </Box>
    </Grid2>
  );
});

export default GridPackagingPicture;
