import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { Button } from '@mui/material';
import { Contract_Status_Enum } from 'kheops-graphql';
import { contractsAtom } from '../state';
import UpdateBasketButton from '../search/product/UpdateBasketButton';
import { RoutePaths } from '../routes/AppRoutes';
import DiscoverySupplierButton from '../discovery/as-buyer/DiscoverSupplierButton';

interface UsePackagingCTAButtonProps {
  packagingId?: string;
  supplierCompanyId: string;
}

export default function usePackagingCTAButton({ packagingId, supplierCompanyId }: UsePackagingCTAButtonProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);
  const navigate = useNavigate();
  const contracts = useAtomValue(contractsAtom);
  const contract = useMemo(() => {
    return contracts.find(({ supplyingCompanyId }) => supplierCompanyId === supplyingCompanyId);
  }, [contracts]);

  const ctaButton = useMemo(() => {
    if (!packagingId) {
      return <></>;
    }

    if (contract?.status === Contract_Status_Enum.Signed) {
      return (
        <UpdateBasketButton
          packagingId={packagingId}
          supplyingCompanyId={supplierCompanyId}
          shouldDisplayTitle
        />
      );
    }

    if (contract?.status === Contract_Status_Enum.Unsigned) {
      return (
        <Button
          variant="outlined"
          fullWidth
          onClick={() => navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${contract.channel_url}`)}
        >
          {t('contracts:negotiations_in_progress')}
        </Button>
      );
    }

    return (
      <DiscoverySupplierButton supplierCompanyId={supplierCompanyId} />
    );
  }, [contract, packagingId]);

  return ctaButton;
}
