import { Box, Checkbox, Chip, FormControlLabel, FormHelperText, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEffect, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomPhoneNumberInput from '../../common/components/CustomPhoneNumberInput';
import PrivacySettingSelect from './PrivacySettingSelect';
import { ContactInformations } from '../settingsDialog/ContactInformationSettings';

interface PhoneContractInformationProps {
  fieldName: 'mobile_phone_number' | 'landline_number',
}

const IgnorePhoneNumberLabelMap = {
  mobile_phone_number: 'settings:no_mobile_phone_number',
  landline_number: 'settings:no_landline_phone_number',
};

export default function PhoneContactInformationInput({ fieldName }: PhoneContractInformationProps): React.JSX.Element {
  const { control, watch, trigger } = useFormContext<ContactInformations>();
  const ignorePhoneNumber = watch(`${fieldName}.ignore`);
  const { t } = useTranslation(['settings', 'common']);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) {
      trigger(`${fieldName}.value`);
      trigger(`${fieldName}.privacy`);
    }
  }, [ignorePhoneNumber]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          gap: 2,
          mt: 2,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography variant="bodyMedium">{t('common:number')}</Typography>
          <Controller
            name={`${fieldName}.value`}
            control={control}
            rules={{
              validate: (phoneNumber) => (ignorePhoneNumber || (phoneNumber && isValidPhoneNumber(phoneNumber))),
            }}
            render={({ field, fieldState }) => (
              <>
                <CustomPhoneNumberInput
                  inputPlaceholder={t('common:number')}
                  formProps={field}
                  selectSx={{
                    height: 40,
                  }}
                  inputSx={{
                    height: 40,
                  }}
                  inputProps={{
                    disabled: ignorePhoneNumber,
                  }}
                />
                {fieldState.error?.type === 'validate' && (
                  <FormHelperText error>{t('settings:invalid_number')}</FormHelperText>
                )}
              </>
            )}
          />
        </Box>
        <PrivacySettingSelect fieldName={fieldName} />
      </Box>
      <Box>
        <Controller
          name={`${fieldName}.ignore`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} />}
              label={t(IgnorePhoneNumberLabelMap[fieldName])}
            />
          )}
        />
        {ignorePhoneNumber && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 4,
              p: 2,
            }}
          >
            <Chip
              color="marketing1"
              label={t('settings:reminder')}
              icon={<HelpOutlineIcon />}
              sx={{ mb: 1 }}
            />
            <Typography variant="bodyMedium">
              {t('settings:no_phone_number_disclaimer')}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
