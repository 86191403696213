import { Breakpoint } from '@mui/material';
import { atom } from 'jotai';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PhotoSize } from '../common/utils/file.utils';
import BeveragesImage from '../assets/images/product_families/BEVERAGES.jpg';
import FreshFoodImage from '../assets/images/product_families/FRESH_FOOD.jpg';
import GroceryImage from '../assets/images/product_families/GROCERY.jpg';
import NonFoodProductsImage from '../assets/images/product_families/NON_FOOD_PRODUCTS.jpg';
import BeerCiderImage from '../assets/images/product_families/BEER_CIDER.jpg';
import NonAlcoholicBeveragesImage from '../assets/images/product_families/NON_ALCOHOLIC_BEVERAGES.jpg';
import SpiritsImage from '../assets/images/product_families/SPIRITS.jpg';
import WineChampagneSparklingImage from '../assets/images/product_families/WINE_CHAMPAGNE_SPARKLING.jpg';
import BakeryImage from '../assets/images/product_families/BAKERY.jpg';
import DairyImage from '../assets/images/product_families/DAIRY.jpg';
import DeliImage from '../assets/images/product_families/DELI.jpg';
import FishSeafoodImage from '../assets/images/product_families/FISH_SEAFOOD.jpg';
import FruitsImage from '../assets/images/product_families/FRUITS.jpg';
import FrozenFoodImage from '../assets/images/product_families/FROZEN_FOOD.jpg';
import RawMeatImage from '../assets/images/product_families/RAW_MEAT.jpg';
import VegetablesImage from '../assets/images/product_families/VEGETABLES.jpg';
import VegetarianVeganImage from '../assets/images/product_families/VEGETARIAN_VEGAN.jpg';
import SaltyGroceryImage from '../assets/images/product_families/SALTY_GROCERY.jpg';
import SweetGroceryImage from '../assets/images/product_families/SWEET_GROCERY.jpg';
import ClothingTextileImage from '../assets/images/product_families/CLOTHING_TEXTILE.jpg';
import FloralImage from '../assets/images/product_families/FLORAL.jpg';
import HouseholdCleaningImage from '../assets/images/product_families/HOUSEHOLD_CLEANING.jpg';
import GamesToysImage from '../assets/images/product_families/GAMES_TOYS.jpg';
import HygieneBeautyImage from '../assets/images/product_families/HYGIENE_BEAUTY.jpg';
import PetsImage from '../assets/images/product_families/PETS.jpg';

export type FiltersMap = {[attribute: string]: string};

export const productFamilyImageMap: {[family in Product_Family_Name_Enum]: string} = {
  [Product_Family_Name_Enum.Beverages]: BeveragesImage,
  [Product_Family_Name_Enum.FreshFood]: FreshFoodImage,
  [Product_Family_Name_Enum.Grocery]: GroceryImage,
  [Product_Family_Name_Enum.NonFoodProducts]: NonFoodProductsImage,
};

export const productSubFamilyImageMap: {[subFamily in Product_Sub_Family_Name_Enum]: string} = {
  [Product_Sub_Family_Name_Enum.BeerCider]: BeerCiderImage,
  [Product_Sub_Family_Name_Enum.NonAlcoholicBeverages]: NonAlcoholicBeveragesImage,
  [Product_Sub_Family_Name_Enum.Spirits]: SpiritsImage,
  [Product_Sub_Family_Name_Enum.WineChampagneSparkling]: WineChampagneSparklingImage,
  [Product_Sub_Family_Name_Enum.Bakery]: BakeryImage,
  [Product_Sub_Family_Name_Enum.Dairy]: DairyImage,
  [Product_Sub_Family_Name_Enum.Deli]: DeliImage,
  [Product_Sub_Family_Name_Enum.FishSeafood]: FishSeafoodImage,
  [Product_Sub_Family_Name_Enum.Fruits]: FruitsImage,
  [Product_Sub_Family_Name_Enum.FrozenFood]: FrozenFoodImage,
  [Product_Sub_Family_Name_Enum.RawMeat]: RawMeatImage,
  [Product_Sub_Family_Name_Enum.Vegetables]: VegetablesImage,
  [Product_Sub_Family_Name_Enum.VegetarianVegan]: VegetarianVeganImage,
  [Product_Sub_Family_Name_Enum.SaltyGrocery]: SaltyGroceryImage,
  [Product_Sub_Family_Name_Enum.SweetGrocery]: SweetGroceryImage,
  [Product_Sub_Family_Name_Enum.ClothingTextile]: ClothingTextileImage,
  [Product_Sub_Family_Name_Enum.Floral]: FloralImage,
  [Product_Sub_Family_Name_Enum.HouseholdCleaning]: HouseholdCleaningImage,
  [Product_Sub_Family_Name_Enum.GamesToys]: GamesToysImage,
  [Product_Sub_Family_Name_Enum.HygieneBeauty]: HygieneBeautyImage,
  [Product_Sub_Family_Name_Enum.Pets]: PetsImage,
};

export const navigationDescriptionKeyByFamily: {[subFamily in Product_Sub_Family_Name_Enum | Product_Family_Name_Enum]: string} = {
  [Product_Family_Name_Enum.Beverages]: 'browse_beverages',
  [Product_Family_Name_Enum.FreshFood]: 'browse_fresh_food',
  [Product_Family_Name_Enum.Grocery]: 'browse_grocery',
  [Product_Family_Name_Enum.NonFoodProducts]: 'browse_non_food_products',
  [Product_Sub_Family_Name_Enum.BeerCider]: 'browse_beer_cider',
  [Product_Sub_Family_Name_Enum.NonAlcoholicBeverages]: 'browse_non_alcoholic_beverages',
  [Product_Sub_Family_Name_Enum.Spirits]: 'browse_spirits',
  [Product_Sub_Family_Name_Enum.WineChampagneSparkling]: 'browse_wine_champagne_sparklings',
  [Product_Sub_Family_Name_Enum.Bakery]: 'browse_bakery',
  [Product_Sub_Family_Name_Enum.Dairy]: 'browse_dairy',
  [Product_Sub_Family_Name_Enum.Deli]: 'browse_deli',
  [Product_Sub_Family_Name_Enum.FishSeafood]: 'browse_fish_seafood',
  [Product_Sub_Family_Name_Enum.Fruits]: 'browse_fruits',
  [Product_Sub_Family_Name_Enum.FrozenFood]: 'browse_frozen_food',
  [Product_Sub_Family_Name_Enum.RawMeat]: 'browse_raw_meat',
  [Product_Sub_Family_Name_Enum.Vegetables]: 'browse_vegetables',
  [Product_Sub_Family_Name_Enum.VegetarianVegan]: 'browse_vegetarian_vegan',
  [Product_Sub_Family_Name_Enum.SaltyGrocery]: 'browse_salty_grocery',
  [Product_Sub_Family_Name_Enum.SweetGrocery]: 'browse_sweet_grocery',
  [Product_Sub_Family_Name_Enum.ClothingTextile]: 'browse_clothing_textile',
  [Product_Sub_Family_Name_Enum.Floral]: 'browse_floral',
  [Product_Sub_Family_Name_Enum.HouseholdCleaning]: 'browse_household_cleaning',
  [Product_Sub_Family_Name_Enum.GamesToys]: 'browse_games_toys',
  [Product_Sub_Family_Name_Enum.HygieneBeauty]: 'browse_hygiene_beauty',
  [Product_Sub_Family_Name_Enum.Pets]: 'browse_pets',
};

export const navigationDescriptionOfSuppliersKeyByFamily: {[subFamily in Product_Sub_Family_Name_Enum | Product_Family_Name_Enum]: string} = {
  [Product_Family_Name_Enum.Beverages]: 'browse_beverages_suppliers',
  [Product_Family_Name_Enum.FreshFood]: 'browse_fresh_food_suppliers',
  [Product_Family_Name_Enum.Grocery]: 'browse_grocery_suppliers',
  [Product_Family_Name_Enum.NonFoodProducts]: 'browse_non_food_products_suppliers',
  [Product_Sub_Family_Name_Enum.BeerCider]: 'browse_beer_cider_suppliers',
  [Product_Sub_Family_Name_Enum.NonAlcoholicBeverages]: 'browse_non_alcoholic_beverages_suppliers',
  [Product_Sub_Family_Name_Enum.Spirits]: 'browse_spirits_suppliers',
  [Product_Sub_Family_Name_Enum.WineChampagneSparkling]: 'browse_wine_champagne_sparklings_suppliers',
  [Product_Sub_Family_Name_Enum.Bakery]: 'browse_bakery_suppliers',
  [Product_Sub_Family_Name_Enum.Dairy]: 'browse_dairy_suppliers',
  [Product_Sub_Family_Name_Enum.Deli]: 'browse_deli_suppliers',
  [Product_Sub_Family_Name_Enum.FishSeafood]: 'browse_fish_seafood_suppliers',
  [Product_Sub_Family_Name_Enum.Fruits]: 'browse_fruits_suppliers',
  [Product_Sub_Family_Name_Enum.FrozenFood]: 'browse_frozen_food_suppliers',
  [Product_Sub_Family_Name_Enum.RawMeat]: 'browse_raw_meat_suppliers',
  [Product_Sub_Family_Name_Enum.Vegetables]: 'browse_vegetables_suppliers',
  [Product_Sub_Family_Name_Enum.VegetarianVegan]: 'browse_vegetarian_vegan_suppliers',
  [Product_Sub_Family_Name_Enum.SaltyGrocery]: 'browse_salty_grocery_suppliers',
  [Product_Sub_Family_Name_Enum.SweetGrocery]: 'browse_sweet_grocery_suppliers',
  [Product_Sub_Family_Name_Enum.ClothingTextile]: 'browse_clothing_textile_suppliers',
  [Product_Sub_Family_Name_Enum.Floral]: 'browse_floral_suppliers',
  [Product_Sub_Family_Name_Enum.HouseholdCleaning]: 'browse_household_cleaning_suppliers',
  [Product_Sub_Family_Name_Enum.GamesToys]: 'browse_games_toys_suppliers',
  [Product_Sub_Family_Name_Enum.HygieneBeauty]: 'browse_hygiene_beauty_suppliers',
  [Product_Sub_Family_Name_Enum.Pets]: 'browse_pets_suppliers',
};

export const imageSizeByBreakpoint: Record<Breakpoint, PhotoSize> = {
  xs: {
    width: 168,
    height: 168,
  },
  sm: {
    width: 268,
    height: 268,
  },
  md: {
    width: 273,
    height: 273,
  },
  lg: {
    width: 276,
    height: 276,
  },
  xl: {
    width: 284,
    height: 284,
  },
};

export type DiscoveryView = 'SUPPLIERS' | 'PRODUCTS';
export type ProductFamilyNavigation = Product_Family_Name_Enum | 'ALL';
export type ProductSubFamilyNavigation = Product_Sub_Family_Name_Enum | 'ALL';

export const discoveryViewAtom = atom<DiscoveryView>('PRODUCTS');
export const currentFamilyAtom = atom<ProductFamilyNavigation>('ALL');
export const currentSubFamilyAtom = atom<ProductSubFamilyNavigation>('ALL');
export const dispatchNavigationAtom = atom(false);
