import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { editedOrderItemsAtom } from '../state/state';

interface OrderActionButtonCancelProps {
  disabled: boolean
}

export default function OrderActionButtonCancel({ disabled }: OrderActionButtonCancelProps): React.JSX.Element {
  const { t } = useTranslation('order');
  const setEditedItems = useSetAtom(
    editedOrderItemsAtom,
  );

  return (
    <Button
      variant="outlined"
      onClick={() => setEditedItems()}
      disabled={disabled}
      sx={{ flex: 'auto' }}
    >
      {t('order:cancel_modifications')}
    </Button>
  );
}
