import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, OutlinedInput, SxProps } from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface QuantitySelectorProps {
  quantity: number;
  onQuantityChange: (newQuantity: number) => void;
  defaultValue?:number;
  shouldDisplayTitle?: boolean;
  shouldDisplayButtons?: boolean;
  sx?: SxProps;
  inputSx?: SxProps;
  index?: number;
}

export default function QuantitySelector({
  quantity,
  onQuantityChange,
  defaultValue,
  shouldDisplayTitle,
  shouldDisplayButtons = true,
  sx,
  inputSx,
  index,
}: QuantitySelectorProps): React.JSX.Element {
  const [displayedValue, setDisplayedValue] = useState<number | ''>(quantity);
  const { t } = useTranslation(['basket']);
  const inputName = 'quantitySelector';
  const { setValue } = useForm({
    defaultValues: {
      [inputName]: defaultValue,
    },
  });

  // We should care for form dirtiness only if there is a default value
  const displayShoppingCart = useMemo(() => !quantity && defaultValue === undefined && shouldDisplayButtons, [quantity]);

  useEffect(() => {
    setDisplayedValue(quantity);
    setValue(inputName, quantity, { shouldDirty: true });
  }, [quantity]);

  const handleChange = (event: ChangeEvent): void => {
    const inputField = event.target as HTMLInputElement;
    let newQuantity = parseInt(inputField.value.replace(/[+-]/, ''), 10);

    if (Number.isNaN(newQuantity)) {
      setDisplayedValue('');
    } else {
      newQuantity = Math.min(newQuantity, 999);
      onQuantityChange(newQuantity);
      setDisplayedValue(newQuantity);
    }

    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const handleDecrementClick = (): void => {
    const newQuantity = Math.max(quantity - 1, 0);

    onQuantityChange(newQuantity);
    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const handleIncrementClick = (): void => {
    const newQuantity = Math.min(quantity + 1, 999);

    onQuantityChange(newQuantity);
    setValue(inputName, newQuantity, { shouldDirty: true });
  };

  const addToCartButton = useMemo((): React.JSX.Element => {
    if (shouldDisplayTitle) {
      return (
        <Button
          startIcon={<ShoppingCartOutlinedIcon />}
          variant="tonal"
          onClick={handleIncrementClick}
          className="KheopsButton-addToCart"
        >
          {t('basket:add_to_basket')}
        </Button>
      );
    }

    return (
      <Button variant="tonal" onClick={handleIncrementClick}>
        <ShoppingCartOutlinedIcon />
      </Button>
    );
  }, [shouldDisplayTitle]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!displayShoppingCart ? (
        <form style={{ display: 'flex' }}>
          {shouldDisplayButtons && (
            <Button variant="contained" onClick={handleDecrementClick}>
              <RemoveIcon />
            </Button>
          )}
          <OutlinedInput
            onChange={handleChange}
            inputProps={{
              min: 0,
              'data-testid': `quantity-${index}`,
              sx: {
                textAlign: 'center',
                '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                  display: 'none',
                },
                paddingY: 1,
                paddingX: 0,
                height: '1rem',
                ...inputSx,
              },
            }}
            name={inputName}
            size="small"
            sx={{
              mx: 0.5,
              minWidth: 0,
              maxWidth: 52,
              ...sx,
            }}
            type="number"
            value={displayedValue}
          />

          {shouldDisplayButtons && (
            <Button variant="contained" onClick={handleIncrementClick}>
              <AddIcon />
            </Button>
          )}
        </form>
      ) : (
        addToCartButton
      )}
    </>
  );
}
