import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontIcon from '@mui/icons-material/Storefront';
import React from 'react';
import { Box, DialogContent, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BuyerInfoViewProps } from './BuyerInfoDialog';
import ContactsByDepartment from '../contacts/ContactsByDepartment';

export default function BuyerInfoContactsView({ company, close, changeView }: BuyerInfoViewProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);

  return (
    <DialogContent sx={{ maxWidth: 500, height: 620, p: 2, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="text" onClick={() => changeView('GENERAL')}>
            <ArrowBackIcon />
          </Button>
          <StorefrontIcon sx={{ mx: 1 }} />
          <Typography variant="titleMedium" sx={{ flex: 1 }}>
            {t('contracts:company_contacts')}
          </Typography>
          <Button variant="text" onClick={close}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="bodySmall" color="secondary">
          {t('contracts:company_contacts_description')}
        </Typography>
      </Box>
      <Box sx={{ overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <ContactsByDepartment companyId={company.id} displayContactInfo />
      </Box>
    </DialogContent>
  );
}
