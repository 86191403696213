import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { format } from 'date-fns';
import { Business_Profile_Enum, Order_Status_Enum, TaskAction } from 'kheops-graphql';
import { convertSqlTimeToDate, getFormattedHour } from 'kheops-utils';
import { currentContextAtom } from '../../state';
import { orderAtom } from '../state/state';
import OrderUpdateDeliveryDateButton from './OrderUpdateDeliveryDateButton';
import OrderDeliveryDateDialog, { DeliveryDateOrderStatus } from './OrderDeliveryDateDialog';
import useOpenable from '../../hooks/useOpenable';
import { longDateFormat } from '../../common/state/state';
import { isDeliveryDay } from '../../common/utils/date.utils';

const NotDeliveredYetStatuses = [Order_Status_Enum.OrderToBeValidatedByBuyer, Order_Status_Enum.OrderToBeValidatedBySupplier, Order_Status_Enum.OrderToBeDeliveredBySupplier, Order_Status_Enum.OrderToBePreparedBySupplier];

export default function OrderDeliveryDateForm(): React.JSX.Element {
  const { t } = useTranslation(['order', 'common']);
  const { realm } = useAtomValue(currentContextAtom);
  const order = useAtomValue(orderAtom);
  const [deliveryDate, setDeliveryDate] = useState<Date>();
  const { open, close, isOpen } = useOpenable();

  const handleDeliveryDateDialogClose = (date: Date | null | undefined): void => {
    if (date) {
      setDeliveryDate(date);
    }
    close();
  };

  const canEditDeliveryDate = useMemo(() => {
    return (
      order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
      || order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier
      || order.status === Order_Status_Enum.OrderToBePreparedBySupplier
    ) && realm === Business_Profile_Enum.Supplier;
  }, [order]);

  const formattedDeliveryDate = useMemo((): string | undefined => {
    if (!deliveryDate) {
      return;
    }

    let result = format(deliveryDate, longDateFormat);

    if (isDeliveryDay(deliveryDate.getDay(), order.contract.buying_company.delivery_hours)) {
      const hoursDisplay = t(
        'order:between_hours',
        {
          openingHour: getFormattedHour(convertSqlTimeToDate(order.contract.buying_company.delivery_hours[0].opening_hour)),
          closingHour: getFormattedHour(convertSqlTimeToDate(order.contract.buying_company.delivery_hours[0].closing_hour)),
        },
      );

      result += ` ${hoursDisplay}`;
    }

    return result;
  }, [deliveryDate, order]);

  const dateComponent = useMemo(() => {
    if (!canEditDeliveryDate) {
      let message: string;

      if (!formattedDeliveryDate) {
        message = order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
          ? t('order:to_be_defined_by_the_supplier')
          : t('common:undefined');
      } else {
        message = formattedDeliveryDate;
      }

      return (
        <Typography variant="bodyMedium">{message}</Typography>
      );
    }

    if (!formattedDeliveryDate) {
      return (
        <Button startIcon={<AddIcon />} onClick={open}>
          {t('order:add_delivery_date')}
        </Button>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="bodyMedium">{formattedDeliveryDate}</Typography>
        {
          order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
            ? (
              <Button variant="text" onClick={open}>
                <EditOutlinedIcon />
              </Button>
            )
            : (
              <OrderUpdateDeliveryDateButton
                orderId={order.id}
                orderReferenceId={order.reference_id}
                orderStatus={order.status as DeliveryDateOrderStatus}
                useEditIcon
              />
            )
        }
      </Box>
    );
  }, [canEditDeliveryDate, deliveryDate, isOpen, order, formattedDeliveryDate]);

  useEffect((): void => {
    if (order.delivery_date) {
      setDeliveryDate(new Date(order.delivery_date));
    }
  }, [order.delivery_date]);

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        gap: 2,
        flexDirection: canEditDeliveryDate ? 'column' : 'row',
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="bodySmall" color="secondary" sx={{ mb: 0.5 }}>
          {NotDeliveredYetStatuses.includes(order.status)
            ? t('order:to_deliver_on')
            : t('order:delivered_on')}
        </Typography>
        {dateComponent}
        {canEditDeliveryDate && (
          <OrderDeliveryDateDialog
            open={isOpen}
            onClose={handleDeliveryDateDialogClose}
            orderStatus={order.status as DeliveryDateOrderStatus}
            actionType={TaskAction.Modify}
          />
        )}
      </Box>
    </Box>
  );
}
