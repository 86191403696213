import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Button, Chip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAtomValue, useSetAtom } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import BasketOrderSummaryDrawer from './BasketOrderSummaryDrawer';
import { useBasketOrders } from '../hooks/useBasketOrders';
import { basketDataAtom, isBasketDrawerOpenAtom } from './state/state';
import { RoutePaths } from '../routes/AppRoutes';
import { BasketSummaryEmpty } from './BasketSummaryEmpty';
import BasketMinimumOrderValues from './BasketMinimumOrderValues';
import { currentContextAtom } from '../state';
import BulkRemoveFromBasketButton from './BulkRemoveFromBasketButton';

interface BasketProps {
  isDrawer?: boolean;
}

export default function Basket({ isDrawer }: BasketProps): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation(['basket', 'common']);
  const context = useAtomValue(currentContextAtom);
  const basket = useAtomValue(basketDataAtom);
  const companySortedByLastUpdated = useMemo(() => {
    const companySet = new Set<string>();

    basket?.items.forEach((item) => {
      companySet.add(item.contract_id);
    });

    return companySet;
  }, [basket]);
  const basketItems = useMemo(() => {
    return basket?.items || [];
  }, [basket]);
  const orders = useBasketOrders({ basketItems });
  const setIsBasketDrawerOpen = useSetAtom(isBasketDrawerOpenAtom);

  const handleClick = (): void => {
    navigate(RoutePaths.BASKET);

    if (isDrawer) {
      setIsBasketDrawerOpen(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', px: isDrawer ? 1 : 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="bodyMedium">
          {t('basket:my_basket')}
        </Typography>
        <Chip
          sx={{ marginLeft: 1, height: 20, borderRadius: 20, fontWeight: 600 }}
          color="marketing1"
          size="small"
          label={`${basketItems.length} ${t('common:product', { count: basketItems.length })}`}
        />
        <Box sx={{ flex: 1 }} />
        {isDrawer && (
          <Button
            variant="text"
            aria-label="close"
            onClick={() => setIsBasketDrawerOpen(false)}
            sx={{
              alignSelf: 'end',
            }}
          >
            <CloseIcon />
          </Button>
        )}
      </Box>
      <Box sx={{ flex: 1, overflow: 'auto', mb: 1, display: 'flex', flexDirection: 'column', gap: 3 }}>
        { basketItems.length
          ? (
            <>
              {[...companySortedByLastUpdated].map((contractId) => {
                return (
                  <BasketOrderSummaryDrawer
                    order={orders[contractId]}
                    editable={isDrawer}
                    key={contractId}
                  />
                );
              })}
            </>
          )
          : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <BasketSummaryEmpty />
            </Box>
          )}
      </Box>
      <BasketMinimumOrderValues orders={orders} />
      <Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ my: 1 }}
          disabled={!basketItems.length}
          onClick={handleClick}
        >
          {t(`basket:${context.realm === Business_Profile_Enum.Buyer ? 'verify_my_basket' : 'verify_my_order_suggestion'}`)}
        </Button>
        {!!basketItems.length && (<BulkRemoveFromBasketButton />)}
      </Box>
    </Box>
  );
}
