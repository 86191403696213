import { InsertSubCatalogMutationVariables, useInsertSubCatalogMutation } from '../../../../mutations/__generated__/insertSubCatalog.generated';
import { useUpdateContractsMutation } from '../../../../mutations/__generated__/updateContracts.generated';
import { UpdateSubCatalogMutationVariables, useUpdateSubCatalogMutation } from '../../../../mutations/__generated__/updateSubCatalog.generated';
import { ContractsAsSupplierDocument } from '../../../../queries/__generated__/contractsAsSupplier.generated';
import { SubCatalogsByCompanyIdDocument } from '../../../../queries/__generated__/subCatalogsByCompanyId.generated';

export interface UpsertSubCatalogMutationVariables {
  upsertSubCatalogVariables: InsertSubCatalogMutationVariables | UpdateSubCatalogMutationVariables;
  addedContractIds?: string[];
  deletedContractIds?: string[];
}

export interface UseUpsertSubCatalogMutation {
  upsertSubCatalogMutation: (params: UpsertSubCatalogMutationVariables) => Promise<string | undefined>;
}

export default function UseUpsertSubCatalogMutation(): UseUpsertSubCatalogMutation {
  const [insertSubCatalog] = useInsertSubCatalogMutation({ refetchQueries: [SubCatalogsByCompanyIdDocument], awaitRefetchQueries: true });
  const [updateSubCatalog] = useUpdateSubCatalogMutation({ refetchQueries: [SubCatalogsByCompanyIdDocument], awaitRefetchQueries: true });
  const [updateContracts] = useUpdateContractsMutation({ refetchQueries: [SubCatalogsByCompanyIdDocument, ContractsAsSupplierDocument], awaitRefetchQueries: true });

  const upsertSubCatalogMutation = async ({ upsertSubCatalogVariables, addedContractIds, deletedContractIds }: UpsertSubCatalogMutationVariables): Promise<string | undefined> => {
    if ('id' in upsertSubCatalogVariables) {
      const { data: updateSubCatalogData } = await updateSubCatalog({ variables: upsertSubCatalogVariables });

      if (addedContractIds?.length) {
        await updateContracts({
          variables: {
            contractIds: addedContractIds,
            contractInput: {
              sub_catalog_id: upsertSubCatalogVariables.id,
            },
          },
        });
      }

      if (deletedContractIds?.length) {
        await updateContracts({
          variables: {
            contractIds: deletedContractIds!,
            contractInput: {
              sub_catalog_id: null,
            },
          },
        });
      }

      return updateSubCatalogData?.update_sub_catalog_by_pk?.id;
    }
    const insertSubCatalogOutput = await insertSubCatalog({
      variables: upsertSubCatalogVariables as InsertSubCatalogMutationVariables,
    });

    if (addedContractIds?.length) {
      await updateContracts({
        variables: {
          contractIds: addedContractIds,
          contractInput: {
            sub_catalog_id: insertSubCatalogOutput.data!.insert_sub_catalog_one!.id,
          },
        },
      });
    }

    return insertSubCatalogOutput.data?.insert_sub_catalog_one?.id;
  };

  return {
    upsertSubCatalogMutation,
  };
}
