import { Box, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import useNormalizedCurrentRefinements from '../hooks/useNormalizedCurrentRefinements';
import SizedImage from '../common/components/SizedImage';
import { packagingLogoMap } from '../common/utils/file.utils';
import { PackagingFamilyTypeIntl } from '../common/i18n/product-family.translation';
import CustomFilterTab from './CustomFilterTab';

export default function CompanyProductSubFamiliesFilter(): React.JSX.Element {
  const { t } = useTranslation('product-family');
  const currentRefinements = useNormalizedCurrentRefinements('family') as string[];
  const [filterValue, setFilterValue] = useState<string | null>(currentRefinements[0] || null);
  const { items, refine } = useRefinementList({ limit: 100, attribute: 'family' });
  const sortedProductSubFamiliesOptions = items.sort((itemA, itemB) => itemB.count - itemA.count || itemA.value.localeCompare(itemB.value));
  const productsCount = sortedProductSubFamiliesOptions.reduce((acc, item) => item.count + acc, 0);
  const thereIsOnlyOneProductSubFamily = useMemo(() => (
    sortedProductSubFamiliesOptions.length === 1
  ), []);

  useEffect(() => {
    if (thereIsOnlyOneProductSubFamily) {
      setFilterValue(sortedProductSubFamiliesOptions[0].value);
    }
  }, [thereIsOnlyOneProductSubFamily]);

  const handleTabsChange = (_: SyntheticEvent, value: string | null): void => {
    setFilterValue(value);

    if (filterValue) {
      refine(filterValue);
    }

    if (value) {
      refine(value);
    }
  };

  return (
    <Tabs
      variant="scrollable"
      value={filterValue}
      sx={{
        width: {
          xs: '-webkit-fill-available',
          md: 'inherit',
        },
      }}
      onChange={handleTabsChange}
      TabIndicatorProps={{
        sx: {
          display: 'none',
        },
      }}
      TabScrollButtonProps={{
        slots: {
          StartScrollButtonIcon: ArrowBackIcon,
          EndScrollButtonIcon: ArrowForwardIcon,
        },
        sx: {
          '&.Mui-disabled': {
            opacity: 1,
            color: 'text.disabled',
          },
          display: {
            xs: 'none',
            md: 'inline-flex',
          },
          opacity: 1,
          width: 40,
          height: 40,
          alignSelf: 'center',
        },
      }}
    >
      {!thereIsOnlyOneProductSubFamily && (
        <CustomFilterTab
          label={
            <Typography variant="labelLarge">{t('common:all_products')}&nbsp;({productsCount})</Typography>
          }
          value={null}
        />
      )}
      {sortedProductSubFamiliesOptions.map((item) => (
        <CustomFilterTab
          key={item.value}
          disabled={thereIsOnlyOneProductSubFamily}
          value={item.value}
          label={(
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <SizedImage
                src={packagingLogoMap[item.value as Product_Sub_Family_Name_Enum]}
                width={24}
                height={24}
                alt={item.value}
              />
              <Typography variant="labelLarge">
                {t(`product-family:${PackagingFamilyTypeIntl[item.value as Product_Sub_Family_Name_Enum]}`)}&nbsp;({item.count})
              </Typography>
            </Box>
          )}
        />
      ))}
    </Tabs>
  );
}
