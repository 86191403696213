import { Box, Card, Divider, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAtomValue } from 'jotai';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Authentication_Verification_Type_Enum } from 'kheops-graphql';
import KheopsLogoLong from '../assets/logo/kheops-logo-full-black.svg?react';
import EmailSentImage from '../assets/photos/email-sent.png';
import { preLoginEmailAtom } from './state';
import { useRedirectIfAuth } from '../hooks/useRedirectIfAuth';
import useAuth from '../hooks/useAuth/useAuth';
import { RoutePaths } from '../routes/AppRoutes';

const errorsMap = new Map<Authentication_Verification_Type_Enum, string>([
  [Authentication_Verification_Type_Enum.NoLongerValid, 'login:authentication_code_no_longer_valid'],
  [Authentication_Verification_Type_Enum.Invalid, 'login:authentication_code_invalid'],
]);

export default function PreLoginPage(): React.JSX.Element {
  const RedirectIfAuth = useRedirectIfAuth();
  const { loginWithAuthCode, errors, isLoading } = useAuth();
  const { t } = useTranslation('login');
  const preLoginEmail = useAtomValue(preLoginEmailAtom);
  const [enableErrorDisplay, setEnableErrorDisplay] = useState(false);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (otp.length === 6) {
      setEnableErrorDisplay(true);
      loginWithAuthCode(otp, preLoginEmail);
      return;
    }
    setEnableErrorDisplay(false);
  }, [otp]);

  const loginError = useMemo(() => {
    return (enableErrorDisplay && errors?.length) ? errors[0] as Authentication_Verification_Type_Enum : undefined;
  }, [errors, enableErrorDisplay]);

  return (
    <RedirectIfAuth>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <Card
          sx={{
            m: 'auto',
            px: {
              xs: 3,
              sm: 7,
            },
            pt: 4,
            pb: {
              xs: 5,
              sm: 7,
            },
            width: {
              xs: '85%',
              sm: 408,
            },
            borderRadius: 8,
          }}
        >
          <Box
            component={KheopsLogoLong}
            sx={{
              height: 50,
              width: 200,
              display: 'block',
              mx: 'auto',
            }}
          />
          <Box
            component="img"
            sx={{
              height: 120,
              width: 152,
              display: 'block',
              mx: 'auto',
            }}
            src={EmailSentImage}
          />
          <Typography variant="displayMedium" sx={{ my: 2 }} align="center">{t('login:confirm_identity')}</Typography>
          <Typography variant="bodyMedium" color="secondary" sx={{ my: 2 }} align="center">{t('login:magic_link_sent_confirmation', { preLoginEmail })}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Typography variant="titleMedium" align="center">{t('login:enter_code_below')}</Typography>
            <Box
              sx={{
                width: '100%',
                gap: {
                  xs: 1,
                  sm: 2,
                },
              }}
            >
              <OtpInput
                onChange={setOtp}
                value={otp}
                containerStyle={{
                  gap: 'inherit',
                  width: '100%',
                  justifyContent: 'center',
                }}
                inputType="number"
                numInputs={6}
                renderInput={(props) => (
                  <TextField
                    type="number"
                    fullWidth
                    inputProps={{
                      ...props,
                      sx: {
                        '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
                          display: 'none',
                        },
                        textAlign: 'center',
                      },
                      style: {},
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'text.primary',
                        },
                      },
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': {
                          borderColor: loginError ? 'error.main' : 'disabled',
                        },
                      },
                      maxWidth: 56,
                    }}
                  />
                )}
              />
            </Box>
            {loginError && (
              <Typography variant="bodySmall" color="error" align="center">
                <Trans
                  i18nKey={errorsMap.get(loginError) || loginError}
                  components={{
                    link1: <Link to={RoutePaths.LOGIN} style={{ color: 'inherit' }} />,
                  }}
                />
              </Typography>
            )}
            <LoadingButton variant="contained" disabled loading={isLoading}>
              {t('login:connection')}
            </LoadingButton>
            <Divider variant="middle" sx={{ textTransform: 'capitalize' }}>{t('login:or')}</Divider>
            <Typography variant="titleMedium" align="center">{t('login:click_link_sent_by_email')}</Typography>
          </Box>
        </Card>
      </Box>
    </RedirectIfAuth>
  );
}
