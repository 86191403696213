import { InsertCustomPriceListMutationVariables, useInsertCustomPriceListMutation } from '../../../mutations/__generated__/insertCustomPriceList.generated';
import { UpdateCustomPriceListMutationVariables, useUpdateCustomPriceListMutation } from '../../../mutations/__generated__/updateCustomPriceList.generated';
import { useUpdateContractsMutation } from '../../../mutations/__generated__/updateContracts.generated';
import { ContractsAsBuyerDocument } from '../../../queries/__generated__/contractsAsBuyer.generated';
import { ContractsAsSupplierDocument } from '../../../queries/__generated__/contractsAsSupplier.generated';
import { CustomPriceListsByCompanyIdDocument } from '../../../queries/__generated__/customPriceListsByCompanyId.generated';

export interface UpsertCustomPriceListMutationVariables {
  upsertCustomPriceListVariables: InsertCustomPriceListMutationVariables | UpdateCustomPriceListMutationVariables;
  addedContractIds?: string[];
  deletedContractIds?: string[];
}

export interface UseUpsertCustomPriceListMutation {
  upsertCustomPriceListMutation: (params: UpsertCustomPriceListMutationVariables) => Promise<string | undefined>;
}

export default function UseUpsertCustomPriceListMutation(): UseUpsertCustomPriceListMutation {
  const [insertCustomPriceList] = useInsertCustomPriceListMutation({ refetchQueries: [CustomPriceListsByCompanyIdDocument] });
  const [updateCustomPriceList] = useUpdateCustomPriceListMutation({ refetchQueries: [ContractsAsSupplierDocument] });
  const [updateContracts] = useUpdateContractsMutation({ refetchQueries: [CustomPriceListsByCompanyIdDocument] });

  const upsertCustomPriceListMutation = async ({ upsertCustomPriceListVariables, addedContractIds, deletedContractIds }: UpsertCustomPriceListMutationVariables): Promise<string | undefined> => {
    const updateContractRefetchQueries = [ContractsAsBuyerDocument, ContractsAsSupplierDocument];

    if ('id' in upsertCustomPriceListVariables) {
      const { data: updateCustomPriceListData } = await updateCustomPriceList({ variables: upsertCustomPriceListVariables });

      if (addedContractIds?.length) {
        await updateContracts({
          variables: {
            contractIds: addedContractIds,
            contractInput: {
              custom_price_list_id: upsertCustomPriceListVariables.id,
              discount: 0,
            },
          },
          refetchQueries: !deletedContractIds?.length ? updateContractRefetchQueries : undefined,
          awaitRefetchQueries: true,
        });
      }

      if (deletedContractIds?.length) {
        await updateContracts({
          variables: {
            contractIds: deletedContractIds!,
            contractInput: {
              custom_price_list_id: null,
              discount: 0,
            },
          },
          refetchQueries: updateContractRefetchQueries,
          awaitRefetchQueries: true,
        });
      }

      return updateCustomPriceListData?.update_custom_price_list_by_pk?.id;
    }
    const insertCustomPriceListOutput = await insertCustomPriceList({
      variables: upsertCustomPriceListVariables as InsertCustomPriceListMutationVariables,
    });

    if (addedContractIds?.length) {
      await updateContracts({
        variables: {
          contractIds: addedContractIds,
          contractInput: {
            custom_price_list_id: insertCustomPriceListOutput.data!.insert_custom_price_list_one!.id,
            discount: 0,
          },
        },
        refetchQueries: updateContractRefetchQueries,
        awaitRefetchQueries: true,
      });
    }

    return insertCustomPriceListOutput.data?.insert_custom_price_list_one?.id;
  };

  return {
    upsertCustomPriceListMutation,
  };
}
