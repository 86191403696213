import { Box, MenuItem, Select, SelectChangeEvent, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { OrdersCategories, orderListUiStateAtom } from '../state/state';
import OrderCategoryChip from './OrderCategoryChip';
import { OrdersCountByCategoryMap } from './OrderList';

type OrderCategoriesSelectionProps = {
  ordersCountByCategory: OrdersCountByCategoryMap;
  handleCategoryChange: (selectedCategory: OrdersCategories) => void;
}

type OrderCategoriesWordingsMap = {
  [status in OrdersCategories]: string;
}

const ORDERS_CATEGORIES_WORDINGS: OrderCategoriesWordingsMap = {
  ALL: 'order:all_orders',
  VALIDATION: 'order:validation',
  DELIVERY: 'common:delivery',
  PAYMENT: 'order:payment',
  COMPLETED: 'order:completed',
};

const orderedCategories = [
  OrdersCategories.ALL,
  OrdersCategories.VALIDATION,
  OrdersCategories.DELIVERY,
  OrdersCategories.PAYMENT,
  OrdersCategories.COMPLETED,
];

export default function OrderCategoriesSelection({ ordersCountByCategory, handleCategoryChange }: OrderCategoriesSelectionProps): React.JSX.Element {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation(['common', 'order']);
  const { currentSetCategory } = useAtomValue(orderListUiStateAtom);

  const categoriesChips = useMemo(() => (

    orderedCategories.map((category) => (

      <OrderCategoryChip
        label={`${t(ORDERS_CATEGORIES_WORDINGS[category], { count: ordersCountByCategory[category] })} (${ordersCountByCategory[category]})`}
        category={category}
        selectedCategory={currentSetCategory}
        onClick={handleCategoryChange}
      />
    ))
  ), [currentSetCategory, handleCategoryChange, ordersCountByCategory]);

  const menuItems = useMemo(() => (

    orderedCategories.map((category) => (

      <MenuItem
        value={category}
        sx={{
          fontSize: 14,
          '&.Mui-selected': {
            fontWeight: 600,
            backgroundColor: 'common.white',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
          py: 2,
          '&:not(:last-child)': {
            borderBottom: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        {`${t(ORDERS_CATEGORIES_WORDINGS[category], { count: ordersCountByCategory[category] })} (${ordersCountByCategory[category]})`}
      </MenuItem>
    ))
  ), [ordersCountByCategory]);

  return isDownMd
    ? (
      <Select
        value={currentSetCategory}
        onChange={(e: SelectChangeEvent) => {
          handleCategoryChange(e.target.value as OrdersCategories);
        }}
        renderValue={(selected) => (
          <Typography variant="bodySmall" fontWeight={600}>
            {`${t(ORDERS_CATEGORIES_WORDINGS[selected as OrdersCategories], { count: ordersCountByCategory[selected as OrdersCategories] })} (${ordersCountByCategory[selected as OrdersCategories]})`}
          </Typography>
        )}
        MenuProps={{
          slotProps: {
            paper: {
              sx: {
                borderRadius: 4,
                mt: 1,
                p: 1,
              },
            },
          },
        }}
        sx={{
          height: 32,
          borderRadius: 12,
          width: 'fit-content',
          backgroundColor: 'secondary.light',
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
          },
        }}
      >
        {menuItems}
      </Select>
    )
    : (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {categoriesChips}
      </Box>
    );
}
