import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useSetAtom, useAtomValue } from 'jotai';
import { useNavigate } from 'react-router';
import { SubCatalogsByCompanyIdDocument, SubCatalogsByCompanyIdQuery } from '../../../queries/__generated__/subCatalogsByCompanyId.generated';
import BrandBox from '../../../common/components/BrandBox';
import { getCompanyShortName } from '../../../common/utils/common.utils';
import { signedContractsAtom } from '../../../state';
import { RoutePaths } from '../../../routes/AppRoutes';
import useOpenable from '../../../hooks/useOpenable';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import { useDeleteSubCatalogByIdMutation } from '../../../mutations/__generated__/deleteSubCatalogById.generated';
import { ContractsAsSupplierDocument } from '../../../queries/__generated__/contractsAsSupplier.generated';
import SubCatalogDeleteDialog from './SubCatalogDeleteDialog';

export interface SubCatalogCardProps {
  subCatalog: SubCatalogsByCompanyIdQuery['sub_catalog'][number];
  supplierPackagingsCount: number;
  shouldDisplayOptionsMenu: boolean;
}

export default function SubCatalogCard({ subCatalog, supplierPackagingsCount, shouldDisplayOptionsMenu }: SubCatalogCardProps): React.JSX.Element {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation(['catalogs', 'discovery', 'products']);
  const signedContracts = useAtomValue(signedContractsAtom);
  const { open, close, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [deleteSubCatalogById] = useDeleteSubCatalogByIdMutation({
    refetchQueries: [SubCatalogsByCompanyIdDocument, ContractsAsSupplierDocument],
    awaitRefetchQueries: true,
  });

  const { contractChipIcon, contractChipLabel } = useMemo(() => {
    if (subCatalog.contracts.length === 0) {
      return {
        contractChipLabel: t('catalogs:no_store'),
        contractChipIcon: (
          <Box
            component={StoreOutlinedIcon}
            sx={{ width: 16, height: 16 }}
          />
        ),
      };
    }

    if (subCatalog.contracts.length === 1) {
      return {
        contractChipLabel: getCompanyShortName(subCatalog.contracts[0].buying_company.tradeName, Business_Profile_Enum.Buyer),
        contractChipIcon: (
          <BrandBox brand={subCatalog.contracts[0].buying_company.brand!} logoWidth={16} width={16} height={16} />
        ),
      };
    }

    if (subCatalog.contracts.length === signedContracts.length) {
      return {
        contractChipLabel: t('catalogs:all_stores'),
        contractChipIcon: (
          <Box
            component={StoreOutlinedIcon}
            sx={{ width: 16, height: 16 }}
          />
        ),
      };
    }

    return {
      contractChipLabel: t('catalogs:N_store_count', { count: subCatalog.contracts.length }),
      contractChipIcon: (
        <Box
          component={StoreOutlinedIcon}
          sx={{ width: 16, height: 16 }}
        />
      ),
    };
  }, [subCatalog]);

  const packagingsChipLabel = useMemo(() => {
    if (subCatalog.sub_catalog_packagings.length === 0) {
      return t('products:no_product');
    }

    if (subCatalog.sub_catalog_packagings.length === supplierPackagingsCount) {
      return t('discovery:all_products');
    }

    return t('discovery:N_product', { count: subCatalog.sub_catalog_packagings.length, formattedCount: subCatalog.sub_catalog_packagings.length });
  }, [subCatalog, supplierPackagingsCount]);

  const handleOptionsButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleConfirm = (confirm: boolean): void => {
    close();

    if (confirm) {
      deleteSubCatalogById({ variables: { id: subCatalog.id } });

      setCommonSnackbarProps({
        label: t('common:delete_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'surfaceContainerLow.main',
          p: 2,
          borderRadius: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: 'surfaceContainer.main',
            cursor: 'pointer',
          },
        }}
        onClick={() => navigate(`${RoutePaths.SUB_CATALOG}?subCatalogId=${subCatalog.id}`)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          <Typography sx={{ textAlign: 'start' }} variant="bodyLarge">{subCatalog.name}</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
            }}
          >
            <Chip
              label={contractChipLabel}
              icon={contractChipIcon}
              sx={{
                backgroundColor: 'surfaceContainerHigh.main',
                pr: 1.5,
                pl: 1,
                borderRadius: 100,
              }}
            />
            <Chip
              label={packagingsChipLabel}
              icon={<Box component={RemoveRedEyeOutlinedIcon} sx={{ width: 16, height: 16 }} />}
              sx={{
                backgroundColor: 'surfaceContainerHigh.main',
                pr: 1.5,
                pl: 1,
                borderRadius: 100,
              }}
            />
          </Box>
        </Box>
        {shouldDisplayOptionsMenu && (
        <Button
          variant="text"
          onClick={handleOptionsButtonClick}
          sx={{ mt: -0.5 }}
        >
          <MoreVertIcon />
        </Button>
        )}
      </Box>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 4,
            gap: 2,
          },
          '& .MuiMenuItem-root': {
            py: 1,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 26,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
            color: 'black',
          },
        }}
      >
        <MenuItem onClick={() => navigate(`${RoutePaths.SUB_CATALOG}?subCatalogId=${subCatalog.id}`)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:modify')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`${RoutePaths.SUB_CATALOG}?copySubCatalogId=${subCatalog.id}`)}>
          <ListItemIcon>
            <ContentCopyOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:duplicate')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={open}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <SubCatalogDeleteDialog
        subCatalog={subCatalog}
        supplierPackagingsCount={supplierPackagingsCount}
        isOpen={isOpen}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
