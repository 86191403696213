import {
  createRoutesFromElements,
  Outlet,
  Route,
  RouteObject,
} from 'react-router-dom';
import LoginPage from '../auth/login-page';
import MagicLinkPage from '../auth/MagicLinkPage';
import PreLoginPage from '../auth/PreLoginPage';
import { BasketSummaryPage } from '../basket/BasketSummaryPage';
import OrdersConfirmationPage from '../basket/OrdersConfirmationPage';
import OfflinePage from '../common/components/offline/OfflinePage';
import ContractList from '../contracts/ContractList';
import CompanyPage from '../company/CompanyPage';
import OrderListPage from '../orders/list/OrderListPage';
import ProductAddPage from '../products/form/ProductAddPage';
import ProductFormInfo from '../products/form/info/ProductFormInfo';
import ProductFormPackagings from '../products/form/ProductFormPackagings';
import ProductListPage from '../products/list/ProductListPage';
import SearchProductPage from '../search/product/SearchProductPage';
import { RoutePaths } from '../routes/AppRoutes';
import { RequireAuth } from '../routes/RequireAuth';
import SearchBuyerPage from '../search/buyer/SearchBuyerPage';
import RootPage from '../RootPage';
import DefaultPage from '../DefaultPage';
import PackagingPage from '../products/PackagingPage';
import ProductPage from '../products/ProductPage';
import UserPage from '../user/UserPage';
import ChatListPage from '../chats/ChatListPage';
import ErrorPage from '../common/components/ErrorPage';
import CompanyDeliveryZones from '../company/delivery_zones/CompanyDeliveryZones';
import UserCompanyPage from '../user/UserCompanyPage';
import CustomPriceListsList from '../company/customPriceLists/CustomPriceListsList';
import CustomPriceListForm from '../company/customPriceLists/CustomPriceListForm';
import CompanyPackagingsPrices from '../company/prices/CompanyPackagingsPrices';
import GlobalDiscountList from '../company/global_discounts/GlobalDiscountList';
import OrderTrackingPage from '../orders/tracking-page/OrderTrackingPage';
import NavigationMain from '../topMenu/NavigationMain';
import NavigationContexts from '../topMenu/NavigationContexts';
import CompanyProfile from '../company/profile/CompanyProfile';
import MyCompanyPage from '../company/MyCompanyPage';
import DiscoveryAsBuyerProvider from '../discovery/as-buyer/DiscoveryAsBuyerProvider';
import BuyerInvoicingInfoPage from '../company/buyer/BuyerInvoicingInfoPage';
import BuyerDeliveryInfoPage from '../company/buyer/BuyerDeliveryInfoPage';
import SubCatalogForm from '../company/catalogs/subCatalogs/SubCatalogForm';
import CatalogsList from '../company/catalogs/CatalogsList';

export default function useKheopsRoutes(): RouteObject[] {
  return createRoutesFromElements((
    <Route element={<RootPage />}>
      <Route path={RoutePaths.ROOT} element={<RequireAuth />} errorElement={<ErrorPage />}>
        <Route path={RoutePaths.ROOT} element={<DefaultPage />} />
        <Route path={RoutePaths.NAVIGATION} element={<Outlet />}>
          <Route path={RoutePaths.NAVIGATION_MAIN} element={<NavigationMain />} />
          <Route path={RoutePaths.NAVIGATION_CONTEXTS} element={<NavigationContexts />} />
        </Route>
        <Route path={RoutePaths.CONTRACT_COMPANY} element={<CompanyPage />} />
        <Route path={RoutePaths.CONTRACT_LIST} element={<ContractList />} />
        <Route path={RoutePaths.PRODUCT} element={<ProductPage />} />
        <Route path={RoutePaths.PACKAGING} element={<PackagingPage />} />
        <Route path={RoutePaths.PACKAGING_VERSION} element={<PackagingPage />} />
        <Route path={RoutePaths.PRODUCT_ADD} element={<ProductAddPage />}>
          <Route path={RoutePaths.PRODUCT_ADD_INFO} element={<ProductFormInfo />} />
          <Route path={RoutePaths.PRODUCT_ADD_PACKAGING} element={<ProductFormPackagings />} />
        </Route>
        <Route path={RoutePaths.DISCOVERY}>
          <Route path={RoutePaths.DISCOVERY_SUPPLIERS} element={<DiscoveryAsBuyerProvider />} />
          <Route path={RoutePaths.DISCOVERY_SUPPLIER} element={<CompanyPage />} />
          <Route path={RoutePaths.DISCOVERY_BUYERS} element={<SearchBuyerPage />} />
          <Route path={RoutePaths.DISCOVERY_BUYER} element={<CompanyPage />} />
        </Route>
        <Route path={RoutePaths.MY_COMPANY} element={<MyCompanyPage />} />
        <Route path={RoutePaths.COMPANY} element={<UserCompanyPage />}>
          <Route path={RoutePaths.COMPANY_BUYER_BILLING_INFO} element={<BuyerInvoicingInfoPage />} />
          <Route path={RoutePaths.COMPANY_BUYER_DELIVERY_INFO} element={<BuyerDeliveryInfoPage />} />
          <Route path={RoutePaths.COMPANY_PRODUCTS} element={<ProductListPage />} />
          <Route path={RoutePaths.COMPANY_CATALOGS} element={<CatalogsList />} />
          <Route path={RoutePaths.COMPANY_DELIVERY_ZONES} element={<CompanyDeliveryZones />} />
          <Route path={RoutePaths.COMPANY_PROFILE} element={<CompanyProfile />} />
          <Route path={RoutePaths.COMPANY_PRICES}>
            <Route path={RoutePaths.COMPANY_PRODUCTS_PRICES} element={<CompanyPackagingsPrices />} />
            <Route path={RoutePaths.COMPANY_GLOBAL_DISCOUNTS} element={<GlobalDiscountList />} />
            <Route path={RoutePaths.COMPANY_CUSTOM_PRICE_LISTS} element={<CustomPriceListsList />} />
          </Route>
        </Route>
        <Route path={RoutePaths.CUSTOM_PRICE_LIST} element={<CustomPriceListForm />} />
        <Route path={RoutePaths.SEARCH_PRODUCT} element={<SearchProductPage />} />
        <Route path={RoutePaths.BASKET} element={<BasketSummaryPage />} />
        <Route path={RoutePaths.ORDERS_CONFIRMATION} element={<OrdersConfirmationPage />} />
        <Route path={RoutePaths.ORDER_LIST} element={<OrderListPage />} />
        <Route path={RoutePaths.ORDER_TRACKING} element={<OrderTrackingPage />} />
        <Route path={RoutePaths.USER} element={<UserPage />} />
        <Route path={RoutePaths.CHAT_LIST} element={<ChatListPage />} />
        <Route path={RoutePaths.SUB_CATALOG} element={<SubCatalogForm />} />
      </Route>
      <Route path={RoutePaths.LOGIN} element={<LoginPage />} />
      <Route path={RoutePaths.OFFLINE} element={<OfflinePage />} />
      <Route path={RoutePaths.PRELOGIN} element={<PreLoginPage />} />
      <Route path={RoutePaths.MAGICLINK} element={<MagicLinkPage />} />
    </Route>
  ));
}
