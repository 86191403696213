/* eslint-disable react/jsx-no-useless-fragment */
import { Box, CircularProgress, Grid2, GridSize, Typography } from '@mui/material';
import { AlgoliaHit } from 'instantsearch.js';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import { useParams } from 'react-router';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useCompaniesBaseUnitsCountLazyQuery } from '../../queries/__generated__/companiesBaseUnitsCount.generated';
import EmptyPagePlaceholder, { EmptyPagePlaceholderProps } from '../../common/components/EmptyPagePlaceholder';
import { contractsAtom, currentContextAtom } from '../../state';
import { DisplayMode, searchProductReadyAtom } from '../state/state';
import { ProductHit } from '../search';
import SearchProductHit, { SearchContract, SearchProductHitOptions } from './SearchProductHit';

interface SearchProductHitsProps {
  hitProps?: SearchProductHitOptions;
  displayMode: DisplayMode;
  cardSizes?: { [key: string]: GridSize };
  currentContract?: SearchContract;
  emptyPlaceholderProps: EmptyPagePlaceholderProps;
}

export default function SearchProductHits({ hitProps, displayMode, cardSizes, currentContract, emptyPlaceholderProps }: SearchProductHitsProps): React.JSX.Element {
  const { t } = useTranslation(['search', 'contracts']);
  const { companyId } = useParams();
  const context = useAtomValue(currentContextAtom);
  const contracts = useAtomValue(contractsAtom);
  const { items, results } = useHits<AlgoliaHit<ProductHit>>();
  const [getCompaniesBaseUnits, { data: companiesBaseUnitsData, loading: isCompaniesBaseUnitsCountLoading }] = useCompaniesBaseUnitsCountLazyQuery();
  const [noProducts, setNoProducts] = useState(false);

  const getContract = useCallback((hit: ProductHit) => {
    if (currentContract) {
      return currentContract;
    }

    return contracts.find((contract) => hit.company_id === contract.supplyingCompanyId)!;
  }, [currentContract]);

  const [isReady, setIsReady] = useAtom(searchProductReadyAtom);

  useEffect(() => {
    if (companyId) {
      const companyToQuery = context.realm === Business_Profile_Enum.Supplier ? context.companyId : companyId;
      getCompaniesBaseUnits({
        variables: {
          companiesIds: [companyToQuery],
        },
      });
    } else {
      getCompaniesBaseUnits({
        variables: {
          companiesIds: contracts.map((contract) => contract.supplyingCompanyId),
        },
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (!companiesBaseUnitsData) {
      return;
    }

    setNoProducts(!companiesBaseUnitsData.base_unit_aggregate.aggregate?.count);
  }, [companiesBaseUnitsData]);

  useEffect(() => {
    // When results have the params string, it means that the initial search was done.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((results as any)?.params) {
      setIsReady(false);
    }
  }, [results]);

  return (
    <>
      {isReady || isCompaniesBaseUnitsCountLoading
        ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '96px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {
              noProducts
                ? (
                  <EmptyPagePlaceholder {...emptyPlaceholderProps} />
                ) : (
                  <>
                    <Grid2
                      container
                      columnSpacing={{
                        xs: 0,
                        sm: 2,
                      }}
                      rowSpacing={1}
                    >
                      {items.length
                        ? items.map((item) => <SearchProductHit displayMode={displayMode} hit={item} key={item.objectID} {...hitProps} sizes={cardSizes} contract={getContract(item)} />)
                        : (
                          <Grid2 size={{ xs: 12 }}>
                            <Typography variant="titleMedium" sx={{ flex: 1, textAlign: 'center', pt: 2 }}>
                              {t('search:no_result_for_this_search')}
                            </Typography>
                          </Grid2>
                        )}
                    </Grid2>
                  </>
                )
            }
          </>
        )}
    </>
  );
}
