import { Divider, FormHelperText, Grid2, InputAdornment, OutlinedInput } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ComputePackagingPriceFromVolumePrice, FormatPackagingVolumePrice, formatPriceByBillingType, GetConvertedUnit, round } from 'kheops-utils';
import { Base_Unit_Type_Enum, Billing_Type_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { Controller, useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { CompanyPackaging } from '../state';
import { DraftPackagingsPrices } from './state';
import CompanyPackagingCard from '../companyPackagingCard';

export interface CatalogProductsFormRowProps {
  packaging: CompanyPackaging;
}

export default function PackagingsPricesFormRow({ packaging }: CatalogProductsFormRowProps): React.JSX.Element {
  const productIsBilledByWeight = packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent;
  const isBulk = packaging.base_unit.unit_type === Base_Unit_Type_Enum.Bulk;
  const { t } = useTranslation(['products', 'validation', 'price']);
  const currencyFormat = useCurrencyFormat();
  const percentFormatter = Intl.NumberFormat(i18next.resolvedLanguage, { style: 'percent', maximumFractionDigits: 2 });
  const { control, setValue, watch, trigger } = useFormContext<DraftPackagingsPrices>();
  const formPackagingPrice = watch(`prices.${packaging.base_unit.sku!}.packagings.${packaging.sku}.packagingPrice`);
  const formBaseUnitPrice = watch(`prices.${packaging.base_unit.sku!}.packagings.${packaging.sku}.packagingUnitPrice`);
  const formSuggestedRetailPrice = watch(`prices.${packaging.base_unit.sku!}.suggestedRetailPrice`);

  const formattedTaxValue = useCallback((baseUnitPrice: number): string => {
    const volumeUnits = GetConvertedUnit((isBulk ? packaging.content_measurement_unit : packaging.base_unit.content_measurement_unit)!);
    const tax = currencyFormat.format(baseUnitPrice * packaging.product.vat_rate!);

    if ((packaging.base_unit.billing_type === Billing_Type_Enum.WeightDependent || isBulk) && volumeUnits) {
      const priceMeasurementUnit = volumeUnits.volumeUnit === Measurement_Unit_Enum.L ? Measurement_Unit_Enum.L : volumeUnits.volumeUnit.toLowerCase();

      return `${tax}/${priceMeasurementUnit}`;
    }

    return tax;
  }, []);

  const getMinSuggestedRetailPrice = useCallback((baseUnitPrice: number): number => {
    return round(baseUnitPrice * (1 + packaging.product.vat_rate!), 2);
  }, []);

  const volumePrice = useMemo(() => {
    if (isBulk || productIsBilledByWeight) {
      const valueWithUnit = FormatPackagingVolumePrice(packaging, packaging.base_unit);

      if (valueWithUnit.unit === Measurement_Unit_Enum.Unit) {
        return {
          value: valueWithUnit.value,
          unit: t('packaging:unit_one'),
        };
      }

      return valueWithUnit;
    }
  }, [packaging]);

  const maxPrice = useMemo(() => {
    if (formSuggestedRetailPrice) {
      return round(formSuggestedRetailPrice / (1 + packaging.product.vat_rate!), 2);
    }

    return Infinity;
  }, [packaging, formSuggestedRetailPrice]);

  useEffect(() => {
    const price = Number.isNaN(formBaseUnitPrice) ? 0 : formBaseUnitPrice;
    const packagingPrice = isBulk || productIsBilledByWeight
      ? round(ComputePackagingPriceFromVolumePrice(
        packaging,
        {
          ...packaging.base_unit,
        },
        price,
      ))
      : round(price * (packaging.unit_quantity || packaging.net_content)!);

    setValue(`prices.${packaging.base_unit.sku!}.packagings.${packaging.sku}.packagingPrice`, round(packagingPrice), { shouldDirty: true });
  }, [formBaseUnitPrice]);

  useEffect(() => {
    trigger(`prices.${packaging.base_unit.sku!}.packagings.${packaging.sku}.packagingUnitPrice`);
  }, [formSuggestedRetailPrice]);

  return (
    <Grid2 container size={{ xs: 12 }} columnSpacing={1}>
      <Grid2
        size={{ xs: 12, lg: 6 }}
        sx={{
          display: 'flex',
          gap: 1,
          height: 121,
          py: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <CompanyPackagingCard packaging={packaging} />
      </Grid2>
      <Grid2
        container
        size={{ xs: 12, lg: 6 }}
        columnSpacing={1}
        sx={{
          alignItems: 'baseline',
          py: {
            xs: 1,
            lg: 0,
          },
        }}
      >
        <Grid2 size={{ xs: 4 }} sx={{ margin: { xs: 'auto', lg: 'unset' } }}>
          {formatPriceByBillingType(
            currencyFormat.format(formPackagingPrice),
            packaging.base_unit.billing_type,
          )}
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <Controller
            name={`prices.${packaging.base_unit.sku!}.packagings.${packaging.sku}.packagingUnitPrice`}
            control={control}
            rules={{
              validate: {
                max: (baseUnitPrice) => {
                  if (baseUnitPrice > maxPrice) {
                    return t(
                      'validation:this_value_should_be_higher_than_all_base_units_prices',
                      {
                        minValue: currencyFormat.format(getMinSuggestedRetailPrice(baseUnitPrice)),
                        priceExclTax: currencyFormat.format(baseUnitPrice),
                        vatRate: percentFormatter.format(packaging.product.vat_rate!),
                        tax: formattedTaxValue(baseUnitPrice),
                      },
                    );
                  }

                  return true;
                },
              },
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
              required: true,
            }}
            render={({ field, fieldState }) => (
              <>
                <OutlinedInput
                  {...field}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    field.onChange(event);
                  }}
                  error={!!fieldState.error}
                  inputProps={{
                    min: '0',
                    max: maxPrice,
                    step: '.01',
                  }}
                  type="number"
                  endAdornment={(
                    <InputAdornment position="start">
                      {volumePrice
                        ? `€/${volumePrice.unit}`
                        : <EuroIcon sx={{ fontSize: '1rem' }} />}
                    </InputAdornment>
                    )}
                  sx={{
                    '& input[type=number]::-webkit-outer-spin-button': {
                      display: 'none',
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                  }}
                />
                {fieldState.error && (
                  // width at 200% makes the error message takes the place below both inputs
                  <FormHelperText sx={{ width: '200%' }} error>{fieldState.error?.message}</FormHelperText>
                )}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 4 }}>
          <Controller
            name={`prices.${packaging.base_unit.sku!}.suggestedRetailPrice`}
            control={control}
            rules={{
              min: { value: getMinSuggestedRetailPrice(formBaseUnitPrice), message: '' },
            }}
            render={({ field, fieldState }) => (
              <OutlinedInput
                {...field}
                size="small"
                fullWidth
                onChange={(event) => {
                  field.onChange(event);
                }}
                error={!!fieldState.error}
                inputProps={{
                  min: '0',
                  step: '.01',
                }}
                type="number"
                endAdornment={(
                  <InputAdornment position="start">
                    {volumePrice
                      ? `€/${volumePrice.unit}`
                      : <EuroIcon sx={{ fontSize: '1rem' }} />}
                  </InputAdornment>
                  )}
                sx={{
                  '& input[type=number]::-webkit-outer-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                }}
              />
            )}
          />
        </Grid2>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Divider />
      </Grid2>
    </Grid2>
  );
}
