import { addDays, getWeek, isSunday } from 'date-fns';
import { Delivery_Hours } from 'kheops-graphql';
import { WeekDays } from '../../company/buyer/state';

export default class DateUtils {
  static AddWorkingDaysToDate(date: Date, workingDays: number): Date {
    let dateToShift = date;
    for (let i = 1; i <= workingDays; i++) {
      dateToShift = addDays(dateToShift, 1);
      if (isSunday(dateToShift)) {
        dateToShift = (addDays(dateToShift, 1));
      }
    }
    return dateToShift;
  }
}

export function isDeliveryDay(day: number, deliveryHours?: Pick<Delivery_Hours, 'week_day'>[]): boolean {
  if (!deliveryHours?.length) {
    return false;
  }

  const dayName: WeekDays = Object.values(WeekDays)[day ? day - 1 : 6];
  const deliveryDays = deliveryHours.map(({ week_day }) => week_day);

  return deliveryDays.includes(dayName);
}

export function numberOfWeeksFromNow(date: Date): number {
  const now = new Date();

  if (date < now) {
    return 0;
  }

  const diff = getWeek(date, { weekStartsOn: 1 }) - getWeek(now, { weekStartsOn: 1 });

  // Handle case when given date is on next year.
  if (diff < 0) {
    return 52 + diff;
  }

  return diff;
}
