import { Button, Divider, Grid2, TextField, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useAuth from '../hooks/useAuth/useAuth';
import AuthForm from './AuthForm';
import { preLoginEmailAtom } from './state';
import { usePreLoginMutation } from '../mutations/__generated__/preLogin.generated';
import { RoutePaths } from '../routes/AppRoutes';
import GoogleLoginButton from './GoogleLoginButton';

function LoginForm(): React.JSX.Element {
  const { t } = useTranslation('login');
  const { isLoading } = useAuth();

  const [email, setEmail] = useState<string>('');
  const setPreLoginEmail = useSetAtom(preLoginEmailAtom);
  const [preLogin, preLoginOutput] = usePreLoginMutation();
  const navigate = useNavigate();
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    if (preLoginOutput.data?.preLogin) {
      setPreLoginEmail(email);
      navigate(RoutePaths.PRELOGIN);
      return;
    }
    if (preLoginOutput.error?.message === 'Email not found') {
      setEmailErrorMessage(t('login:error_email_not_found'));
    }
  }, [preLoginOutput]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    preLogin({
      variables: {
        email,
      },
    });
  };

  const handleContactClick = (): void => {
    window.location.href = 'https://www.kheops.io/contactez-nous';
  };

  return (
    <AuthForm onSubmit={handleSubmit}>
      <Grid2 size={{ xs: 12 }} textAlign="center" mb={1}>
        <Typography variant="titleMedium" align="center">
          {t('login:log_in_to_your_account')}
        </Typography>
      </Grid2>
      <TextField
        label={t('login:email')}
        variant="outlined"
        type="email"
        name="email"
        required
        value={email}
        size="small"
        onChange={(e) => setEmail(e.target.value)}
        error={Boolean(preLoginOutput.error)}
        helperText={emailErrorMessage}
      />
      <Grid2 container size={{ xs: 12 }} direction="column" mt={2}>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          fullWidth
        >
          {t('login:log_in')}
        </Button>
        <Grid2 container alignItems="center" justifyContent="center" mt={1}>
          <Typography variant="bodyMedium" align="center">
            {t('login:dont_have_an_account')}
          </Typography>
          <Button variant="text" onClick={handleContactClick}>
            {t('login:contact_us')}
          </Button>
        </Grid2>
      </Grid2>
      <Grid2 width="100%" my={3}>
        <Divider>
          <Typography variant="bodyMedium" align="center">
            {t('login:or')}
          </Typography>
        </Divider>
      </Grid2>
      <GoogleLoginButton />
    </AuthForm>
  );
}

export default LoginForm;
