import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm, useController, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClearRefinements } from 'react-instantsearch';
import { useAtomValue } from 'jotai';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import useRefine from '../../hooks/useRefine';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import { HistoryIndexUiState } from '../state/state';
import FilterButton, { FilterButtonMode, FilterButtonVariant } from './FilterButton';
import GeoLocationAdministrativeAreaFilter from './GeoLocationAdministrativeAreaFilter';
import GeoLocationRadiusRange from './GeoLocationRadiusRange';
import { currentContextAtom } from '../../state';

enum GeoLocationFilterValueEnum {
  ALL = 'ALL',
  RANGE = 'RANGE',
  ADMINISTRATIVE_AREA = 'ADMINISTRATIVE_AREA',
}

export interface GeoLocationFormValues {
  type: GeoLocationFilterValueEnum,
  range: number;
  administrativeAreas: string[];
}

interface GeoLocationFilterProps {
  index: string;
  mode?: FilterButtonMode;
  variant?: FilterButtonVariant;
}

export default function GeoLocationFilter({ index, mode, variant }: GeoLocationFilterProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const administrativeAreas = useNormalizedCurrentRefinements('administrative_areas');
  const { refine: clearAdministrativeAreasRefine } = useClearRefinements({ includedAttributes: ['administrative_areas'] });
  const form = useForm<GeoLocationFormValues>({
    defaultValues: {
      type: GeoLocationFilterValueEnum.ALL,
      range: 100,
    },
  });
  const { control, watch, setValue } = form;
  const { field: typeField } = useController({ name: 'type', control });
  const [range, type] = watch(['range', 'type']);
  const { refine, uiState } = useRefine();
  const { realm } = useAtomValue(currentContextAtom);

  const activeItemCount = useMemo(() => {
    switch (type) {
      case GeoLocationFilterValueEnum.RANGE:
        return 1;
      case GeoLocationFilterValueEnum.ADMINISTRATIVE_AREA:
        return administrativeAreas.length;
      default:
        return 0;
    }
  }, [type, administrativeAreas]);

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const aroundRadius = event.target.value === GeoLocationFilterValueEnum.RANGE ? range * 1000 : 'all';

    refine({
      aroundRadius,
    });
    clearAdministrativeAreasRefine();
  };

  useEffect(() => {
    if (type === GeoLocationFilterValueEnum.RANGE && uiState.aroundRadius === 'all') {
      setValue('type', GeoLocationFilterValueEnum.ALL);
    }
  }, [uiState]);

  useEffect(() => {
    if (administrativeAreas.length) {
      setValue('type', GeoLocationFilterValueEnum.ADMINISTRATIVE_AREA);

      refine({
        aroundRadius: 'all',
      });
    }
  }, [administrativeAreas.length]);

  useEffect(() => {
    const historyIndexUiState = window.history.state.uiState?.[index] as HistoryIndexUiState | undefined;

    if (historyIndexUiState?.configure && historyIndexUiState.configure.aroundRadius !== 'all') {
      setValue('type', GeoLocationFilterValueEnum.RANGE);

      if (historyIndexUiState.configure.aroundRadius) {
        setValue('range', historyIndexUiState.configure.aroundRadius / 1000);
      }
    } else if (historyIndexUiState?.refinementList?.administrative_areas) {
      setValue('type', GeoLocationFilterValueEnum.ADMINISTRATIVE_AREA);
    }
  }, []);

  return (
    <FormProvider {...form}>
      <FilterButton
        buttonProps={{
          startIcon: <LocationOnOutlinedIcon />,
        }}
        variant={variant}
        activeItemCount={activeItemCount}
        label={t('discovery:distance')}
        mode={mode}
        name="geolocation"
        content={(
          <RadioGroup
            {...typeField}
            onChange={(event) => {
              typeField.onChange(event);
              handleTypeChange(event);
            }}
            sx={{ gap: 2 }}
          >
            <FormControlLabel
              value={GeoLocationFilterValueEnum.ALL}
              control={<Radio />}
              label={t('discovery:search_without_limit')}
            />
            <Divider />
            <FormControlLabel
              value={GeoLocationFilterValueEnum.RANGE}
              control={<Radio />}
              label={t(DiscoveryWordingsKeys[realm].geoloc_radius_label)}
            />
            {type === GeoLocationFilterValueEnum.RANGE && <GeoLocationRadiusRange />}
            <Divider />
            <FormControlLabel
              value={GeoLocationFilterValueEnum.ADMINISTRATIVE_AREA}
              control={<Radio />}
              label={t('discovery:search_by_administrative_area')}
            />
            <Box
              sx={{
                display: type === GeoLocationFilterValueEnum.ADMINISTRATIVE_AREA ? 'flex' : 'none',
                flexDirection: 'column',
                maxWidth: '100%',
              }}
            >
              <GeoLocationAdministrativeAreaFilter />
            </Box>
          </RadioGroup>
        )}
      />
    </FormProvider>
  );
}
