import { Button } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { useAddOrderToBasket } from '../../hooks/useAddOrderToBasket';

export interface AddOrderPackaging {
  id: string;
  quantity: number;
  weight?: number | null;
}

export interface AddOrderToBasketButtonProps {
  packagings: AddOrderPackaging[];
  contractReferenceId: string;
  priceUpdatedPackagings: string[];
  unavailablePackagings: string[];
  fullWidth?: boolean;
  onClick?: () => void;
}

export default function AddOrderToBasketButton({ packagings, contractReferenceId, priceUpdatedPackagings, unavailablePackagings, fullWidth, onClick }: AddOrderToBasketButtonProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);
  const { addOrderToBasket: handleAddToBasketClick, addedToBasket } = useAddOrderToBasket({
    orderPackagings: {
      packagings,
      priceUpdatedPackagings,
      unavailablePackagings,
      contractReferenceId,
    },
    onClick,
  });

  return (
    <Button
      variant="tonal"
      startIcon={addedToBasket ? <CheckCircleOutlineOutlinedIcon /> : <AddShoppingCartIcon />}
      disabled={addedToBasket}
      onClick={handleAddToBasketClick}
      fullWidth={fullWidth}
    >
      {t(addedToBasket ? 'common:added' : 'basket:add_to_basket')}
    </Button>
  );
}
