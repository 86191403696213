import { Step, StepLabel, Stepper, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import EuroIcon from '@mui/icons-material/Euro';
import { useAtomValue } from 'jotai';
import { Order_Status_Enum } from 'kheops-graphql';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { contextToActionNeededStatusMap, orderAtom } from '../../state/state';
import CustomStepIcon from './CustomStepIcon';
import OrderStatusChip from '../OrderStatusChip';
import CustomConnector from './CustomConnector';
import { currentContextAtom } from '../../../state';

enum OrderMainStepsValues {
  VALIDATION = 'VALIDATION',
  DELIVERY = 'DELIVERY',
  PAYMENT = 'PAYMENT',
  FINISHED = 'FINISHED',
}
type OrderStatusHandledByStepper = Exclude<Order_Status_Enum, Order_Status_Enum.OrderArchived | Order_Status_Enum.OrderCancelled>

type OrderStatusToStepMap = {
  [status in OrderStatusHandledByStepper]: OrderMainStepsValues
};

interface OrderStatusStepperProps {
  sx?: SxProps;
}

const orderStatusToStepMap : OrderStatusToStepMap = {
  order_to_be_validated_by_buyer: OrderMainStepsValues.VALIDATION,
  order_to_be_validated_by_supplier: OrderMainStepsValues.VALIDATION,
  order_to_be_prepared_by_supplier: OrderMainStepsValues.DELIVERY,
  order_to_be_delivered_by_supplier: OrderMainStepsValues.DELIVERY,
  order_to_be_received_by_buyer: OrderMainStepsValues.DELIVERY,
  order_to_be_billed_by_supplier: OrderMainStepsValues.PAYMENT,
  order_to_be_paid_by_buyer: OrderMainStepsValues.PAYMENT,
  order_being_paid_by_buyer: OrderMainStepsValues.PAYMENT,
  order_closed: OrderMainStepsValues.FINISHED,
};

const stepsInfoMap: {
  [step in OrderMainStepsValues]: {
    icon: React.ReactElement,
    label: string;
  }
} = {
  VALIDATION: {
    icon: <AccessTimeIcon />,
    label: 'order:validation',
  },
  DELIVERY: {
    icon: <LocalShippingOutlinedIcon />,
    label: 'common:delivery',
  },
  PAYMENT: {
    icon: <EuroIcon />,
    label: 'order:payment',
  },
  FINISHED: {
    icon: <ThumbUpAltOutlinedIcon />,
    label: 'order:completed_one',
  },
};

const orderedSteps = [
  OrderMainStepsValues.VALIDATION,
  OrderMainStepsValues.DELIVERY,
  OrderMainStepsValues.PAYMENT,
  OrderMainStepsValues.FINISHED,
];

export default function OrderStatusStepper({ sx }: OrderStatusStepperProps): React.JSX.Element {
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation(['common', 'order']);
  const order = useAtomValue(orderAtom);
  const { realm } = useAtomValue(currentContextAtom);
  const activeStep = orderStatusToStepMap[order.status as OrderStatusHandledByStepper];

  const getStepLabel = (step: OrderMainStepsValues): React.JSX.Element | undefined => {
    if (isDownLg) {
      return;
    }

    if (step === activeStep) {
      return <OrderStatusChip status={order.status} />;
    }
    return (
      <Typography variant="bodySmall">
        {t(stepsInfoMap[step].label)}
      </Typography>
    );
  };

  const steps = orderedSteps.map((step) => {
    return (
      <Step
        key={step}
      >
        <StepLabel
          StepIconComponent={(props) => (
            <CustomStepIcon
              canEditStep={contextToActionNeededStatusMap.get(realm)!.includes(order.status)}
              {...props}
            />
          )}
          StepIconProps={{
            icon: stepsInfoMap[step].icon,
          }}
          sx={{
            '& .MuiStepLabel-label': {
              mt: 1.5,
            },
          }}
        >
          {getStepLabel(step)}
        </StepLabel>
      </Step>
    );
  });

  return (
    <Stepper
      alternativeLabel
      connector={<CustomConnector />}
      activeStep={orderedSteps.indexOf(activeStep)}
      sx={sx}
    >
      {steps}
    </Stepper>
  );
}
