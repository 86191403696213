import { Container, containerClasses, Grid2, Typography } from '@mui/material';
import { AlgoliaHit } from 'instantsearch.js';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import { SupplierHit } from '../search';
import SearchBuyerHit from './SearchBuyerHit';

export default function SearchBuyerHits(): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { items, results } = useHits<AlgoliaHit<SupplierHit>>();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!results && (
        <Container
          maxWidth="xl"
          sx={{
            // The selector is here is used to increase the specifity.
            [`&.${containerClasses.maxWidthXl}`]: {
              px: {
                xs: 1,
                sm: 0,
              },
            },
          }}
        >
          <Typography variant="titleLarge">
            {t(DiscoveryWordingsKeys.SUPPLIER.n_result, { count: results.nbHits })}
          </Typography>
          <Typography
            variant="bodyMedium"
            sx={{
              color: 'text.secondary',
              mt: 0.5,
              mb: 2,
            }}
          >
            {t(DiscoveryWordingsKeys.SUPPLIER.sorted_by_distance)}
          </Typography>
          <Grid2 container columnSpacing={2}>
            {items.map((item) => (
              <Grid2 key={item.objectID} size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <SearchBuyerHit hit={item} />
              </Grid2>
            ))}
          </Grid2>
        </Container>
      )}
    </>
  );
}
