import { Box, Button, Card, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Product_Family_Name_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { ProductFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import { ellipsisStyle } from '../../common/utils/style.utils';
import { ProductSubFamilyNavigation, currentFamilyAtom, currentSubFamilyAtom, discoveryViewAtom } from '../state';
import { DiscoveryNavigationItem, getProductFamilyNavigationItems, getProductSubFamilyNavigationItems } from '../discovery.utils';
import useNavigateByProductFamily from '../hooks/useNavigateByProductFamily';

interface DiscoveryCarrouselNavigationSlideProps {
  item: DiscoveryNavigationItem;
  onClick: (family: Product_Family_Name_Enum | Product_Sub_Family_Name_Enum) => void;
  currentSubFamily: ProductSubFamilyNavigation;
}

export function DiscoveryCarrouselNavigationSlide({ item, onClick, currentSubFamily }: DiscoveryCarrouselNavigationSlideProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        cursor: 'pointer',
        backgroundColor: item.family === currentSubFamily ? 'text.primary' : 'white',
        height: 40,
        alignItems: 'center',
        borderRadius: 10,
        '&:hover': {
          backgroundColor: item.family === currentSubFamily ? undefined : 'secondary.light',
        },
        '&:active': {
          backgroundColor: item.family === currentSubFamily ? undefined : 'greys.light',
        },
        pr: 1,
        pl: 0.5,
      }}
      onClick={() => onClick(item.family)}
    >
      <Box
        component="img"
        src={item.img}
        sx={{
          borderRadius: 4,
          objectFit: 'cover',
          width: 32,
          height: 32,
        }}
      />
      <Typography
        variant="bodyMedium"
        color={item.family === currentSubFamily ? 'white' : undefined}
        sx={{ ...ellipsisStyle }}
      >
        {item.label}
      </Typography>
    </Box>
  );
}

export default function DiscoveryCarrouselNavigation(): React.JSX.Element {
  const { t } = useTranslation(['discovery', 'product-family']);
  const currentFamily = useAtomValue(currentFamilyAtom);
  const currentSubFamily = useAtomValue(currentSubFamilyAtom);
  const discoveryView = useAtomValue(discoveryViewAtom);
  const { navigate } = useNavigateByProductFamily();

  const items = useMemo((): DiscoveryNavigationItem[] => {
    return currentFamily !== 'ALL'
      ? getProductSubFamilyNavigationItems(t, currentFamily)
      : getProductFamilyNavigationItems(t);
  }, [currentFamily]);

  const title = useMemo(() => {
    const isProductView = discoveryView === 'PRODUCTS';

    if (currentFamily === 'ALL') {
      return t(isProductView ? 'discovery:all_products' : 'discovery:all_suppliers');
    }

    return t(`product-family:${ProductFamilyTypeIntl[currentFamily]}`);
  }, [currentFamily, currentSubFamily, discoveryView]);

  const handleNavigation = (family: Product_Family_Name_Enum | Product_Sub_Family_Name_Enum | 'ALL'): void => {
    if (currentFamily !== 'ALL') {
      navigate(currentFamily, family as Product_Sub_Family_Name_Enum);
    } else {
      navigate(family as Product_Family_Name_Enum, 'ALL');
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
        backgroundColor: 'transparent',
        px: 0,
        pt: 0,
        borderRadius: 0,
        pb: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
          {(currentFamily !== 'ALL') && (
            <Button variant="text" onClick={() => navigate('ALL', 'ALL')}>
              <ArrowBackIcon />
            </Button>
          )}
          <Typography variant="displayLarge">
            {title}
          </Typography>
        </Box>
      </Box>
      <Tabs
        sx={{
          '& .MuiTabs-flexContainer': {
            gap: 1,
          },
          alignItems: 'center',
          gap: 1,
        }}
        variant="scrollable"
        TabScrollButtonProps={{
          slots: {
            StartScrollButtonIcon: ArrowBackIcon,
            EndScrollButtonIcon: ArrowForwardIcon,
          },
          sx: {
            '&.Mui-disabled': {
              opacity: 1,
              color: 'text.disabled',
            },
            display: {
              xs: 'none',
              md: 'inline-flex',
            },
            opacity: 1,
            backgroundColor: 'white',
            width: 40,
            height: 40,
            alignSelf: 'center',
            borderRadius: 10,
          },
        }}
      >
        <Box
          sx={{
            height: 40,
            backgroundColor: currentSubFamily === 'ALL' ? 'text.primary' : 'white',
            alignItems: 'center',
            display: 'flex',
            px: 2,
            borderRadius: 10,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: currentSubFamily === 'ALL' ? undefined : 'secondary.light',
            },
            '&:active': {
              backgroundColor: currentSubFamily === 'ALL' ? undefined : 'greys.light',
            },
          }}
          onClick={() => navigate(currentFamily, 'ALL')}
        >
          <Typography variant="bodyMedium" color={currentSubFamily === 'ALL' ? 'white' : undefined}>{t('common:see_all')}</Typography>
        </Box>
        {items.map((item) => {
          return (
            <Tab
              key={item.family}
              component={DiscoveryCarrouselNavigationSlide}
              item={item}
              currentSubFamily={currentSubFamily}
              onClick={(family) => handleNavigation(family)}
            />
          );
        })}
      </Tabs>
    </Card>
  );
}
