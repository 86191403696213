import { Business_Profile_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { round } from 'kheops-utils';
import { BasketItem } from '../basket/state/basket';
import FileUtils, { PhotoSize } from '../common/utils/file.utils';
import { contractsAtom, currentContextAtom } from '../state';

export interface BasketOrder {
  items: BasketItem[];
  contractId: string;
  photoUrl: string;
  name: string;
  companyId: string;
  minimumOrderValue: number;
  totalPrice: number;
}

export interface UseBasketOrdersParams {
  basketItems: BasketItem[];
  photoSize?: PhotoSize;
}

export interface BasketOrdersDict {
  [contractId: string]: BasketOrder,
}

export function useBasketOrders({ basketItems, photoSize }: UseBasketOrdersParams): BasketOrdersDict {
  const { realm } = useAtomValue(currentContextAtom);
  const contracts = useAtomValue(contractsAtom);

  return useMemo(() => {
    if (!contracts.length) {
      return {};
    }

    return basketItems.reduce(
      (acc, item) => {
        const contractId = item.contract_id;
        const contract = contracts.find(({ reference_id }) => contractId === reference_id)!;
        const customPriceListDiscount = contract.custom_price_list?.custom_price_list_packagings
          .find(({ packaging_sku }) => packaging_sku === item.packaging.sku)
          ?.discount;
        const adjustedItem: BasketItem = {
          ...item,
          packaging: {
            ...item.packaging,
            price: round(item.packaging.price * (1 - (customPriceListDiscount || item.contract.discount))),
          },
        };
        const price = adjustedItem.quantity * adjustedItem.packaging.price;

        if (!acc[contractId]) {
          const { id, tradeName, photos, business_profile } = realm === Business_Profile_Enum.Buyer
            ? adjustedItem.contract.supplying_company
            : adjustedItem.contract.buying_company;

          acc[contractId] = {
            items: [adjustedItem],
            contractId,
            photoUrl: FileUtils.BuildCompanyImageUrl({
              photo: photos[0]?.photo,
              size: photoSize || { height: 112, width: 112 },
              type: business_profile,
            }),
            companyId: id,
            name: tradeName,
            minimumOrderValue: adjustedItem.contract.minimum_order_value!,
            totalPrice: price,
          };
        } else {
          acc[contractId].items.push(adjustedItem);
          acc[contractId].totalPrice += price;
        }

        return acc;
      },
      {} as BasketOrdersDict,
    );
  }, [basketItems, contracts]);
}
