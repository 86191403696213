import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import MinimumOrderValueIcon from '../../assets/icons/minimum_order_value.svg?react';
import ContractsUtils from '../../common/utils/contracts.utils';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { currentContextAtom } from '../../state';
import { ContractConditions } from '../state';
import OpenPackagingPricesButton from './OpenPackagingPricesButton';
import CommissionInfo from '../../common/components/CommissionInfo';

export interface AcceptOfferDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  offerInfo: ContractConditions;
}

export default function AcceptOfferDialog({ open, onClose, offerInfo }: AcceptOfferDialogProps): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'contracts']);
  const { realm, commissionRate } = useAtomValue(currentContextAtom);
  const currencyFormat = useCurrencyFormat({ maximumFractionDigits: 0 });

  const priceModeLabel = useMemo((): string => {
    return ContractsUtils.getPriceModeLabel(t, realm, offerInfo.discount, offerInfo.customPriceListName);
  }, [offerInfo]);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('contracts:accept_commercial_offer')}
      message={realm === Business_Profile_Enum.Buyer ? t('contracts:make_first_order_as_buyer') : t('contracts:make_first_order_as_supplier')}
      confirmLabel={t('contracts:accept')}
      cancelLabel={t('common:cancel')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        <Box
          sx={{
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 4,
            width: 268,
            display: 'flex',
            justifyContent: 'center',
            gap: 3,
            py: 3,
            px: 2,
            mt: 3,
          }}
        >
          <Box>
            <Typography variant="titleLarge" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <MinimumOrderValueIcon fill={theme.palette.marketing3.main} /> {t('common:minimum_order_value')}
            </Typography>
            <Typography variant="bodyMedium">
              {currencyFormat.format(offerInfo.minimum_order_value!)}
            </Typography>
          </Box>
          {priceModeLabel && (
            <Box>
              <Typography variant="titleLarge" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DiscountIcon /> {t('contracts:discount')}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography variant="bodyMedium">
                  {priceModeLabel}
                </Typography>
                {realm === Business_Profile_Enum.Buyer && !!offerInfo.custom_price_list_id && (
                  <OpenPackagingPricesButton offerInfo={offerInfo} />
                )}
              </Box>
            </Box>
          )}
        </Box>
        {realm === Business_Profile_Enum.Supplier && <CommissionInfo commission={commissionRate!} size="small" />}
      </Box>
    </ConfirmDialog>
  );
}
